import React from "react";
import "./index.scss";

import { useDispatch } from "react-redux";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import { ReactComponent as ModalLogout_img } from "./images/verify_modal_logout.svg";
import { updateTimestamp } from "../../actions/timeStampAction";


const VerifyModal = (props) => {
    const curDispatch = useDispatch();


    const performLogout = () => {

        axios({
            method: "GET",
            url: `${backendModule.backendURL}/auth/logout`,
            ...backendModule.axiosConfig
        }).then(() => null).catch(() => null).finally(() => {
            props.navigate("/");
            curDispatch(updateTimestamp());
        });
    };



    return <div className="modals__verify">
        <div className="modals__verify__container">

            <p>Your account has not been verified, please contact your manager to verify it.</p>

            <div className="modals__verify__button" onClick={performLogout}>
                <ModalLogout_img />
                <p>Logout</p>
            </div>
        </div>

    </div >
};

export default VerifyModal;
