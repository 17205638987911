import React from "react";
import "./index.scss";

import { useSelector } from "react-redux";
import axios from "axios";
import * as backendModule from "../../../modules/backendModule";
import { animateBox } from "../../../modules/componentAnimation";
import { countries } from "../../../modules/countryModule";

import useDefer from "../../../modules/hooks/useDefer";
import useOnScreen from "../../../modules/hooks/useOnScreen";

import Spinner from "../../../components/customComponents/Spinner";
import FilterPanel from "../../../components/customComponents/FilterPanel";
import Dropdown from "../../../components/customComponents/Dropdown";
import FilterBySearch from "../../../filters/FilterBySearch";
import RadioButton from "../../../components/customComponents/RadioButton";
import { FilteredCustomTable } from "../../../components/customComponents/Table";

const AdminOffers = () => {
    const [showFlags, setShowFlags] = React.useState(false);
    const [data, setData] = React.useState();
    const [filters, setFilters] = React.useState([]);
    const [spinner, setSpinner] = React.useState(false)
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [search, setSearch] = React.useState("");
    const curDefer = useDefer();
    const curOnScreen = useOnScreen();

    const curUserSelector = useSelector(state => state?.userData?.userData?.UserInfo);

    const getData = () => {
        const filtersTmp = [...filters];

        if (search) {
            filtersTmp.push({
                or: [
                    { name: "Name", op: "like", value: search },
                    { name: "Type", op: "like", value: search },
                    { name: "Currency", op: "like", value: search },
                    { name: "Description", op: "like", value: search },
                    { name: "ID", op: "like", value: search }
                ]
            })
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOffers`,
            data: {
                filters: filtersTmp,
                limit: null
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) {
                    setCanPaginate(true);
                } else {
                    setCanPaginate(false);
                };
            };
            setData(res.data);
        }).catch(() => {
            setData(backendModule.axiosConfig);
        });
    };

    const continueData = () => {
        setCanPaginate(false);
        if (!data) return;
        if (data.status !== "ok") return;
        if (data.data.length === 0) return;

        const filtersTmp = [...filters];

        if (search) {
            filtersTmp.push({
                or: [
                    { name: "Name", op: "like", value: search },
                    { name: "Type", op: "like", value: search },
                    { name: "Currency", op: "like", value: search },
                    { name: "Description", op: "like", value: search },
                    { name: "ID", op: "like", value: search }
                ]
            })
        };

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOffers`,
            data: {
                limit: 20,
                offset: data.data.length,
                filters: filtersTmp
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
                if (res.data.data.length === 20) {
                    setCanPaginate(true);
                } else {
                    setCanPaginate(false);
                };
            };
        }).catch(() => null).finally(() => {
            setSpinner(false);
        });
    };
    React.useEffect(() => {
        getData();
    }, [search, filters])

    React.useEffect(() => {
        if (curOnScreen.isIntersecting && canPaginate) {
            try {
                curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
            } catch { };

            curDefer(() => {
                continueData();
            }, 500);
        };
    }, [canPaginate, curOnScreen.isIntersecting]);

    return <div className="route__adminOffers">
        <div className="route__adminOffers__head" style={{
            gridTemplateColumns: curUserSelector?.Flags?.isAdmin ? null : "1fr 400px"
        }}>
            <FilterPanel
                accent="#dfdfdf"
                theme="light"
                filterCB={fi => setFilters(fi)}
                filters={[
                    { name: "ID", friendlyName: "ID", type: "string" },
                    { name: "AdvertiserID", friendlyName: "Advertiser ID", type: "string" },
                    { name: "Name", friendlyName: "Name", type: "string" },
                    { name: "Description", friendlyName: "Description", type: "string" },
                    {
                        name: "Type", friendlyName: "Type", type: "custom", varType: "string", data: [
                            { text: "Cost per lead (CPL)", value: "CPL" },
                            { text: "Cost per action - single optin (CPA-SOI)", value: "CPA-SOI" },
                            { text: "Cost per action - double optin (CPA-DOI)", value: "CPA-DOI" },
                            { text: "Revenue sharing (RevShare)", value: "RevShare" }
                        ]
                    },
                    { name: "Limit", friendlyName: "Limit", type: "number" },
                    { name: "PlatformTax", friendlyName: "Platform tax", type: "number" },
                    { name: "Unlocked offer", friendlyName: "Is unlocked  (Dont need request to run)", type: "boolean" },
                    { name: "isInHouse", friendlyName: "Is in-house offer", type: "boolean" },
                    { name: "isActive", friendlyName: "Is active", type: "boolean" }
                ]} />
            <FilterBySearch onChange={e => setSearch(e)} />
            {curUserSelector?.Flags?.isAdmin && <button onClick={() => { animateBox({ currentTarget: document.querySelector('.route__adminOffers__content') }, <SelectOffer item={null} update={getData} />) }}>Add offer</button>}
            <div className="route__adminOffers__head__showFlags">
                <RadioButton checked={showFlags} onChange={e => setShowFlags(!!e)} />
                <p>Display flags</p>
            </div>
        </div>

        <div className="route__adminOffers__content" style={{ gridTemplateColumns: "1fr" }} >
            <div className="route__adminOffers__content__left">
                <FilteredCustomTable
                    theme="light"
                    accent="#6C5DD3"
                    headers={["Name", "Type", "Limit", "Currency", "Active", ""]}
                    customColumns={["1fr", "1fr", "1fr", "1fr", "70px", curUserSelector?.Flags?.isAdmin ? "400px" : "0px"]}
                    data={(() => {
                        let out = [];

                        if (!data) return [[{ keyID: "noData-spinner", type: "spinner", color: "white" }]];
                        if (data.status === "ok") {
                            for (let item of data.data) {
                                out.push({
                                    columns: [
                                        { keyID: item.ID, type: "text", text: item.Name },
                                        { keyID: item.ID, type: "text", text: item.Type },
                                        { keyID: item.ID, type: "text", text: item.Limit },
                                        { keyID: item.ID, type: "text", text: item.Currency },
                                        { keyID: item.ID, type: "custom", data: item.isActive ? <p className="route__adminOffers__yesbase">Yes</p> : <p className="route__adminOffers__nobase">No</p> },
                                        {
                                            keyID: item.ID, type: "custom", data: <div className="route__adminOffers__buttons">
                                                {curUserSelector?.Flags?.isAdmin && <p className="route__adminOffers__verify" onClick={(e) => {
                                                    e.stopPropagation();
                                                    animateBox(e, <AllowPublisher item={item} />);
                                                }}>Allow  / Deny publisher</p>}
                                                {curUserSelector?.Flags?.isAdmin && <p className="route__adminOffers__edit" onClick={(e) => {
                                                    e.stopPropagation();
                                                    animateBox({ currentTarget: document.querySelector('.route__adminOffers__content') }, <SelectOffer item={item} update={getData} edit={true} />)
                                                }}>Edit</p>}
                                                {curUserSelector?.Flags?.isAdmin && <p className="route__adminOffers__delete" onClick={(e) => {
                                                    e.stopPropagation();
                                                    animateBox({ currentTarget: document.querySelector('.route__adminOffers__content') }, <RemoveOffer item={item} id={item.ID} update={getData} />)
                                                }}>Delete</p>}
                                            </div>
                                        },
                                        {
                                            keyID: item.ID, type: "groupNewline", group: [
                                                {
                                                    keyID: item.ID, type: "custom", data: <p className="route__adminOffers__content__payouts">
                                                        {item.Payouts.map(p => {
                                                            return <div className="route__adminOffers__content__payouts__payout">
                                                                {showFlags ? <img src={`/images/flags/${p.Country.toLowerCase()}.png`} /> : <span>{p.Country}</span>}
                                                                <span>{Number(p.Payout).toFixed(2)}</span>
                                                            </div>
                                                        })}
                                                    </p>
                                                }
                                            ]
                                        }
                                    ]
                                })
                            };
                        } else {
                            out.push([{ keyID: "noData-error", type: "text", text: "Error while fetching orders!", color: "#f96666" }]);
                        };

                        if (spinner) out.push([{ keyID: "pagination-spinner", type: "spinner", color: "white" }]);
                        if (out.length === 0) out.push([{ keyID: "noData-noData", type: "text", text: "Nothing to show for now." }]);

                        return out;
                    })()}
                />
            </div>
            {canPaginate && <div ref={curOnScreen.measureRef} className="modals__ordersModal__wrap__content__left__paginate"></div>}

        </div>
    </div>
};

const SelectOffer = (props) => {
    const [allCurrencies, setAllCurrencies] = React.useState();
    const [allAdvertisers, setAllAdvertisers] = React.useState();

    const [flags, setFlags] = React.useState({
        UnlockedOffer: !!props?.item?.UnlockedOffer ?? false,
        isInHouse: !!props?.item?.isInHouse ?? false,
        isActive: (props?.item?.isActive !== null && props?.item?.isActive !== undefined) ? !!props?.item?.isActive : true
    });
    const [item, setItem] = React.useState(props.item);
    const [error, setError] = React.useState();
    const [spinner, setSpinner] = React.useState();

    const [selectedType, setSelectedType] = React.useState(props?.item?.Type);
    const [selectedCurrency, setSelectedCurrency] = React.useState(props?.item?.Currency);
    const [selectedPayouts, setSelectedPayouts] = React.useState(props?.item?.Payouts ?? []);
    const [selectedImages, setSelectedImages] = React.useState(props?.item?.Images ?? []);
    const [selectedAdvertiser, setSelectedAdvertiser] = React.useState(props?.item?.AdvertiserID);
    const [selectedRequiredFields, setSelectedRequiredFields] = React.useState(props?.item?.RequiredFields ?? []);

    const nameRef = React.useRef();
    const limitRef = React.useRef();
    const descriptionRef = React.useRef();
    const platformTaxRef = React.useRef();
    const urlRef=React.useRef();

    const onClose = async () => {
        props.onClose();

        if (props.item === null) {
            for (let item of selectedImages) {
                let finalName = item.split("/").pop();

                await axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/images/deleteImageByFilename`,
                    data: {
                        FileName: finalName
                    },
                    ...backendModule.axiosConfig
                }).then(() => null).catch(() => null);
            };
        };
    }

    const saveData = () => {
        if (props.item !== null) {
            let diff = props?.item?.Images.filter(i => {
                return !selectedImages.includes(i);
            });

            (async () => {
                for (let item of diff) {
                    let finalName = item.split("/").pop();

                    await axios({
                        method: "POST",
                        url: `${backendModule.backendURL}/images/deleteImageByFilename`,
                        data: {
                            FileName: finalName
                        },
                        ...backendModule.axiosConfig
                    }).then(() => null).catch(() => null);
                };
            })();
        };

        setError("");
        let data = {
            AdvertiserID: selectedAdvertiser,
            Name: nameRef.current.value,
            Images: selectedImages,
            Description: descriptionRef.current.value,
            Type: selectedType,
            Limit: Number(limitRef.current.value),
            PlatformTax: Number(platformTaxRef.current.value),
            Currency: selectedCurrency,
            Payouts: selectedPayouts,
            UnlockedOffer: !!flags["UnlockedOffer"],
            isInHouse: !!flags["isInHouse"],
            isActive: !!flags["isActive"],
            RequiredFields: selectedRequiredFields,
            URL: urlRef.current.value
        };

        if (!data.AdvertiserID) return setError("Advertiser must be selected");
        if (!data.Name) return setError("Offer name missing");
        if (isNaN(data.Limit) || data.Limit < 0) return setError("Litmit invalid");
        if (isNaN(data.PlatformTax) || data.PlatformTax < 0) return setError("Platform tax invalid");
        if (!data.Currency) return setError("Currency must be selected");
        if (!data.Payouts || data.Payouts.length === 0) return setError("Payouts missing");
        if (!data.RequiredFields) return setError("Required fields are ... required...");
        if(!data.URL) return setError("URL is required.");
        if (!Array.isArray(data.RequiredFields)) return setError("Required fields are invalid");
        if (data.RequiredFields.length === 0) return setError("Required fields missing");


        if (props.item) data["ID"] = props.item.ID;

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/${props.item ? "editOffer" : "addOffer"}`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.update();
                props.onClose();
            } else {
                setError("Error while adding an offer, check the info and try again.");
            };
        }).catch(() => {
            setError("Server timed out!");
        }).finally(() => {
            setSpinner(false);
        });
    };

    const getCurrencies = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/currencies/getAllCurrencies`,
            data: {
                offset: 0,
                limit: null
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setAllCurrencies(res.data);
        }).catch(() => {
            setAllCurrencies(backendModule.genericError);
        });
    };

    const getAdvertisers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/advertisers/getAllAdvertisers`,
            data: {
                offset: 0,
                limit: null
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setAllAdvertisers(res.data);
        }).catch(() => {
            setAllAdvertisers(backendModule.genericError);
        });
    };

    React.useEffect(() => {
        getCurrencies();
        getAdvertisers();
    }, []);

    return <div className="modals__adminOffersModal">
        <div className="modals__adminOffersModal__container">
            <div className="modals__adminOffersModal__close">
                <img src="/images/closeIcon.png" onClick={() => { onClose() }} />
            </div>

            <p className="modals__adminOffersModal__head">{props.edit ? "Edit Offer" : "Add new offer"}</p>

            <p className="modals__adminOffersModal__label">Advertiser</p>
            {allAdvertisers ? (allAdvertisers.status === "error" ? <p>There was an error while fetching advertisers</p> : <Dropdown theme="light"
                accent="#6C5DD3" data={allAdvertisers.data.map(c => {
                    return { name: `${c.CompanyName} (${c.Username})`, value: c.ID };
                })} onChange={val => {
                    setSelectedAdvertiser(val.value)
                }}
                style={{ height: "45px", marginTop: "15px" }}
                selected={(() => {
                    if (!allAdvertisers) return -1;
                    if (allAdvertisers.status === "error") return -1;
                    return allAdvertisers.data.indexOf(allAdvertisers.data.find(c => c.ID === selectedAdvertiser));
                })()}
            />) : <Spinner color="white" style={{ color: "45px", height: "45px" }} />}

            <p className="modals__adminOffersModal__label">Name</p>
            <input defaultValue={item?.Name} ref={nameRef} type='text' placeholder="Offer name" />

            <p className="modals__adminOffersModal__label">Type</p>
            <Dropdown theme="light"
                accent="#6C5DD3" data={[
                    { name: "Cost per lead (CPL)", value: "CPL" },
                    { name: "Cost per action - single optin (CPA-SOI)", value: "CPA-SOI" },
                    { name: "Cost per action - double optin (CPA-DOI)", value: "CPA-DOI" },
                    { name: "Revenue sharing (RevShare)", value: "RevShare" }
                ]} onChange={val => {
                    setSelectedType(val.value)
                }}
                style={{ height: "45px", marginTop: "15px" }}
                selected={(() => {
                    switch (selectedType) {
                        case "CPL": return 0;
                        case "CPA-SOI": return 1;
                        case "CPA-DOI": return 2;
                        case "RevShare": return 3;
                        default: return 0;
                    };
                })()}
            />
            <p className="modals__adminOffersModal__label">URL</p>
            <input defaultValue={item?.URL} ref={urlRef} type='text' placeholder="Offer URL" />

            <p className="modals__adminOffersModal__label">Lead limit</p>
            <input defaultValue={item?.Limit ?? 1000} ref={limitRef} type='text' placeholder="Lead limit" />

            <p className="modals__adminOffersModal__label">Platform tax (in %)</p>
            <input defaultValue={item?.PlatformTax ?? 20} ref={platformTaxRef} type='number' placeholder="Platform tax (in %)" />

            <p className="modals__adminOffersModal__label">Currency</p>
            {allCurrencies ? (allCurrencies.status === "error" ? <p>There was an error while fetching currencies</p> : <Dropdown theme="light"
                accent="#6C5DD3" data={allCurrencies.data.map(c => {
                    return { name: c.Currency, value: c.Currency };
                })} onChange={val => {
                    setSelectedCurrency(val.value)
                }}
                style={{ height: "45px", marginTop: "15px" }}
                selected={(() => {
                    if (!allCurrencies) return -1;
                    if (allCurrencies.status === "error") return -1;
                    return allCurrencies.data.indexOf(allCurrencies.data.find(c => c.Currency === selectedCurrency));
                })()}
            />) : <Spinner color="white" style={{ color: "45px", height: "45px" }} />}

            <p className="modals__adminOffersModal__label">Description</p>
            <textarea placeholder="Offer description" defaultValue={item?.Description} ref={descriptionRef}></textarea>

            <div className="modals__userModal__inline">
                <RadioButton checked={!!flags["UnlockedOffer"]} onChange={e => setFlags(f => {
                    return {
                        ...f,
                        UnlockedOffer: e
                    }
                })} />
                <span>Unlocked  (Dont need request to run)</span>
            </div>
            <div className="modals__userModal__inline">
                <RadioButton checked={!!flags["isInHouse"]} onChange={e => setFlags(f => {
                    return {
                        ...f,
                        isInHouse: e
                    }
                })} />
                <span>In house offer</span>
            </div>
            <div className="modals__userModal__inline">
                <RadioButton checked={!!flags["isActive"]} onChange={e => setFlags(f => {
                    return {
                        ...f,
                        isActive: e
                    }
                })} />
                <span>Active</span>
            </div>

            <div className="modals__adminOffersModal__controls">
                <div onClick={e => animateBox(e, <ViewImages editMode={props.item !== null} onChange={e => setSelectedImages(e)} Images={selectedImages} />)}>View images ({selectedImages.length})</div>

                <div onClick={e => animateBox(e, <ViewPayouts onChange={e => setSelectedPayouts(e)} Payouts={selectedPayouts} Type={selectedType} Currency={selectedCurrency} />)}>View payouts ({selectedPayouts.length})</div>

                <div onClick={e => animateBox(e, <ViewFields onChange={e => setSelectedRequiredFields(e)} Fields={selectedRequiredFields} />)}>View required fields ({selectedRequiredFields.length})</div>
            </div>

            <p className="modals__adminOffersModal__error">{error}</p>
            <div className="modals__adminOffersModal__buttons">
                {spinner ? <Spinner color={"##00A3FF"} /> : <button onClick={() => { saveData() }}>Save</button>}
            </div>
        </div>
    </div>
};

const ViewFields = (props) => {
    const [allPurchaseFields, setAllPurchaseFields] = React.useState();
    const [fields, setFields] = React.useState(props.Fields ?? []);

    const getAllowedPurchaseFields = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllowedPurchaseFields`,
            data: {
                offset: 0,
                limit: null
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setAllPurchaseFields(res.data);
        }).catch(() => {
            setAllPurchaseFields(backendModule.genericError);
        });
    };

    React.useEffect(() => {
        getAllowedPurchaseFields();
    }, []);

    return <div className="modals__adminOffersModal__viewPayouts">
        <div className="modals__adminOffersModal__viewPayouts__wrap">
            <div className="modals__adminOffersModal__viewPayouts__wrap__head">
                <div className="modals__adminOffersModal__viewPayouts__wrap__head__left">Required fields</div>
                <div className="modals__adminOffersModal__viewPayouts__wrap__head__middle" onClick={() => {
                    props.onChange(fields);
                    props.onClose();
                }}>Save</div>
                <div className="modals__adminOffersModal__viewPayouts__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={() => props.onClose()}></div>
            </div>

            {allPurchaseFields ? <>
                {allPurchaseFields.status === "error" ? <p>There was an error while fetching fields.</p> : <>
                    <div className="modals__adminOffersModal__viewPayouts__wrap__content">
                        {allPurchaseFields.data.map(pf => {
                            return <p style={{display: "flex", alignItems: "center", color: "white", gap: "10px", marginBottom: "10px"}}>
                                <RadioButton checked={fields.includes(pf)} onChange={e => {
                                    if (e) return setFields(f => [...f, pf]);
                                    return setFields(f => f.filter(ff => ff !== pf));
                                }} />
                                {pf}
                            </p>
                        })}
                    </div>
                </>}
            </> : <Spinner color="white" />}
        </div>
    </div>
};

const ViewPayouts = (props) => {
    const [payouts, setPayouts] = React.useState(props?.Payouts ?? []);

    const [selectedCountry, setSelectedCountry] = React.useState();

    const payoutRef = React.useRef();
    const dropdownResetRef = React.useRef();

    const addPayout = () => {
        if (!selectedCountry) return;
        if (payouts.find(p => p.Country === selectedCountry)) return;

        let tmpCountry = selectedCountry;
        let tmpPayout = Number(payoutRef.current.value ?? 0);

        if (isNaN(tmpPayout)) return;
        if (tmpPayout < 0) return;

        setPayouts(p => [
            ...p,
            { Country: tmpCountry, Payout: Number(tmpPayout.toFixed(2)) }
        ]);
        setSelectedCountry(null);
        payoutRef.current.value = null;
        try {
            dropdownResetRef.current();
        } catch { };
    };

    return <div className="modals__adminOffersModal__viewPayouts">
        <div className="modals__adminOffersModal__viewPayouts__wrap">
            <div className="modals__adminOffersModal__viewPayouts__wrap__head">
                <div className="modals__adminOffersModal__viewPayouts__wrap__head__left">View payouts</div>
                <div className="modals__adminOffersModal__viewPayouts__wrap__head__middle" onClick={() => {
                    props.onChange(payouts);
                    props.onClose();
                }}>Save</div>
                <div className="modals__adminOffersModal__viewPayouts__wrap__head__right" style={{ backgroundImage: `url("/images/icon_close.svg")` }} onClick={() => props.onClose()}></div>
            </div>

            <div className="modals__adminOffersModal__viewPayouts__wrap__content">
                <div className="modals__adminOffersModal__viewPayouts__wrap__content__inputs">
                    <Dropdown
                        theme="light"
                        inlinePlaceholder="Country"
                        data={countries.map(c => {
                            if (payouts.find(p => p.Country === c.code)) return null;

                            return { name: c.name, value: c.code }
                        }).filter(t => t)}
                        onChange={e => setSelectedCountry(e?.value)}
                        onReset={r => dropdownResetRef.current = r}
                    />
                    <input ref={payoutRef} type="text" placeholder="Payout" />
                    <button onClick={addPayout}>Add</button>
                </div>

                <FilteredCustomTable
                    theme="light"
                    accent="#6C5DD3"
                    headers={["Country", "Payout", ""]}
                    customColumns={["1fr", "1fr", "120px"]}
                    canAnimate={false}
                    data={(() => {
                        let out = [];

                        for (let item of payouts) {
                            let curCountry = countries.find(c => c.code.toLowerCase() === item.Country.toLowerCase());
                            if (curCountry) curCountry = curCountry.name; else curCountry = item.Country;

                            out.push([
                                { keyID: item.Country, type: "text", text: curCountry },
                                { keyID: item.Country, type: "text", text: `${Number(item.Payout).toFixed(2)} ${props.Type === "RevShare" ? "%" : props.Currency ?? "?"}` },
                                {
                                    keyID: item.Country, type: "button", text: "Remove", onClick: e => {
                                        setPayouts(p => {
                                            return p.filter((_, pIdx) => {
                                                if (pIdx === payouts.indexOf(item)) return false;
                                                return true;
                                            });
                                        });
                                    }
                                }
                            ]);
                        };

                        if (out.length === 0) out.push([
                            { keyID: "noData-noData", type: "text", text: "No payouts defined" }
                        ]);

                        return out;
                    })()}
                />
            </div>
        </div>
    </div>
};

const ViewImages = props => {
    const [images, setImages] = React.useState(props.Images ?? []);
    const [selectedFiles, setSelectedFiles] = React.useState([]);
    const [imagesToRemove, setImagesToRemove] = React.useState([]);

    const onClose = async (wasSuccess = false) => {
        props.onClose();
        let oldImages = props.Images ?? [];

        let diff = images.filter(i => !oldImages.includes(i));

        if (!wasSuccess) {
            for (let item of diff) {
                let finalName = item.split("/").pop();

                await axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/images/deleteImageByFilename`,
                    data: {
                        FileName: finalName
                    },
                    ...backendModule.axiosConfig
                }).then(() => null).catch(() => null);
            };
        };

        if (wasSuccess && !props.editMode) for (let item of imagesToRemove) {
            await axios({
                method: "POST",
                url: `${backendModule.backendURL}/images/deleteImageByFilename`,
                data: {
                    FileName: item
                },
                ...backendModule.axiosConfig
            }).then(() => null).catch(() => null);
        };
    };

    const removeImage = URL => {
        let finalName = URL.split("/").pop();
        setImagesToRemove(i => [...i, finalName]);
        return setImages(imgs => imgs.filter(imgFinal => imgFinal !== URL));
    };

    React.useEffect(() => {
        if (!Array.isArray(selectedFiles)) return;

        for (let item of [...selectedFiles]) {
            if (item.progress !== 0) continue;

            let ext = item.file.name.split(".").pop();

            let fd = new FormData();
            fd.append("ImageName", `Upload-offer-${Date.now()}-${Math.floor(Math.random() * 999999)}.${ext}`);
            fd.append("tag", "Offer");
            fd.append("image", item.file);

            axios({
                method: "POST",
                url: `${backendModule.backendURL}/images/uploadImage`,
                data: fd,
                onUploadProgress: function (progressEvent) {
                    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

                    setSelectedFiles(sf => sf.map(sfm => {
                        if (sfm.file !== item.file) return sfm;
                        sfm.progress = percentCompleted;
                        if (sfm.progress === 0) sfm.progress = 1;

                        return sfm;
                    }));
                },
                ...backendModule.axiosConfig,
                headers: {
                    ...backendModule.axiosConfig.headers,
                    "Content-Type": "multipart/form-data"
                }
            }).then(res => {
                if (res.data.status === "ok") {
                    setSelectedFiles(sf => sf.filter(ssf => ssf.file !== item.file));
                    setImages(img => [...img, res.data.data]);
                } else {
                    setSelectedFiles(sf => sf.map(sfm => {
                        if (sfm.file !== item.file) return sfm;
                        sfm.progress = -1;

                        return sfm;
                    }));
                };
            }).catch(() => {
                setSelectedFiles(sf => sf.map(sfm => {
                    if (sfm.file !== item.file) return sfm;
                    sfm.progress = -1;

                    return sfm;
                }));
            });
        };

        if (selectedFiles.find(f => f.progress === 0)) setSelectedFiles(sf => sf.map(ssf => {
            return {
                ...ssf,
                progress: 1
            };
        }));
    }, [selectedFiles]);

    const renderImage = (file, img) => {
        let reader = new FileReader();
        reader.onload = e => {
            img.src = e.target.result;
        };
        reader.readAsDataURL(file);
    };

    return <div className="modals__adminOffersModal__viewPayouts">
        <div className="modals__adminOffersModal__viewPayouts__wrap">
            <div className="modals__adminOffersModal__viewPayouts__wrap__head">
                <div className="modals__adminOffersModal__viewPayouts__wrap__head__left">View images</div>
                <div className="modals__adminOffersModal__viewPayouts__wrap__head__middle" onClick={() => {
                    props.onChange(images);
                    onClose(true);
                }}>Save</div>
                <div className="modals__adminOffersModal__viewPayouts__wrap__head__right" style={{ backgroundImage: `url("/images/icon_close.svg")` }} onClick={() => onClose()}></div>
            </div>

            <div className="modals__adminOffersModal__viewPayouts__wrap__content">
                <div className="modals__adminOffersModal__viewPayouts__wrap__content__inputs">
                    <button style={{ gridColumn: "1 / -1" }} onClick={e => e?.currentTarget.parentNode.querySelector("input").click()}>Upload</button>
                    <input type="file" accept="image/*" style={{ display: "none" }} multiple={true} onChange={e => {
                        if (e.target.files.length > 0) setSelectedFiles(sf => {
                            return [
                                ...(sf ?? []),
                                ...[...e.target.files].map(f => {
                                    return {
                                        file: f,
                                        progress: 0
                                    }
                                })
                            ]
                        });
                    }} />
                </div>

                <div className="modals__adminOffersModal__viewPayouts__wrap__content__uploads">
                    {selectedFiles.filter(i => i.file && i.progress < 100).map((img, imgIndex) => {
                        return <div key={`${img.file.name}-${imgIndex}`} className="modals__adminOffersModal__viewPayouts__wrap__content__uploads__upload" src="/images/image-missing.png" onError={e => {
                            renderImage(img.file, e.target);
                        }}>
                            <img src="modals__adminOffersModal__viewPayouts__wrap__content__uploads__upload__image" />
                            <p>{img.file.name} {img.progress === -1 && <button onClick={() => {
                                setSelectedFiles(sf => sf.filter(ssf => ssf.file !== img.file));
                            }}>Dismiss</button>}</p>
                            <div className="modals__adminOffersModal__viewPayouts__wrap__content__uploads__upload__progressbar" style={{
                                backgroundColor: img.progress === -1 ? "#9e4949" : null,
                                width: `${img.progress === -1 ? "100%" : img.progress + "%"}`
                            }}></div>
                        </div>
                    })}
                </div>

                <div className="modals__adminOffersModal__viewPayouts__wrap__content__images">
                    {images.map(img => <div className="modals__adminOffersModal__viewPayouts__wrap__content__images__image">
                        <img src={img} />
                        <button onClick={() => {
                            removeImage(img);
                        }}>Remove</button>
                    </div>)}

                </div>
            </div>
        </div>
    </div>
};

const RemoveOffer = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [error, setError] = React.useState();

    const deleteData = () => {
        setSpinner(true)
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/removeOffer`,
            data: {
                ID: props.id
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.update();
                props.onClose();
            } else {
                setError('Please check all your info and try again');
            }
        }).catch(() => {
            setError('Something went wrong please try again later')
        }).finally(() => { setSpinner(false) })
    };

    return <div className="modals__removeOffer">
        <div className="modals__removeOffer__container">
            <p>You are about to delete <span style={{ color: "#6c5dd3" }}>{props.item?.Name}</span></p>
            <p>Offer deletion is permanent.</p>
            <p>Are you sure you want to continue?</p>
            <p className="modals__removeOffer__error">{error}</p>
            <div className="modals__removeOffer__container__buttons">
                <button onClick={() => [deleteData()]}>Yes</button>
                <button onClick={() => { props.onClose() }}>No</button>
            </div>
        </div>
    </div>
};

const AllowPublisher = (props) => {
    const [users, setUsers] = React.useState();
    const [offerUsers, setOfferUsers] = React.useState();
    const [selectedUser, setSelectedUser] = React.useState();
    const [add, setAdd] = React.useState();
    const [error, setError] = React.useState();
    const [filters, setFilters] = React.useState([]);

    const getUsers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsers`,
            data: {
                limit: 1000
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setUsers(res.data);
        }).catch(() => {
            setUsers(backendModule.axiosConfig);
        });
    };

    const getAllowedUsers = () => {
        
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllAllowedUsersForOffer`,
            data: {
                ID: props?.item?.ID,
                limit: 1000,
                filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setOfferUsers(res.data);
        }).catch(() => {
            setOfferUsers(backendModule.axiosConfig);
        });
    };
    React.useEffect(() => {
        if (!props.item) return;
        getAllowedUsers();
        getUsers()
    }, [props.item])
    React.useEffect(() => {
        if (!props.item) return;
        getAllowedUsers();
    }, [filters])

    const updateUserOfferStatus = (user, allow) => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/updateUserOfferStatus`,
            data: {
                OfferID: props?.item?.ID,
                UserID: user,
                isAllowed: allow
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                getAllowedUsers()
            } else {
                setError('Something went wrong please try later')
            }

        }).catch(() => {
            setError('Something went wrong please try later')
        });
    }

    return <div className="modals__adminOffersModal__viewPayouts">
        <div className="modals__adminOffersModal__viewPayouts__wrap">
            <div className="modals__adminOffersModal__viewPayouts__wrap__head">
                <div className="modals__adminOffersModal__viewPayouts__wrap__head__left">Allow/Deny publisher</div>
                <div className="modals__adminOffersModal__viewPayouts__wrap__head__right" style={{ backgroundImage: `url("/images/icon_close.svg")` }} onClick={() => props.onClose()}></div>
            </div>
            <p>{error}</p>
            <div className="modals__adminOffersModal__viewPayouts__wrap__users">
                <div className="modals__adminOffersModal__viewPayouts__wrap__users__add">
                    <div className="modals__adminOffersModal__viewPayouts__wrap__users__add__head">
                        <FilterPanel
                            accent="#dfdfdf"
                            theme="light"
                            filterCB={fi => setFilters(fi)}
                            filters={[
                                { name: "ID", friendlyName: "ID", type: "string" },
                                { name: "AdvertiserID", friendlyName: "Advertiser ID", type: "string" },
                                { name: "OfferID", friendlyName: "Offer ID", type: "string" },
                                {
                                    name: "UserID", friendlyName: "User", type: "custom", varType: "string", data: users?.data.map(item=>{
                                        return {text: item.Username, value: item.ID}
                                    })
                                },
                                { name: "isAlloowed", friendlyName: "Is allowed", type: "boolean" }
                            ]} />
                        <p onClick={() => { setAdd(!add) }}>Add new user </p>
                    </div>
                    <div className={`modals__adminOffersModal__viewPayouts__wrap__users__add__modal ${add && "modals__adminOffersModal__viewPayouts__wrap__users__add__modal-active"}`}>
                        <Dropdown theme="light"
                            accent="#6C5DD3" data={users?.data.map(item => {
                                return { value: item.ID, name: item.Username }
                            })} onChange={val => {
                                setSelectedUser(val.value)
                            }} />
                        <button onClick={() => { updateUserOfferStatus(selectedUser, true) }}>Add</button>
                    </div>
                </div>
                {(offerUsers?.status === 'ok' && users?.status === 'ok') && offerUsers?.data.map((item, index) => {
                    return <div className="modals__adminOffersModal__viewPayouts__wrap__users__single">
                        <p>{(() => {
                            let tmp = users?.data.find(u => u.ID === item.UserID);
                            return tmp?.Username;
                        })()}</p>
                        <p>isAllowed: {item.isAllowed ? <span className="modals__adminOffersModal__viewPayouts__wrap__users__single__yes">Yes</span> :
                            <span className="modals__adminOffersModal__viewPayouts__wrap__users__single__no">No</span>}</p>
                        {!item.isAllowed ? <button className="modals__adminOffersModal__viewPayouts__wrap__users__single__yesB" onClick={() => { updateUserOfferStatus(item.UserID, true) }}>Allow</button> :
                            <button className="modals__adminOffersModal__viewPayouts__wrap__users__single__noB" onClick={() => { updateUserOfferStatus(item.UserID, false) }}>Forbid</button>}
                    </div>
                })}
            </div>
        </div>
    </div>
};

export default AdminOffers;