import React, { useImperativeHandle } from "react";
import "./index.scss";
import PropTypes from "prop-types";

import CustomButtonSmall from "../Button";
import CustomInput from "../CustomInput";
import Dropdown from "../Dropdown";
import Spinner from "../Spinner";
import Progressbar from "../Progressbar";

import Checkbox from "../Checkbox";


const internalObjectToString = (obj) => {
    if (Array.isArray(obj)) {
        return `[${obj.map(item => internalObjectToString(item))}]`;
    } else if (typeof (obj) === "object") {
        if (obj.keyID) return obj.keyID;
        switch (obj.type) {
            case "buttom":
            case "text":
                if (typeof (obj?.text) === "string") {
                    return `{${obj.type}:${obj.text}}`;
                } else if (typeof (obj?.text) === "object") {
                    if (obj?.text?.props) {
                        return `${obj.text.props}-${obj.text.type}`
                    } else {
                        return `Object[${Object.keys(obj.text).map(item => obj.text[item]).join("")}]`;
                    };
                } else {
                    return "{UnknownTypeObject}";
                };
            case "group":
            case "groupNewLine":
                return `[${obj.group.map(item => internalObjectToString(item))}]`;
            case "custom":
                return `{custom: A custom component ${obj.data?.type}}`;
            case "spinner":
                return "{spinner!}"
            default: return "?!?!"
        };
    } else {
        try {
            return obj.toString();
        } catch {
            return "?!";
        };
    };
};

/*
data:
{type: "button", text:"", onClick: () => {}}
{type: "button", text: "", onClick: () => {}, triggerDropdown: true | false, triggerData: (closeCB) => <p></p>}
{type: "text", text: ""}
{type: "custom", data: <p></p>} za cijeli red
{type: "group", group: [...sve ovo gore]}
{type: "groupNewline", group: [...sve ovo gore sem grupe]}
{type: "spinner" color: ""}
*/

// --------------------------------------------------------

// {name: "FirstName", friendlyName: "First name", type: "string"}
// {name: "FirstName", friendlyName: "First name", type: "custom", varType: "number", data:[
// {value: 1, text: "asd"}, {value:2, text: "dsa"}
//]}

const FilterCustomTableItem = React.forwardRef((props, ref) => {
    let customOutput = [];
    let startRow = 1;
    let allEvents = {};

    if (props.events) {
        if (typeof(props.events) === "object" && !Array.isArray(props.events)) {
            for (let key of Object.keys(props.events)) {
                if (!key.startsWith("on")) continue;;
                if (typeof(props.events[key]) !== "function") continue;

                allEvents[key] = props.events[key];
            };
        };
    };

    let [dropdownTriggers, setDropdownTriggers] = React.useState({});

    let isPhone = React.useRef(window.innerWidth >= 600 ? false : true);
    const internalRef = React.useRef();
    const wasAnimated = React.useRef();
    useImperativeHandle(ref, () => internalRef.current);

    React.useEffect(() => {
        if (!internalRef?.current) return;
        if (wasAnimated.current) {
            internalRef.current.style.overflow = null;
            internalRef.current.style.padding = null;
            internalRef.current.style.opacity = null;
            internalRef.current.animate([{ height: "auto", maxHeight: "none", padding: "20px 40px" }, { height: "auto", maxHeight: "none", padding: "20px 40px" }], {
                duration: 0,
                iterations: 1,
                easing: "linear",
                fill: "both"
            });
        };
    });

    React.useEffect(() => {
        if (!internalRef?.current) return;
        let internalComputed = getComputedStyle(internalRef.current);
        internalRef.current.style.height = "auto";
        internalRef.current.style.padding = "0 40px";
        internalRef.current.style.overflow = "hidden";
        internalRef.current.style.opacity = 1;

        internalRef.current.animate([
            {
                maxHeight: internalComputed.maxHeight ?? "0px",
                padding: internalComputed.padding ?? "0px"
            },
            {
                maxHeight: `300px`,
                padding: "20px 40px"
            }
        ], {
            duration: props.canAnimate ? 300 : 0,
            iterations: 1,
            fill: "both",
            easing: "ease-in-out",
        }).onfinish = () => {
            if (!internalRef?.current) return;
            internalRef.current.animate([{ height: "auto", maxHeight: "none", padding: "20px 40px" }, { height: "auto", maxHeight: "none", padding: "20px 40px" }], {
                duration: 0,
                iterations: 1,
                easing: "linear",
                fill: "both"
            });
            internalRef.current.style.overflow = null;
            internalRef.current.style.padding = null;
            internalRef.current.style.opacity = null;
            if (!wasAnimated.current) wasAnimated.current = true;
        };
        return () => wasAnimated.current = false;
    }, []);

    const createTriggerDiv = (index, data) => {
        startRow += 1;
        let div = <div index={index} style={{
            gridRow: startRow,
            gridColumn: `1 / ${props.headers.length + 1}`
        }} className={`customComponents__table__data__dropdown customComponents__table__data__dropdown${index}`}>{dropdownTriggers[index] ? (() => {
            setTimeout(() => {
                let tmp = internalRef?.current?.querySelector(`.customComponents__table__data__dropdown${index}`);
                if (tmp) {
                    tmp.animate([
                        { maxHeight: getComputedStyle(tmp).maxHeight },
                        { maxHeight: "1500px" }
                    ], { duration: 300, iterations: 1, fill: "both", easing: "ease-in-out" }).onfinish = () => {
                        tmp.style.overflow = "initial";
                    };
                };
            }, 0);
            return data(() => toggleTriggerDiv(index));
        })() : <div index={index}></div>}</div>;
        return {
            div,
            index
        };
    };
    const toggleTriggerDiv = (index) => {
        return new Promise(async r => {
            let tmpDropdown = {};
            if (!dropdownTriggers[index] === true) {
                for (let elem of Object.keys(dropdownTriggers)) {
                    if (dropdownTriggers[elem] === true) {
                        await toggleTriggerDiv(elem);
                        tmpDropdown[elem] = false;
                    };
                };
            } else {
                tmpDropdown = { ...dropdownTriggers };
            };
            if (!internalRef?.current) return;
            if (dropdownTriggers[index] === true) {
                let tmp = internalRef?.current?.querySelector(`.customComponents__table__data__dropdown${index}`);
                if (tmp) {
                    tmp.style.overflow = "hidden";
                    tmp.animate([
                        { maxHeight: getComputedStyle(tmp).height },
                        { maxHeight: 0 }
                    ], { duration: 300, iterations: 1, fill: "both", easing: "ease-in-out" }).onfinish = () => {
                        setDropdownTriggers({
                            ...tmpDropdown,
                            [index]: false
                        });
                        r();
                    };
                } else {
                    setDropdownTriggers({
                        ...tmpDropdown,
                        [index]: false
                    });
                    r();
                }
            } else {
                setDropdownTriggers({
                    ...tmpDropdown,
                    [index]: true
                });
                r();
            };
        });
    };
    return (
        <div data-index={props.index} className={`customComponents__table__data customComponents__table__data${props.index % 2 === 0 ? "0" : "1"}`} key={props.childKey} ref={internalRef} style={{
            opacity: 0,
            height: "auto",
            ...(props.style ?? {}),
            ...(props.cItem?.style ?? {}),
            gridColumn: `1 / span all`,
            gridTemplateColumns: "subgrid"
        }} {...allEvents}>
            {(() => {
                if (props.checkboxCB) customOutput.push(<p><Checkbox onChange={props.checkBoxChange} checked={!!props.checkBoxState} /></p>);
                const finalArr = (() => {
                    if (props.cItem instanceof Array) return props.cItem;
                    if (props.cItem?.columns instanceof Array) return props.cItem.columns;
                    return [];
                })()
                finalArr.filter(tmp => tmp !== undefined && tmp !== null).forEach((item, index) => {
                    if (!(typeof item === "object")) return null;
                    switch (true) {
                        case item.type === "spinner":
                            customOutput.push(<p key={index} className={`${isPhone.current ? "customComponents__table__data__mobileFriendly" : ""}`} style={{
                                gridColumn: isPhone.current ? `1 / span ${props.headers.length}` : null,
                                gridRow: isPhone.current ? startRow : null,
                                ...(item.style ?? {}),
                                backgroundColor: item.backgroundColor ?? (item.style?.backgroundColor ?? null),
                                color: item.color ?? (item.style?.color ?? null)
                            }}>
                                {isPhone.current && props.headers[index] !== undefined && <span className="customComponents__table__data__mobile">{props.headers[index]}</span>}
                                <Spinner color={item.color ?? props.accent ?? "##00A3FF"} />
                            </p>);
                            if (isPhone.current) startRow += 1;
                            break;
                        case item.type === "progressbar":
                            customOutput.push(<p key={index} className={`${isPhone.current ? "customComponents__table__data__mobileFriendly" : ""}`} style={{
                                gridColumn: isPhone.current ? `1 / span ${props.headers.length}` : null,
                                gridRow: isPhone.current ? startRow : null,
                                ...(item.style ?? {}),
                                backgroundColor: item.backgroundColor ?? (item.style?.backgroundColor ?? null),
                                color: item.color ?? (item.style?.color ?? null)
                            }}>
                                {isPhone.current && props.headers[index] !== undefined && <span className="customComponents__table__data__mobile">{props.headers[index]}</span>}
                                <Progressbar color={item.color ?? props.accent ?? "##00A3FF"} maximum={item.progressMax ?? 100} value={item.progressVal ?? 0} />
                            </p>);
                            if (isPhone.current) startRow += 1;
                            break;
                        case item.type === "custom":
                            customOutput.push(<div key={index} className={`customComponents__table__data__customInput ${isPhone.current ? "customComponents__table__data__mobileFriendly" : ""}`} style={{
                                width: "max-content",
                                maxWidth: "100%",
                                gridColumn: isPhone.current ? `1 / span ${props.headers.length}` : null,
                                gridRow: isPhone.current ? startRow : null,
                                ...(item.style ?? {}),
                                backgroundColor: item.backgroundColor ?? (item.style?.backgroundColor ?? null),
                                color: item.color ?? (item.style?.color ?? null)
                            }}>
                                {isPhone.current && props.headers[index] !== undefined && <p className="customComponents__table__data__mobile">{props.headers[index]}</p>}
                                {item.data ?? null}
                            </div>);
                            if (isPhone.current) startRow += 1;
                            break;
                        case item.type === "text":
                            customOutput.push(<p key={index} className={`${isPhone.current ? "customComponents__table__data__mobileFriendly" : ""}`} style={{
                                gridColumn: isPhone.current ? `1 / span ${props.headers.length}` : null,
                                gridRow: isPhone.current ? startRow : null,
                                ...(item.style ?? {}),
                                backgroundColor: item.backgroundColor ?? (item.style?.backgroundColor ?? null),
                                color: item.color ?? (item.style?.color ?? null)
                            }}>
                                {isPhone.current && props.headers[index] !== undefined && <span className="customComponents__table__data__mobile">{props.headers[index]}</span>}
                                {item.text}
                            </p>);
                            if (isPhone.current) startRow += 1;
                            break;
                        case item.type === "button":
                            if (isPhone.current) {
                                customOutput.push(<div key={index} className="customComponents__table__data__mobileButton" style={{
                                    gridRow: startRow,
                                    gridColumn: `1 / span ${props.headers.length}`
                                }}>
                                    <span className="customComponents__table__data__mobile">{props.headers[index]}</span>
                                    <CustomButtonSmall 
                                        style={{ minWidth: "100px", width: "auto", padding: "0px 10px", ...(item.style ?? {})}}
                                        onClick={(e) => {
                                            if (item.onClick) item.onClick(e);
                                            if (item.buttonIndex) toggleTriggerDiv(item.buttonIndex);
                                        }} value={item.text} accent={props.accent ?? null}
                                    />
                                </div>);
                                startRow += 1;
                            } else {
                                customOutput.push(<CustomButtonSmall
                                    key={index}
                                    style={{ minWidth: "100px", width: "auto", padding: "0px 10px", ...(item.style ?? {})}}
                                    onClick={(e) => {
                                        if (item.onClick) item.onClick(e);
                                        if (item.buttonIndex) toggleTriggerDiv(item.buttonIndex);
                                    }}
                                    value={item.text}  accent={props.accent ?? null}
                                />);
                            };
                            break;
                        case item.type === "group" || item.type === "groupNewline":
                            if (item.type === "groupNewline") startRow += 1;
                            if (isPhone.current) startRow += 1;
                            customOutput.push(
                                <div key={index} className={`customComponents__table__data__${item.type}`} style={{
                                    gridRow: item.type === "groupNewline" ? startRow : (isPhone.current ? startRow : null),
                                    gridColumn: item.type === "groupNewline" ? `1 / span ${props.headers.length}` : (isPhone.current ? `1 / span ${props.headers.length}` : null)
                                }}>
                                    {isPhone.current && item.type === "group" && props.headers[index] !== undefined && <span className="customComponents__table__data__mobile">{props.headers[index]}</span>}
                                    {item.group.filter((tmp) => tmp !== undefined && tmp !== null).map((item2, item2Index) => {
                                        let customOutput2 = [];
                                        if (!(typeof item2 === "object")) return null;
                                        switch (item2.type) {
                                            case "spinner":
                                                customOutput2.push(<p key={index} className={`${isPhone.current ? "customComponents__table__data__mobileFriendly" : ""}`} style={{
                                                    gridColumn: isPhone.current ? `1 / span ${props.headers.length}` : null,
                                                    gridRow: isPhone.current ? startRow : null,
                                                    ...(item2.style ?? {}),
                                                    backgroundColor: item2.backgroundColor ?? (item2.style?.backgroundColor ?? null),
                                                    color: item2.color ?? (item2.style?.color ?? null)
                                                }}>
                                                    {isPhone.current && props.headers[index] !== undefined && <span className="customComponents__table__data__mobile">{props.headers[index]}</span>}
                                                    <Spinner color={item2.color ?? (props.accent ?? "##00A3FF")} />
                                                </p>);
                                                if (isPhone.current) startRow += 1;
                                                break;
                                            case "progressbar":
                                                customOutput2.push(<p key={index} className={`${isPhone.current ? "customComponents__table__data__mobileFriendly" : ""}`} style={{
                                                    gridColumn: isPhone.current ? `1 / span ${props.headers.length}` : null,
                                                    gridRow: isPhone.current ? startRow : null,
                                                    ...(item2.style ?? {}),
                                                    backgroundColor: item2.backgroundColor ?? (item2.style?.backgroundColor ?? null),
                                                    color: item2.color ?? (item2.style?.color ?? null)
                                                }}>
                                                    {isPhone.current && props.headers[index] !== undefined && <span className="customComponents__table__data__mobile">{props.headers[index]}</span>}
                                                    <Progressbar color={item2.color ?? (props.accent ?? "##00A3FF")} maximum={item2.progressMax} value={item2.progressVal} />
                                                </p>);
                                                if (isPhone.current) startRow += 1;
                                                break;
                                            case "custom":
                                                customOutput2.push(<div key={item2Index} className="customComponents__table__data__customInput" style={{
                                                    width: "max-content",
                                                    maxWidth: "100%",
                                                    ...(item2.style ?? {}),
                                                    backgroundColor: item2.backgroundColor ?? (item2.style?.backgroundColor ?? null),
                                                    color: item2.color ?? (item2.style?.color ?? null)
                                                }}>
                                                    {item2.data ?? null}
                                                </div>);
                                                break;
                                            case "text":
                                                customOutput2.push(<p key={item2Index} style={{
                                                    ...(item2.style ?? {}),
                                                    backgroundColor: item2.backgroundColor ?? (item2.style?.backgroundColor ?? null),
                                                    color: item2.color ?? (item2.style?.color ?? null)
                                                }}>{item2.text}</p>);
                                                break;
                                            case "button":
                                                customOutput2.push(<CustomButtonSmall key={item2Index} style={{ minWidth: "100px", width: "auto", padding: "0px 10px", ...(item2.style ?? {}) }} onClick={(e) => {
                                                    if (item2.onClick) item2.onClick(e);
                                                    if (item2.buttonIndex) toggleTriggerDiv(item2.buttonIndex);
                                                }} value={item2.text} accent={props.accent ?? null} />);
                                                break;
                                            default:
                                                break;
                                        }
                                        return customOutput2;
                                    })}
                                </div>
                            )
                            break;
                        default:
                            break;
                    }
                });
                if (props.cItem instanceof Array) {
                    props.cItem.filter(tmp => tmp !== undefined && tmp !== null).forEach((elem, elemIndex) => {
                        if (!(typeof (elem) === "object")) return;
                        if (elem.type === "button") {
                            if (elem.triggerDropdown === true && elem.triggerData !== undefined) {
                                let tmp = createTriggerDiv(`${props.index}_${elemIndex}`, elem.triggerData);
                                customOutput.push(tmp.div);
                                elem.buttonIndex = tmp.index;
                            };
                        } else if (elem.type === "group" || elem.type === "groupNewline") {
                            elem.group.filter(tmp => tmp !== undefined && tmp !== null).forEach((elem2, elem2index) => {
                                if (!(typeof (elem2) === "object")) return;
                                if (elem2.type === "button") {
                                    if (elem2.triggerDropdown === true && elem2.triggerData !== undefined) {
                                        let tmp = createTriggerDiv(`${props.index}${elemIndex}_${elem2index}`, elem2.triggerData);
                                        customOutput.push(tmp.div);
                                        elem2.buttonIndex = tmp.index;
                                    };
                                };
                            });
                        };
                    });
                }
                return customOutput;
            })()}
        </div>
    );
});

const FilterTablePill = (props) => {
    let internalRef = React.useRef();

    React.useEffect(() => {
        if (!internalRef.current) return;

        let cmp = getComputedStyle(internalRef.current);
        if (!internalRef.current) return;
        internalRef.current.animate([
            { maxWidth: cmp.maxWidth ? cmp.maxWidth : 0, padding: cmp.padding ? `10px ${cmp.paddingRight} 10px ${cmp.paddingLeft}` : 0, marginRight: cmp.marginRight ? cmp.marginRight : 0 },
            { maxWidth: "100%", padding: "10px", marginRight: "10px" }
        ], { duration: 300, iterations: 1, fill: "both", easing: "ease-in-out" });
    }, [internalRef.current]);

    const parseType = (t) => {
        switch (t) {
            case "eq": return "equals";
            case "deq": return "equals";
            case "pdeq": return "precisely equals";
            case "neq": return "not equals";
            case "dneq": return "not equals";
            case "pdneq": return "precisely not equals";
            case "gt": return "greater than";
            case "dgt": return "greater than";
            case "pdgt": return "precisely greater than";
            case "lt": return "less than";
            case "dlt": return "less than";
            case "pdlt": return "precisely less than";
            case "geq": return "greater or equals";
            case "dgeq": return "greater or equals";
            case "pdgeq": return "precisely greater than";
            case "leq": return "less or equals";
            case "dleq": return "less or equals";
            case "pdleq": return "precisely less or equals";
            case "like": return "contains";
            case "startsWith": return "starts with";
            case "endsWith": return "ends with";
            default: return t;
        };
    };
    switch (props.data.varType) {
        case "string":
        case "date":
            return <span ref={internalRef} className={`customComponents__table__filterHead__addFilterWrap__item${props.data.ID}`} onClick={() => props.removeFilterItem(props.data.ID)}>
                <span><span style={{ color: "#00A3FF" }}>{props.data.friendlyName}</span>{` ${parseType(props.data.type)} ${props.data.value ? `'${props.data.friendlyValue ? props.data.friendlyValue : props.data.value}'` : "empty string"}`}</span>
                <span>✖</span>
            </span>
        case "number":
            return <span ref={internalRef} className={`customComponents__table__filterHead__addFilterWrap__item${props.data.ID}`} onClick={() => props.removeFilterItem(props.data.ID)}>
                <span><span style={{ color: "#00A3FF" }}>{props.data.friendlyName}</span>{` ${parseType(props.data.type)} ${props.data.value ? `${props.data.value}` : 0}`}</span>
                <span>✖</span>
            </span>
        case "boolean":
            return <span ref={internalRef} className={`customComponents__table__filterHead__addFilterWrap__item${props.data.ID}`} onClick={() => props.removeFilterItem(props.data.ID)}>
                <span><span style={{ color: "#00A3FF" }}>{props.data.friendlyName}</span>{` ${parseType(props.data.type)} `}{props.data.value ? <span style={{ color: "#26e126" }}>Yes</span> : <span style={{ color: "#ee8181" }}>No</span>}</span>
                <span>✖</span>
            </span>
        case "custom": return (() => {
            switch (props.data.filterType) {
                case "string":
                    return <span ref={internalRef} className={`customComponents__table__filterHead__addFilterWrap__item${props.data.ID}`} onClick={() => props.removeFilterItem(props.data.ID)}>
                        <span><span style={{ color: "#00A3FF" }}>{props.data.friendlyName}</span>{` ${parseType(props.data.type)} ${props.data.value ? `'${props.data.value}'` : "empty string"}`}</span>
                        <span>✖</span>
                    </span>
                case "number":
                    return <span ref={internalRef} className={`customComponents__table__filterHead__addFilterWrap__item${props.data.ID}`} onClick={() => props.removeFilterItem(props.data.ID)}>
                        <span><span style={{ color: "#00A3FF" }}>{props.data.friendlyName}</span>{` ${parseType(props.data.type)} ${props.data.value ? `${props.data.value}` : 0}`}</span>
                        <span>✖</span>
                    </span>
                case "boolean":
                    return <span ref={internalRef} className={`customComponents__table__filterHead__addFilterWrap__item${props.data.ID}`} onClick={() => props.removeFilterItem(props.data.ID)}>
                        <span><span style={{ color: "#00A3FF" }}>{props.data.friendlyName}</span>{` ${parseType(props.data.type)}`}{props.data.value ? <span style={{ color: "#26e126" }}>Yes</span> : <span style={{ color: "#ee8181" }}>No</span>}</span>
                        <span>✖</span>
                    </span>
                case "custom":
                    return <span ref={internalRef} className={`customComponents__table__filterHead__addFilterWrap__item${props.data.ID}`} onClick={() => props.removeFilterItem(props.data.ID)}>
                        <span><span style={{ color: "#00A3FF" }}>{props.data.friendlyName}</span>{` ${parseType(props.data.type)}`}{props.data.value ? String(props.data.value) : " empty"}</span>
                        <span>✖</span>
                    </span>
            };
        })()
    };
};

const FilteredCustomTable = (props) => {
    const internalRef = React.useRef();
    let [filterList, setFilterList] = React.useState([]);
    let [oldFilterList, setOldFilterList] = React.useState([]);
    let [curFilter, setCurFilter] = React.useState(null);
    let [tableData, setTableData] = React.useState([]);
    let [tableCheckData, setTableCheckData] = React.useState({});
    let filterValueRef = React.useRef();
    let [filterTypeBox, setFilterTypeBox] = React.useState();
    let [filterValueBox, setFilterValueBox] = React.useState();
    let [headerOrder, setHeaderOrder] = React.useState();
    let themeData = (props.theme === "light" || props.theme === "dark") ? props.theme : "light";
    let gTimeout = null;

    const downOrderArrow = "↓";
    const upOrderArrow = "↑";

    let canAnimate = true;
    if (props.canAnimate !== null && props.canAnimate !== undefined) {
        canAnimate = !!props.canAnimate;
    };

    let customColumnLength = (Array.isArray(props.customColumns) ? props.customColumns.join(" ") : props.customColumns) ?? null;
    if (!customColumnLength) customColumnLength = `repeat(${props.headers?.length ?? 0}, 1fr)`;
    if (props.checkboxCB) {
        if (Array.isArray(customColumnLength)) {
            customColumnLength.unshift("70px");
        } else {
            if (customColumnLength) {
                customColumnLength = `70px ${customColumnLength}`;
            } else {
                customColumnLength = ["70px"];
            };
        };
    };

    React.useEffect(() => {
        let timeoutDelay = 400;
        if (internalObjectToString(props.data) === internalObjectToString(tableData)) timeoutDelay = 0;
        if (!canAnimate) timeoutDelay = 0;
        clearTimeout(gTimeout);
        
        gTimeout = setTimeout(async () => {
            if (!(props.data?.length > 0)) {
                return;
            };
            if (tableData.length === 0) {
                setTableData(props.data);
                return;
            };

            let animateIndexes = [];

            tableData.forEach((item, index) => {
                let found = false;
                let internalStringItem = internalObjectToString([item]);
                for (let newData of props.data) {
                    if (internalStringItem === internalObjectToString([newData])) {
                        found = true;
                        break;
                    };
                };
                if (!found) {
                    animateIndexes.push(index);
                };
            });
            animateIndexes = animateIndexes.map(item => {
                return new Promise(r => {
                    if (!internalRef?.current) return r();
                    let curElement = internalRef.current.querySelector(`.customComponents__table__data[data-index="${item}"]`);
                    if (!curElement) return r();
                    let computed = getComputedStyle(curElement);
                    let curHeight = curElement.getBoundingClientRect().height + "px";
                    curElement.style.overflow = "hidden";
                    curElement.animate([
                        { height: curHeight, padding: computed.padding, overflow: "hidden" },
                        { height: 0, padding: "0 40px", overflow: "hidden" }
                    ], { duration: canAnimate ? 200 : 0, iterations: 1, fill: "both", easing: "ease-in-out" }).onfinish = () => {
                        curElement.style.overflow = null;
                        r();
                    };
                });
            });
            await Promise.allSettled(animateIndexes);
            setTableData(props.data);
        }, timeoutDelay);
    }, [props.data]);

    React.useEffect(() => {
        if (!tableData) return;

        let found = true;
            if (props.defaultChecked && Object.keys(tableCheckData).length === 0) {
                if (!Array.isArray(props.defaultChecked)) return;
    
                let out = {};
                for (let item of props.defaultChecked) {
                    if (!tableCheckData[item]) found = false;
                    out[item] = true;
                };
    
                if (!found) setTableCheckData(out);   
            };
    }, [tableData]);

    React.useEffect(() => {
        if (props.addFilter) props.addFilter(() => {
            return (data) => data ? addFilterItem(data) : null;
        });
        if (props.checkboxFunctions) props.checkboxFunctions(() => {
            return {
                reset: () => setTableCheckData({}),
                select: (items) => {
                    if (!Array.isArray(items)) return;

                    let out = {};
                    for (let item of items) {
                        out[item] = true;
                    };
                    setTableCheckData(ct => {
                        return {
                            ...ct,
                            ...out
                        };
                    });
                }
            };
        });


        if (!internalRef.current) return;
        internalRef.current.animate([
            { opacity: getComputedStyle(internalRef.current).opacity ?? 0 },
            { opacity: 1 }
        ], { duration: 300, iterations: 1, easing: "ease-in-out", fill: "both" });
    }, []);

    const callCB = () => {
        if (props.filterCB) props.filterCB(filterList.map(elem => {
            return {
                name: elem.name,
                op: elem.type,
                value: elem.value
            };
        }));
    };
    const addFilterItem = async (fi) => {
        setFilterList(fl => [...fl, fi]);
    };
    const removeFilterItem = async (id) => {
        let curTarget = internalRef?.current?.querySelector(`.customComponents__table__filterHead__addFilterWrap__item${id}`);
        if (curTarget) {
            let cmp = getComputedStyle(curTarget);
            curTarget.animate([
                { width: cmp.width ? cmp.width : 0, padding: cmp.padding ? cmp.padding : 0, marginRight: cmp.marginRight ? cmp.marginRight : 0 },
                { width: 0, padding: "10px 0", marginRight: 0 }
            ], { duration: 300, iterations: 1, easing: "ease-in-out", fill: "both" }).onfinish = () => {
                setFilterList(fl => fl.filter(item => item.ID !== id));
            };
        } else {
            setFilterList(fl => fl.filter(item => item.ID !== id));
        };
    };

    const verifyCheckedItems = () => {
        let final = {...tableCheckData};
        let changed = false;

        let cKeys = [];
        for (let cItem of tableData) {
            let checkKey = "";

            if (cItem instanceof Array) {
                checkKey = cItem[0].keyID;
            } else if (cItem?.columns instanceof Array) {
                checkKey = cItem.columns[0].keyID;
            } else {
                checkKey = tableData.indexOf(cItem);
            };

            cKeys.push(checkKey);
        };
        for (let key of Object.keys(final)) {
            if (!cKeys.includes(key)) {
                changed = true;
                delete final[key];
            };
        };

        if (changed) setTableCheckData(final);
    };

    const checkAllItems = (newState) => {
        if (tableData.length === 0) return;
        if (!newState && (tableData.length !== Object.keys(tableCheckData).length)) return;

        let final = {...tableCheckData};
        let changed = false;

        for (let cItem of tableData) {
            let checkKey = "";

            if (cItem instanceof Array) {
                checkKey = cItem[0].keyID;
            } else if (cItem?.columns instanceof Array) {
                checkKey = cItem.columns[0].keyID;
            } else {
                checkKey = tableData.indexOf(cItem);
            };

            if (newState) {
                if (!final[checkKey]) {
                    changed = true;
                    final[checkKey] = true;
                };
            } else {
                if (final[checkKey]) {
                    changed = true;
                    delete final[checkKey];
                };
            };
        };
        if (changed) setTableCheckData(final);
    };

    const changeColumnOrder = head => {
        if (!props.orderCB) return;

        if (!headerOrder) {
            setHeaderOrder({name: head, order: "asc"});
        } else {
            if (headerOrder.name !== head) {
                setHeaderOrder({name: head, order: "asc"});
            } else {
                if (headerOrder.order === "desc") {
                    setHeaderOrder();
                } else {
                    setHeaderOrder({name: head, order: "desc"});
                };
            };
        };
    };

    React.useEffect(() => {
        let shouldCall = false;
        for (let item of filterList) {
            let found = oldFilterList.find(elem => elem.ID === item.ID);
            if (!found) {
                shouldCall = true;
                break;
            };
        };
        for (let item of oldFilterList) {
            let found = filterList.find(elem => elem.ID === item.ID);
            if (!found) {
                shouldCall = true;
                break;
            };
        };
        if (shouldCall) {
            setOldFilterList([...filterList]);
            callCB();
        };
    }, [filterList]);

    React.useEffect(() => {
        verifyCheckedItems();
    }, [tableData]);

    React.useEffect(() => {
        if (typeof(props.checkboxCB) === "function") {
            props.checkboxCB(Object.keys(tableCheckData));
        };
    }, [tableCheckData]);

    React.useEffect(() => {
        if (typeof(props.orderCB) === "function") props.orderCB(headerOrder);
    }, [headerOrder]);

    if (!props) return null;
    return <div className={`customComponents__table ${props.className ? props.className : ""} customComponents__table--${themeData}`} ref={internalRef} style={{
        borderLeftColor: props.accent ?? null,
        ...(props.style ?? {}),
        gridTemplateColumns: customColumnLength
    }}>
        <div className="customComponents__table__spinner" style={{
            opacity: props.showSpinner ? 1 : 0,
            pointerEvents: props.showSpinner ? "all" : "none"
        }} onClick={e => props.showSpinner && e.stopPropagation()}>
            <Spinner color={props.spinnerColor ?? (props.accent ?? "white")} />
        </div>
        <div className="customComponents__table__filterHead" style={{
            padding: props.filters?.length > 0 ? null : "0",
            display: props.filters?.length > 0 ? null : "none"
        }}>
            {props.filters && <div className="customComponents__table__filterHead__filterList">
                <p className="customComponents__table__filterHead__filterList__addFilter" onClick={() => {
                    let curElem = internalRef?.current?.querySelector(".customComponents__table__filterHead__addFilterWrap");
                    if (curElem) {
                        curElem.classList.toggle("customComponents__table__filterHead__addFilterWrap--active");
                    };
                }}>+ Add filter &nbsp; <img style={{ marginLeft: ' 10px', filter: "invert(0)" }} src="/images/filter.png" /></p>
                {filterList.map((fi) => {
                    return <FilterTablePill key={fi.ID} id={fi.ID} data={fi} removeFilterItem={removeFilterItem} />
                })}
            </div>}
        </div>
        {props.filters && <div className="customComponents__table__filterHead__addFilterWrap">
            <Dropdown accent={props.accent ?? null} theme={themeData} selected={0} selectText="Filter column" data={props.filters.map((item, index) => {
                if (index === 0 && !curFilter) setCurFilter(item);
                return {
                    name: item.friendlyName,
                    value: index
                }
            })} onChange={val => {
                setCurFilter(props.filters.find((_, index) => String(index) === String(val.value)));
            }} />
            {(() => {
                switch (curFilter?.type) {
                    case "string": return <Dropdown key="customComponent__table__filterType__string" accent={props.accent ?? null} theme={themeData} selected={0} data={[
                        { name: "Equals", value: "eq" },
                        { name: "Not equals", value: "neq" },
                        { name: "Contains", value: "like" },
                        { name: "Starts with", value: "startsWith" },
                        { name: "Ends with", value: "endsWith" }
                    ]} onChange={e => setFilterTypeBox(e)} />
                    case "number": return <Dropdown key="customComponent__table__filterType__number" accent={props.accent ?? null} theme={themeData} selected={0} data={[
                        { name: "Equals", value: "eq" },
                        { name: "Not equals", value: "neq" },
                        { name: "Greater than", value: "gt" },
                        { name: "Greater or equals", value: "geq" },
                        { name: "Less than", value: "lt" },
                        { name: "Less or equals", value: "leq" }
                    ]} onChange={e => setFilterTypeBox(e)} />
                    case "date": return <Dropdown key="customComponent__table__filterType__date" accent={props.accent ?? null} theme={themeData} selected={0} data={[
                        { name: "Equals", value: "deq" },
                        { name: "Not equals", value: "dneq" },
                        { name: "Greater than", value: "dgt" },
                        { name: "Greater or equals", value: "dgeq" },
                        { name: "Less than", value: "dlt" },
                        { name: "Less or equals", value: "dleq" },

                        { name: "Precisely equals", value: "pdeq" },
                        { name: "Precisely not equals", value: "pdneq" },
                        { name: "Precisely greater than", value: "pdgt" },
                        { name: "Precisely greater or equals", value: "pdgeq" },
                        { name: "Precisely less than", value: "pdlt" },
                        { name: "Precisely less or equals", value: "pdleq" }
                    ]} onChange={e => setFilterTypeBox(e)} />
                    case "boolean": return <Dropdown key="customComponent__table__filterType__boolean" accent={props.accent ?? null} theme={themeData} selected={0} data={[
                        { name: "Equals", value: "eq" },
                        { name: "Not equals", value: "neq" }
                    ]} onChange={e => setFilterTypeBox(e)} />
                    case "custom": return (() => {
                        switch (curFilter?.varType) {
                            case "string": return <Dropdown key="customComponent__table__filterType__custom-string" accent={props.accent ?? null} theme={themeData} selected={0} data={[
                                { name: "Equals", value: "eq" },
                                { name: "Not equals", value: "neq" },
                                { name: "Contains", value: "like" },
                                { name: "Starts with", value: "startsWith" },
                                { name: "Ends with", value: "endsWith" }
                            ]} onChange={e => setFilterTypeBox(e)} />
                            case "number": return <Dropdown key="customComponent__table__filterType__custom-number" accent={props.accent ?? null} theme={themeData} selected={0} data={[
                                { name: "Equals", value: "eq" },
                                { name: "Not equals", value: "neq" },
                                { name: "Greater than", value: "gt" },
                                { name: "Greater or equals", value: "geq" },
                                { name: "Less than", value: "lt" },
                                { name: "Less or equals", value: "leq" }
                            ]} onChange={e => setFilterTypeBox(e)} />
                            case "date": return <Dropdown key="customComponent__table__filterType__custom-date" accent={props.accent ?? null} theme={themeData} selected={0} data={[
                                { name: "Equals", value: "deq" },
                                { name: "Not equals", value: "dneq" },
                                { name: "Greater than", value: "dgt" },
                                { name: "Greater or equals", value: "dgeq" },
                                { name: "Less than", value: "dlt" },
                                { name: "Less or equals", value: "dleq" },

                                { name: "Precisely equals", value: "pdeq" },
                                { name: "Precisely not equals", value: "pdneq" },
                                { name: "Precisely greater than", value: "pdgt" },
                                { name: "Precisely greater or equals", value: "pdgeq" },
                                { name: "Precisely less than", value: "pdlt" },
                                { name: "Precisely less or equals", value: "pdleq" }
                            ]} onChange={e => setFilterTypeBox(e)} />
                            case "boolean": return <Dropdown key="customComponent__table__filterType__custom-boolean" accent={props.accent ?? null} theme={themeData} selected={0} data={[
                                { name: "Equals", value: "eq" },
                                { name: "Not equals", value: "neq" }
                            ]} onChange={e => setFilterTypeBox(e)} />
                            case "custom": return <Dropdown key="customComponent__table__filterType__custom-custom" accent={props.accent ?? null} theme={themeData} selected={0} data={[
                                { name: "Equals", value: "eq" },
                                { name: "Not equals", value: "neq" }
                            ]} onChange={e => setFilterTypeBox(e)} />
                            default: return null;
                        };
                    })()
                    default: return null;
                };
            })()}
            {(() => {
                switch (curFilter?.type) {
                    case "date":
                        switch (filterTypeBox?.value) {
                            case "pdeq":
                            case "pdneq":
                            case "pdgt":
                            case "pdgeq":
                            case "pdlt":
                            case "pdleq":
                                return <CustomInput style={{ width: "300px" }} accent={props.accent ?? null} theme={themeData} ref={filterValueRef} placeholder="Value (date/time)" type="datetime-local" />;
                            default:
                                return <CustomInput accent={props.accent ?? null} theme={themeData} ref={filterValueRef} placeholder="Value (date)" type="date" />;
                        }
                    case "string": return <CustomInput accent={props.accent ?? null} theme={themeData} ref={filterValueRef} placeholder="Value (text/string)" />
                    case "number": return <CustomInput accent={props.accent ?? null} theme={themeData} ref={filterValueRef} placeholder="Value (number)" type="number" />
                    case "boolean": return <Dropdown accent={props.accent ?? null} theme={themeData} data={[
                        { value: true, name: "Yes / true" },
                        { value: false, name: "No / false" }
                    ]} selected={0} onChange={e => setFilterValueBox(e)} />
                    case "custom": return <Dropdown accent={props.accent ?? null} theme={themeData} data={curFilter.data.map(item => {
                        return { value: item.value, name: item.text }
                    })} selected={0} onChange={e => setFilterValueBox(e)} />
                    default: return null;
                };
            })()}
            <CustomButtonSmall style={{ marginLeft: '20px' }} value="Add filter" accent={props.accent ?? null} onClick={() => {
                switch (curFilter?.type) {
                    case "string":
                        try {
                            let final = {
                                ID: Date.now(),
                                name: curFilter.name,
                                friendlyName: curFilter.friendlyName,
                                type: filterTypeBox !== null ? filterTypeBox.value : null,
                                value: filterValueRef.current.value,
                                varType: "string",
                                filterType: curFilter.varType
                            };
                            addFilterItem(final);
                            filterValueRef.current.value = "";
                            break;
                        } catch { };
                    case "number":
                        try {
                            let final2 = {
                                ID: Date.now(),
                                name: curFilter.name,
                                friendlyName: curFilter.friendlyName,
                                type: filterTypeBox !== null ? filterTypeBox.value : null,
                                value: isNaN(Number(filterValueRef.current.value)) ? 0 : Number(filterValueRef.current.value),
                                varType: "number",
                                filterType: curFilter.varType
                            };
                            addFilterItem(final2);
                            filterValueRef.current.value = "";
                        } catch { };
                        break;
                    case "boolean":
                        try {
                            let final3 = {
                                ID: Date.now(),
                                name: curFilter.name,
                                friendlyName: curFilter.friendlyName,
                                type: filterTypeBox !== null ? filterTypeBox.value : null,
                                value: filterValueBox.value ? true : false,
                                varType: "boolean",
                                filterType: curFilter.varType
                            };
                            addFilterItem(final3);
                        } catch { };
                        break;
                    case "date":
                        switch (filterTypeBox?.value) {
                            case "pdeq":
                            case "pdneq":
                            case "pdgt":
                            case "pdgeq":
                            case "pdlt":
                            case "pdleq":
                                try {
                                    let tmpDate = new Date(filterValueRef.current.value);
                                    let finaldate = {
                                        ID: Date.now(),
                                        name: curFilter.name,
                                        friendlyName: curFilter.friendlyName,
                                        type: filterTypeBox !== null ? filterTypeBox.value : null,
                                        value: tmpDate.toISOString(),
                                        varType: "date",
                                        filterType: curFilter.varType,
                                        friendlyValue: tmpDate.toLocaleString()
                                    };
                                    addFilterItem(finaldate);
                                } catch { };
                                break;
                            default:
                                try {
                                    let tmpDate = new Date(filterValueRef.current.value);
                                    let finaldate = {
                                        ID: Date.now(),
                                        name: curFilter.name,
                                        friendlyName: curFilter.friendlyName,
                                        type: filterTypeBox !== null ? filterTypeBox.value : null,
                                        value: tmpDate.toISOString(),
                                        varType: "date",
                                        filterType: curFilter.varType,
                                        friendlyValue: tmpDate.toLocaleDateString()
                                    };
                                    addFilterItem(finaldate);
                                } catch { };
                                break;
                        }
                        break;
                    case "custom":
                        try {
                            let final4 = {
                                ID: Date.now(),
                                name: curFilter.name,
                                friendlyName: curFilter.friendlyName,
                                type: filterTypeBox !== null ? filterTypeBox.value : null,
                                value: (() => {
                                    if (curFilter.varType === "boolean") return (String(filterValueBox.value) === "true") ? true : false;
                                    if (curFilter.varType === "number") return Number(filterValueBox.value);
                                    if (curFilter.varType === "string") return String(filterValueBox.value);
                                    if (curFilter.varType === "date") return new Date(filterValueBox.value).toISOString();
                                    if (curFilter.varType === "custom") return filterValueBox.value;
                                })(),
                                varType: curFilter.type,
                                filterType: curFilter.varType
                            };
                            addFilterItem(final4);
                        } catch { };
                        break;
                };
                let curElem = internalRef?.current?.querySelector(".customComponents__table__filterHead__addFilterWrap");
                if (curElem) {
                    curElem.classList.toggle("customComponents__table__filterHead__addFilterWrap--active");
                };
            }} />
        </div>}
        <div className="customComponents__table__head" style={{
            ...(props.headerStyle ?? {}),
            gridColumn: `1 / span all`,
            gridTemplateColumns: "subgrid"
        }}>
            {props.checkboxCB && <Checkbox
                checked={(tableData.length > 0 && (tableData.length === Object.keys(tableCheckData).length))}
                onClick={e => {
                    if (tableData.length === 0) return;
                    checkAllItems(!(tableData.length === Object.keys(tableCheckData).length));
                }}
            />}
            {props.headers && props.headers.filter(tmp => tmp !== undefined && tmp !== null).map((item, index) => {
                let orderArrow = "";

                if (headerOrder && props.orderCB) {
                    if (headerOrder?.name === item) {
                        if (headerOrder.order === "asc") {
                            orderArrow = downOrderArrow;
                        } else if (headerOrder.order === "desc") {
                            orderArrow = upOrderArrow;
                        };
                    };
                };

                return <p key={index} className="customComponents__table__head__item" style={{
                    cursor: props.orderCB ? "pointer" : "default"
                }} onClick={() => changeColumnOrder(item)}>{item}{orderArrow ? <span className="customComponents__table__head__item__order">{orderArrow}</span> : ""}</p>
            })}
        </div>
        {tableData.map((cItem, index) => {
            let finalKey = "";
            let checkKey = "";

            if (cItem instanceof Array) {
                cItem.forEach(elem => elem.keyID ? finalKey += elem.keyID : null);
                checkKey = cItem[0].keyID;
            } else if (cItem?.columns instanceof Array) {
                cItem.columns.forEach(elem => elem.keyID ? finalKey += elem.keyID : null);
                checkKey = cItem.columns[0].keyID;
            } else {
                checkKey = index
            };
            if (!finalKey) finalKey = index;
            if (!checkKey) checkKey = index;

            return <FilterCustomTableItem
                canAnimate={canAnimate}
                accent={props.accent ?? null}
                style={{
                    backgroundColor: props.colors ? (props.colors[index] ?? null) : null
                }}
                key={finalKey}
                childKey={finalKey}
                cItem={cItem}
                index={index}
                headers={props.headers}
                max={tableData.length}
                customColumns={customColumnLength}
                checkboxCB={props.checkboxCB}
                checkBoxState={tableCheckData[checkKey]}
                events={cItem?.events ?? {}}
                checkBoxChange={e => {
                    if (!e) {
                        if (!tableCheckData[checkKey]) return;
                        setTableCheckData(tc => {
                            delete tc[checkKey];
                            return {...tc};
                        });
                    } else {
                        if (tableCheckData[checkKey]) return;
                        setTableCheckData(tc => {
                            return {...tc, [checkKey]: true};
                        });
                    };
                }}
                />
        })}
    </div>
};

FilteredCustomTable.propTypes = {
    customColumns: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ]),
    headers: PropTypes.arrayOf(PropTypes.string),
    theme: PropTypes.oneOf(["light", "dark"]),
    filters: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        friendlyName: PropTypes.string,
        type: PropTypes.oneOf(["string", "number", "boolean", "date", "custom"]),
        varType: PropTypes.oneOf(["string", "number", "boolean", "date", "custom"]),
        data: PropTypes.arrayOf(PropTypes.shape({
            text: PropTypes.string,
            value: PropTypes.string
        }))
    })),
    data: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape({
            keyID: PropTypes.string,
            type: PropTypes.oneOf(["text", "button", "group", "groupNewline", "custom", "spinner", "progressbar"]),
            onClick: PropTypes.func,
            text: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number
            ]),
            progressMax: PropTypes.number,
            progressVal: PropTypes.number,
            data: PropTypes.any,
            group: PropTypes.arrayOf(PropTypes.shape({
                keyID: PropTypes.string,
                type: PropTypes.oneOf(["text", "button", "group", "groupNewline", "custom", "spinner", "progressbar"]),
                onClick: PropTypes.func,
                text: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number
                ]),
                style: PropTypes.object
            }))
        })),
        PropTypes.shape({
            backgroundColor: PropTypes.string,
            color: PropTypes.string,
            columns: PropTypes.arrayOf(PropTypes.shape({
                keyID: PropTypes.string,
                type: PropTypes.oneOf(["text", "button", "group", "groupNewline", "custom", "spinner", "progressbar"]),
                onClick: PropTypes.func,
                text: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number
                ]),
                progressMax: PropTypes.number,
                progressVal: PropTypes.number,
                data: PropTypes.any,
                group: PropTypes.arrayOf(PropTypes.shape({
                    keyID: PropTypes.string,
                    type: PropTypes.oneOf(["text", "button", "group", "groupNewline", "custom", "spinner", "progressbar"]),
                    onClick: PropTypes.func,
                    text: PropTypes.oneOfType([
                        PropTypes.string,
                        PropTypes.number
                    ]),
                    style: PropTypes.object
                }))
            })),
            rowEvents: PropTypes.Object
        })
    ])),
    filterCB: PropTypes.func,
    orderCB: PropTypes.func,
    addFilter: PropTypes.func,
    style: PropTypes.instanceOf(React.CSSProperties),
    headerStyle: PropTypes.instanceOf(React.CSSProperties),
    accent: PropTypes.string,
    colors: PropTypes.arrayOf(PropTypes.string),

    checkboxCB: PropTypes.func,
    checkboxFunctions: PropTypes.func,
    defaultChecked: PropTypes.arrayOf(PropTypes.string),

    canAnimate: PropTypes.bool,

    showSpinner: PropTypes.bool,
    spinnerColor: PropTypes.string
}

export { FilteredCustomTable };