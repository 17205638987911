import React from "react";
import "./index.scss";

import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import animateModule from "../../modules/animateModule";

import { ReactComponent as Dashboard_img } from "./images/sidebar_dashboard.svg";
import { ReactComponent as Campaigns_img } from "./images/sidebar_campaigns.svg";
import { ReactComponent as Optimize_img } from "./images/sidebar_optimize.svg";
import { ReactComponent as Reports_img } from "./images/sidebar_reports.svg";
import { ReactComponent as Creatives_img } from "./images/sidebar_creatives.svg";
import { ReactComponent as Offers_img } from "./images/sidebar_offers.svg";
import { ReactComponent as Sites_img } from "./images/sidebar_sites.svg";
import { ReactComponent as Heatmaps_img } from "./images/sidebar_heatmaps.svg";
import { ReactComponent as Integrations_img } from "./images/sidebar_integrations.svg";
import { ReactComponent as SmsMarketing_img } from "./images/sidebar_smsMarketing.svg";
import { ReactComponent as Currencies_img } from "./images/sidebar_currencies.svg";

import { ReactComponent as Users_img } from "./images/sidebar_users.svg";
import { ReactComponent as UserGroups_img } from "./images/sidebar_usergroups.svg";

const Sidebar = (props) => {
    const curLocation = useLocation();
    const curNavigate = useNavigate();

    const userInfoSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".root__content"));

    const [windowSize, setWindowSize] = React.useState(window.innerWidth);

    const checkSidebarActive = elem => {
        if (!elem) return curLocation.pathname === "/";
        return (curLocation.pathname.startsWith(`/${elem}`));
    };



    React.useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize(window.innerWidth);
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    })

    return <>
        <div className={`component__sidebar ${props.sidebarOpened ? 'component__sidebar--opened' : ''}`} onMouseEnter={() => {
            if (windowSize > 768) {
                props.setSidebar(true)
            }
        }} onMouseLeave={() => {
            if (windowSize > 768) {
                props.setSidebar(false)
            }
        }}>
            <div className="component__sidebar__logo">
                <img src="/images/logoSmall.svg" />
                <p>Cupid Affiliate</p>
                <div className="component__sidebar__logo__x" onClick={props.toggleSidebar()}>
                    <div className="component__sidebar__logo__x__line"></div>
                    <div className="component__sidebar__logo__x__line-2"></div>
                </div>
            </div>
            <div className="component__sidebar__line"></div>
            <div className="component__sidebar__text">
                <span style={{ opacity: props.sidebarOpened ? '1' : '0', maxWidth: props.sidebarOpened ? '70px' : '0px' }}>Publisher</span>
                <span className="component__sidebar__text--short" style={{ opacity: props.sidebarOpened ? '0' : '1', maxWidth: props.sidebarOpened ? '0px' : '70px' }}>Pub</span>
            </div>
            <div onClick={() => animateNavigate("/publisher-dashboard")} className={`component__sidebar__item ${checkSidebarActive("publisher-dashboard") ? "component__sidebar__item--active" : ""}`}>
                <div style={{ width: '24px !important' }}>
                    <Dashboard_img />
                </div>

                <p>Dashboard</p>
            </div>
            <div onClick={() => animateNavigate("/publisher-offers")} className={`component__sidebar__item ${(checkSidebarActive("publisher-offers") || checkSidebarActive("publisher-takenoffers")) ? "component__sidebar__item--active" : ""}`}>
                <div style={{ width: '24px !important' }}><SmsMarketing_img /></div>
                <p>Offers</p>
            </div>
            <div onClick={() => animateNavigate("/publisher-postback")} className={`component__sidebar__item ${checkSidebarActive("publisher-postback") ? "component__sidebar__item--active" : ""}`}>
                <div style={{ width: '24px !important' }}><Creatives_img /></div>
                <p>Postback</p>
            </div>
            <div onClick={() => animateNavigate("/publisher-leads")} className={`component__sidebar__item ${checkSidebarActive("publisher-leads") ? "component__sidebar__item--active" : ""}`}>
                <div style={{ width: '24px !important' }}><Heatmaps_img /></div>
                <p>Leads</p>
            </div>
            {(!userInfoSelector?.Flags?.isTeamMember) && <div onClick={() => animateNavigate("/publisher-team")} className={`component__sidebar__item ${checkSidebarActive("publisher-team") ? "component__sidebar__item--active" : ""}`}>
                <div style={{ width: '24px !important' }}><Integrations_img /></div>
                <p>Team</p>
            </div>}
            {/*             
            <div onClick={() => animateNavigate("/campaigns")} className={`component__sidebar__item ${checkSidebarActive("campaigns") ? "component__sidebar__item--active" : ""}`}>
                <div style={{ width: '24px !important' }}><Campaigns_img /></div>
                <p>Campaigns</p>
            </div>
            <div onClick={() => animateNavigate("/optimize")} className={`component__sidebar__item ${checkSidebarActive("optimize") ? "component__sidebar__item--active" : ""}`}>
                <div style={{ width: '24px !important' }}><Optimize_img /></div>
                <p>Optimize</p>
            </div>
            <div onClick={() => animateNavigate("/reports")} className={`component__sidebar__item ${checkSidebarActive("reports") ? "component__sidebar__item--active" : ""}`}>
                <div style={{ width: '24px !important' }}><Reports_img /></div>
                <p>Reports</p>
            </div>
            <div onClick={() => animateNavigate("/creatives")} className={`component__sidebar__item ${checkSidebarActive("creatives") ? "component__sidebar__item--active" : ""}`}>
                <div style={{ width: '24px !important' }}><Creatives_img /></div>
                <p>Creatives</p>
            </div>
            <div onClick={() => animateNavigate("/offers")} className={`component__sidebar__item ${checkSidebarActive("offers") ? "component__sidebar__item--active" : ""}`}>
                <div style={{ width: '24px !important' }}><Offers_img /></div>
                <p>Offers</p>
            </div>
            <div onClick={() => animateNavigate("/sites")} className={`component__sidebar__item ${checkSidebarActive("sites") ? "component__sidebar__item--active" : ""}`}>
                <div style={{ width: '24px !important' }}><Sites_img /></div>
                <p>Sites</p>
            </div>
            <div onClick={() => animateNavigate("/heatmaps")} className={`component__sidebar__item ${checkSidebarActive("heatmaps") ? "component__sidebar__item--active" : ""}`}>
                <div style={{ width: '24px !important' }}><Heatmaps_img /></div>
                <p>Heatmaps</p>
            </div>
            <div onClick={() => animateNavigate("/integrations")} className={`component__sidebar__item ${checkSidebarActive("integrations") ? "component__sidebar__item--active" : ""}`}>
                <div style={{ width: '24px !important' }}><Integrations_img /></div>
                <p>Integrations</p>
            </div>
            <div onClick={() => animateNavigate("/smsMarketing")} className={`component__sidebar__item ${checkSidebarActive("smsMarketing") ? "component__sidebar__item--active" : ""}`}>
                <div style={{ width: '24px !important' }}><SmsMarketing_img /></div>
                <p>SMS Marketing</p>
            </div> */}
            {(userInfoSelector?.Flags?.isAdvertiser) && <>
                <div className="component__sidebar__line"></div>
                <div className="component__sidebar__text">
                    <span style={{ opacity: props.sidebarOpened ? '1' : '0', maxWidth: props.sidebarOpened ? '70px' : '0px' }}>Advertiser</span>
                    <span className="component__sidebar__text--short" style={{ opacity: props.sidebarOpened ? '0' : '1', maxWidth: props.sidebarOpened ? '0px' : '70px' }}>Adv</span>
                </div>

                <div onClick={() => animateNavigate("/advertiser-dashboard")} className={`component__sidebar__item ${checkSidebarActive("advertiser-dashboard") ? "component__sidebar__item--active" : ""}`}>
                    <div style={{ width: '24px !important' }}>
                        <Dashboard_img />
                    </div>
                    <p>Dashboard</p>
                </div>

                <div onClick={() => animateNavigate("/advertiser-offers")} className={`component__sidebar__item ${checkSidebarActive("advertiser-offers") ? "component__sidebar__item--active" : ""}`}>
                    <div style={{ width: '24px !important' }}>
                        <Currencies_img />
                    </div>

                    <p>Offers</p>
                </div>
                <div onClick={() => animateNavigate("/advertiser-leads")} className={`component__sidebar__item ${checkSidebarActive("advertiser-leads") ? "component__sidebar__item--active" : ""}`}>
                    <div style={{ width: '24px !important' }}>
                        <Heatmaps_img />
                    </div>

                    <p>Leads</p>
                </div>

            </>}


            {(userInfoSelector?.Flags?.isAdmin || userInfoSelector?.Flags?.isManager) && <>
                <div className="component__sidebar__line"></div>
                <div className="component__sidebar__text">
                    <span style={{ opacity: props.sidebarOpened ? '1' : '0', maxWidth: props.sidebarOpened ? '70px' : '0px' }}>Admin</span>
                    <span className="component__sidebar__text--short" style={{ opacity: props.sidebarOpened ? '0' : '1', maxWidth: props.sidebarOpened ? '0px' : '70px' }}>Adm</span>
                </div>
                <div onClick={() => animateNavigate("/admin-dashboard")} className={`component__sidebar__item ${checkSidebarActive("admin-dashboard") ? "component__sidebar__item--active" : ""}`}>
                    <div style={{ width: '24px !important' }}>
                        <Dashboard_img />
                    </div>

                    <p>Dashboard</p>
                </div>
                <div onClick={() => animateNavigate("/admin-websites")} className={`component__sidebar__item ${checkSidebarActive("admin-websites") ? "component__sidebar__item--active" : ""}`}>
                    <div style={{ width: '24px !important' }}><Sites_img /></div>

                    <p>Websites</p>
                </div>

                <div onClick={() => animateNavigate("/admin-users")} className={`component__sidebar__item ${checkSidebarActive("admin-users") ? "component__sidebar__item--active" : ""}`}>
                    <div style={{ width: '24px !important' }}><Users_img /></div>

                    <p>Users</p>
                </div>
                <div onClick={() => animateNavigate("/admin-advertisers")} className={`component__sidebar__item ${checkSidebarActive("admin-advertisers") ? "component__sidebar__item--active" : ""}`}>
                    <div style={{ width: '24px !important' }}><Users_img /></div>

                    <p>Advertisers</p>
                </div>
                <div onClick={() => animateNavigate("/admin-currencies")} className={`component__sidebar__item ${checkSidebarActive("admin-currencies") ? "component__sidebar__item--active" : ""}`}>
                    <div style={{ width: '24px !important' }}>
                        <Currencies_img />
                    </div>

                    <p>Currencies</p>
                </div>
                <div onClick={() => animateNavigate("/admin-domains")} className={`component__sidebar__item ${checkSidebarActive("admin-domains") ? "component__sidebar__item--active" : ""}`}>
                    <div style={{ width: '24px !important' }}>
                        <Currencies_img />
                    </div>

                    <p>Domains</p>
                </div>
                <div onClick={() => animateNavigate("/admin-offers")} className={`component__sidebar__item ${checkSidebarActive("admin-offers") ? "component__sidebar__item--active" : ""}`}>
                    <div style={{ width: '24px !important' }}>
                        <Currencies_img />
                    </div>

                    <p>Offers</p>
                </div>
                <div onClick={() => animateNavigate("/admin-leads")} className={`component__sidebar__item ${checkSidebarActive("admin-leads") ? "component__sidebar__item--active" : ""}`}>
                    <div style={{ width: '24px !important' }}>
                        <Heatmaps_img />
                    </div>

                    <p>Leads</p>
                </div>
                <div onClick={() => animateNavigate("/admin-payments")} className={`component__sidebar__item ${checkSidebarActive("admin-payments") ? "component__sidebar__item--active" : ""}`}>
                    <div style={{ width: '24px !important' }}>
                        <Heatmaps_img />
                    </div>

                    <p>Payments</p>
                </div>
            </>}
        </div>
    </>
};

export default Sidebar;