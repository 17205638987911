import React from "react";
import "./index.scss";

import { useSelector } from "react-redux";
import axios from "axios";
import * as backendModule from "../../../modules/backendModule";
import { animateBox } from "../../../modules/componentAnimation";
import { countries } from "../../../modules/countryModule";

import useDefer from "../../../modules/hooks/useDefer";
import useOnScreen from "../../../modules/hooks/useOnScreen";

import Spinner from "../../../components/customComponents/Spinner";
import FilterPanel from "../../../components/customComponents/FilterPanel";
import Dropdown from "../../../components/customComponents/Dropdown";
import FilterBySearch from "../../../filters/FilterBySearch";
import RadioButton from "../../../components/customComponents/RadioButton";
import { FilteredCustomTable } from "../../../components/customComponents/Table";
import { checkMail } from "../../../modules/miscModule";
import TeamLeader from "./teamLeader";


const PublisherTeam = () => {

    const userDataSelector = useSelector(state => state?.userData?.userData.UserInfo.Flags ?? {});




    return <div className="route__team">
        {(!userDataSelector?.isTeamLeader && !userDataSelector.isTeamMember) && <div className="route__team__create">
            <p>Currently you don't have crew yet, click on the button below to create one</p>
            <div className="route__team__create__button" onClick={e => {
                animateBox(e, <CreateUser />)
            }}>
                Create
            </div>
        </div>}
        {userDataSelector?.isTeamLeader && <div className="route__team__teamleader" >
            <TeamLeader />
        </div>}



    </div>
};




const CreateUser = (props) => {
    const [item, setItem] = React.useState(props.item);
    const [spinner, setSpinner] = React.useState(false);
    const [selectedCountry, setSelectedCountry] = React.useState();
    const [error, setError] = React.useState();
    const [focusedInput, setFocusedInput] = React.useState();
    const [earningState, setEarningState] = React.useState(0);

    const usernameRef = React.useRef();
    const emailRef = React.useRef();
    const passwordRef = React.useRef();
    const firstNameRef = React.useRef();
    const lastNameRef = React.useRef();
    const zipRef = React.useRef();
    const experienceRef = React.useRef();

    const whatsappRef = React.useRef();
    const skypeRef = React.useRef();
    const telegramRef = React.useRef();

    const earningsRef = React.useState();



    const saveData = () => {
        setError();
        let data = {
            username: firstNameRef.current.value.toLowerCase()+"."+lastNameRef.current.value.toLowerCase(),
            password: passwordRef.current.value,
            email: emailRef.current.value,
            flags: {
                isAdmin: false,
                isAdvertiser: false,
                isManager: false,
                isManager: false,
                isVerified: true,
                isTeamLeader: false,
                isTeamMember: true
            },

            FirstName: firstNameRef.current.value,
            LastName: lastNameRef.current.value,
            EarningsPercentage: earningsRef.current.value
        };

        if (!props.edit) {
            if (!data.username) return setError("Username is required");
            if (data.username.length < 8 || data.username.length > 64) return setError("Usernaem must be between 8 and 64 characters");
            if (!data.password) return setError("Password is required");
            if (data.password.length < 8 || data.password.length > 64) return setError("Password must be between 8 and 64 characters");
        } else {
            if (data.password) {
                if (data.password.length < 8 || data.password.length > 64) return setError("Password must be between 8 and 64 characters");
            };
            data["id"] = item?.ID;
            data["newPassword"] = data.password;
            data["newEmail"] = data.email;
            data["newFlags"] = data.flags;
        };
        if (!data.email) return setError("Email is required");
        if (!checkMail(data.email)) return setError("Email is invalid");
        if (!data.FirstName) return setError("First name is required");
        if (!data.LastName) return setError("Last name is required");
        let path = '/createNewTeamMember';
        if (props.edit) path = '/editTeamMember';
        setSpinner(true)
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/teams${path}`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.update();
                props.onClose();
            } else {
                setError('Please check all your info and try again');
            }
        }).catch((e) => {
            setError('Something went wrong please try again later')
        }).finally(() => { setSpinner(false) })
    };

    return <div className="modals__userModal">
        <div className="modals__userModal__container">
            <div className="modals__userModal__close">
                <img src="/images/closeIcon.png" onClick={() => { props.onClose() }} />
            </div>
            <p className="modals__userModal__head">{props.edit ? "Edit user" : "Add new user"}</p>
            <p className="modals__userModal__label">Email  of team member  </p>
            <input ref={emailRef} defaultValue={item?.Email} type="text" placeholder="Email" />
            <p className="modals__userModal__label">Password  of team member </p>
            <input ref={passwordRef} type="password" placeholder="Password" />
            <p className="modals__userModal__label">First name  of team member </p>
            <input ref={firstNameRef} defaultValue={item?.FirstName} type="text" placeholder="First name" />
            <p className="modals__userModal__label">Last name  of team member </p>
            <input ref={lastNameRef} defaultValue={item?.LastName} type="text" placeholder="Last name" />
            <p className="modals__userModal__label">Percentage of earnings taken by the team leader</p>
            <input ref={earningsRef} onChange={(e) => { setEarningState(e.target.value) }} type="number" defaultValue={item?.FirstName} placeholder="Percentage of earnings" />
            {earningState ? <p className="modals__userModal__label">Team member will get {100 - Number(earningState)} % of earnings and you will get {earningState} % of earnings </p>:""}
            <p className="modals__userModal__error">{error}</p>
            <div className="modals__userModal__buttons">
                {spinner ? <Spinner color={"#00A3FF"} /> : <button onClick={() => { saveData() }}>Save</button>}
            </div>
        </div>
    </div>
}








export default PublisherTeam;