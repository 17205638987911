import "./index.scss";

const Footer = () => {
    return <div className="component__footer">
        <div className="component__footer__wrap">
            <div className="component__footer__wrap__1">
                <img src="./images/header_logo.svg" />
                <p>© 2023 CupidCoast . All rights reserved.</p>
            </div>
            <div className="component__footer__wrap__2">
                <p>Affiliates</p>
                <p>Advertisers</p>
                <p>About</p>
            </div>
            <div className="component__footer__wrap__3">
                <p>Events</p>
                <p>Blog</p>
                <p>Carrers</p>
            </div>
            <div className="component__footer__wrap__4">
                <p>Contact</p>
                <p>Sign up</p>
            </div>
        </div>
    </div>
};

export default Footer;