import React from "react";
import "./index.scss";

import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment/moment";
import * as backendModule from "../../../modules/backendModule";
import { animateBox } from "../../../modules/componentAnimation";
import Spinner from "../../../components/customComponents/Spinner";
import FilterPanel from "../../../components/customComponents/FilterPanel";
import { FilteredCustomTable } from "../../../components/customComponents/Table";
import useDefer from "../../../modules/hooks/useDefer";
import useOnScreen from "../../../modules/hooks/useOnScreen";
import FilterBySearch from "../../../filters/FilterBySearch";
import Dropdown from "../../../components/customComponents/Dropdown";

const Postback = () => {
    const [data, setData] = React.useState();
    const [filters, setFilters] = React.useState([]);
    const [spinner, setSpinner] = React.useState(false)
    const [selectedItem, setSelectedItem] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [search, setSearch] = React.useState("");
    const [help, setHelp] = React.useState();
    const curNavigate = useNavigate();
    const curLocation = useLocation();
    const curDefer = useDefer();
    const curOnScreen = useOnScreen();

    const curUserSelector = useSelector(state => state?.userData?.userData?.UserInfo);

    const getData = () => {
        const filtersTmp = [...filters];

        if (search) {
            filtersTmp.push({
                or: [
                    { name: "PostbackName", op: "like", value: search },
                    { name: "PostbackType", op: "like", value: search },
                    { name: "PostbackTrigger", op: "like", value: search },
                    { name: "PostbackType", op: "like", value: search }
                ]
            })
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/postback/getAllPostbacks`,
            data: {
                filters: filtersTmp,
                limit: 20
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) {
                    setCanPaginate(true);
                } else {
                    setCanPaginate(false);
                };
            };
            setData(res.data);
        }).catch(() => {
            setData(backendModule.axiosConfig);
        });
    };

    const continueData = () => {

        setCanPaginate(false);
        if (!data) return;
        if (data.status !== "ok") return;
        if (data.data.length === 0) return;

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/postback/getAllPostbacks`,
            data: {
                limit: 20,
                offset: data.data.length
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
                if (res.data.data.length === 20) {
                    setCanPaginate(true);
                } else {
                    setCanPaginate(false);
                };
            };
        }).catch(() => null).finally(() => {
            setSpinner(false);
        });
    };
    React.useEffect(() => {
        getData();
    }, [search, filters])

    React.useEffect(() => {
        if (curOnScreen.isIntersecting && canPaginate) {
            try {
                curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
            } catch { };

            curDefer(() => {
                continueData();
            }, 500);
        };
    }, [canPaginate, curOnScreen.isIntersecting]);


    return <div className="component__postback">
        <div className="component__postback__head" >
            <FilterPanel

                accent="#dfdfdf"
                theme="light"
                filterCB={fi => setFilters(fi)}
                filters={[
                    { name: "ID", friendlyName: "ID", type: "string" },
                    { name: "PostbackName", friendlyName: "Postback name", type: "string" },
                    { name: "PostbackURL", friendlyName: "Postback URL", type: "string" },
                    { name: "createdAt", friendlyName: "Date created", type: "date" },
                    { name: "updatedAt", friendlyName: "Date updated", type: "date" }
                ]} />
            <FilterBySearch onChange={e => setSearch(e)} />
            <button onClick={() => { animateBox({ currentTarget: document.querySelector('.component__postback__content') }, <SelectedPostback item={null} update={getData} />) }}>Add postback</button>
        </div>
        <div className={help ? `component__postback__help component__postback__help-active` : "component__postback__help"}>
            <button onClick={() => { setHelp(!help) }}>Show help</button>
            <div className="component__postback__help__container">
                <p>Postbacks will notify your server when a lead is added or changed</p>
                <p>Parameters are created by using <span> { } </span> characters</p>
                <p>Example:</p>
                <p>https://your-postback-domain.com/postbackLocation?status=<span>{`{lead_status}`}</span>&leadID=<span>{`{lead_id}`}</span></p>
                <p>List of possible parameters:</p>
                <div className="component__postback__help__container__table">
                    <div>
                        <p>Code</p>
                        <p>Description</p>
                    </div>
                    <div>
                        <p>{`{lead_id}`}</p>
                        <p>ID of the lead</p>
                    </div>
                    <div>
                        <p>{`{offer_id}`}</p>
                        <p>ID of the offer</p>
                    </div>
                    <div>
                        <p>{`{lead_status}`}</p>
                        <p>Lead status</p>
                    </div>
                    <div>
                        <p>{`{lead_type}`}</p>
                        <p>Type of the lead</p>
                    </div>
                    <div>
                        <p>{`{offer_domain}`}</p>
                        <p>Name of the offer</p>
                    </div>
                    <div>
                        <p>{`{lead_price}`}</p>
                        <p>	Price of the lead (in USD)</p>
                    </div>
                    <div>
                        <p>{`{lead_uid}`}</p>
                        <p>	(Optional) ID provided by other networks</p>
                    </div>
                    <div>
                        <p>{`{sub1}`}</p>
                        <p>	(Optional) Field for custom data provided by other networks</p>
                    </div>
                    <div>
                        <p>{`{sub2}`}</p>
                        <p>	(Optional) Field for custom data provided by other networks</p>
                    </div>
                    <div>
                        <p>{`{sub3}`}</p>
                        <p>	(Optional) Field for custom data provided by other networks</p>
                    </div>
                    <div>
                        <p>{`{sub4}`}</p>
                        <p>	(Optional) Field for custom data provided by other networks</p>
                    </div>
                    <div>
                        <p>{`{sub5}`}</p>
                        <p>	(Optional) Field for custom data provided by other networks</p>
                    </div>
                    <div>
                        <p>{`{sub6}`}</p>
                        <p>	(Optional) Field for custom data provided by other networks</p>
                    </div>
                    <div>
                        <p>{`{sub7}`}</p>
                        <p>	(Optional) Field for custom data provided by other networks</p>
                    </div>

                </div>
              

            </div>
        </div>
        <div className="component__postback__content" style={{ gridTemplateColumns: "1fr" }} >
            <div className="component__postback__content__left">
                <FilteredCustomTable
                    theme="light"
                    accent="#dfdfdf"
                    headers={["Postback name", "Postback trigger", "Postback Type", "Postback URL", ""]}
                    customColumns={["360px", "1fr", "1fr", "1fr", "200px"]}
                    style={{ width: "100%" }}
                    data={(() => {
                        let out = [];

                        if (!data) return [[{ keyID: "noData-spinner", type: "spinner", color: "white" }]];
                        if (data.status === "ok") {
                            for (let item of data.data) {
                                out.push({
                                    columns: [
                                        { keyID: item.ID, type: "text", text: item.PostbackName },
                                        { keyID: item.ID, type: "text", text: item.PostbackTrigger },
                                        { keyID: item.ID, type: "text", text: item.PostbackType },
                                        { keyID: item.ID, type: "text", text: item.PostbackURL },
                                        {
                                            keyID: item.ID, type: "custom", data: <div className="component__currencies__buttons">
                                                 <p className="component__currencies__edit" onClick={(e) => {
                                                    e.stopPropagation();
                                                    animateBox({ currentTarget: document.querySelector('.component__postback__content') }, <SelectedPostback item={item} update={getData} edit={true} />)
                                                }}>Edit</p>
                                                 && <p className="component__currencies__delete" onClick={(e) => {
                                                    e.stopPropagation();
                                                    animateBox({ currentTarget: document.querySelector('.component__postback__content') }, <DeletePostback item={item} id={item.ID} update={getData} />)
                                                }}>Delete</p>
                                            </div>
                                        }
                                    ]
                                })
                            };
                        } else {
                            out.push([{ keyID: "noData-error", type: "text", text: "Error while fetching orders!", color: "#f96666" }]);
                        };

                        if (spinner) out.push([{ keyID: "pagination-spinner", type: "spinner", color: "white" }]);
                        if (out.length === 0) out.push([{ keyID: "noData-noData", type: "text", text: "Nothing to show for now." }]);

                        return out;
                    })()}
                />
            </div>
            {canPaginate && <div ref={curOnScreen.measureRef} className="modals__ordersModal__wrap__content__left__paginate"></div>}

        </div>

    </div>
};


const SelectedPostback = (props) => {
    const [item, setItem] = React.useState(props.item);
    const [spinner, setSpinner] = React.useState(false);
    const nameRef = React.useRef();
    const urlRef = React.useRef();
    const [postbackType, setPostbackType] = React.useState();
    const [postbackOffer, setPostbackOffer] = React.useState();
    const [postbackTrigger, setPostbackTrigger] = React.useState();
    const [error, setError] = React.useState();

    const [takenOffers, setTakenOffers] = React.useState();


    const postbackStates = [
        "Any",
        "AnyPublisher",
        "AnyAdvertiser",

        "OnCreate",
        "OnCreatePublisher",
        "OnCreateAdvertiser",

        "OnApproved",
        "OnApprovedPublisher",
        "OnApprovedAdvertiser",

        "OnDeclined",
        "OnDeclinedPublisher",
        "OnDeclinedAdvertiser"
    ];
    const postbackTypes = ["global", "offer"];

    React.useEffect(() => {
        setItem(props.item);
    }, [props.item])

    const saveData = () => {
        let path = '/addPostback'
        if (props.edit) path = '/editPostback'
        setSpinner(true)
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/postback${path}`,
            data: {
                ID: props.item?.ID,
                PostbackName: nameRef.current.value,
                PostbackTrigger: postbackTrigger,
                PostbackType: postbackType,
                PostbackOffer: postbackType === 'offer' ? postbackOffer : null,
                PostbackURL: urlRef.current.value
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.update();
                props.onClose();
            } else {
                setError('Please check all your info and try again');
            }
        }).catch(() => {
            setError('Something went wrong please try again later')
        }).finally(() => { setSpinner(false) })
    };

    const getTakenOffers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/takenoffers/getAllTakenOffers`,
            data: {
                limit: 1000
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setTakenOffers(res.data);
        }).catch(() => {
            setTakenOffers(backendModule.axiosConfig);
        });
    }
    React.useEffect(() => {
        getTakenOffers()
    }, [])

    return <div className="modals__postbackModal">
        <div className="modals__postbackModal__container">
            <div className="modals__postbackModal__close">
                <img src="/images/closeIcon.png" onClick={() => { props.onClose() }} />
            </div>
            <p className="modals__postbackModal__head">{props.edit ? "Edit Postback" : "Add new Postback"}</p>
            <p className="modals__postbackModal__label" >Postback Name</p>
            <input defaultValue={item?.PostbackName} ref={nameRef} type='text' placeholder="Enter your postback name here" />
            <p className="modals__postbackModal__label" >Postback URL</p>
            <input defaultValue={item?.PostbackURL} ref={urlRef} type='text' placeholder="Enter your postback URL here" />
            <p className="modals__postbackModal__label" >Postback Type</p>
            <Dropdown theme="light"
                accent="#6C5DD3" selected={(() => {
                    switch (item?.PostbackType) {
                        case "global": return 0;
                        case "offer": return 1;
                        default: return 0;
                    };
                })()} data={postbackTypes.map(item => {
                    return { value: item, name: item }
                })} onChange={val => {
                    setPostbackType(val.value)
                }} />
            {postbackType === 'offer' && <>
                <p className="modals__postbackModal__label" >Postback Offer</p>
                <Dropdown theme="light" selected={(() => {
                    if (takenOffers?.status !== 'ok') return 0;
                    let tmp = takenOffers.data.map((o, index) => { if (o.ID === item?.PostbackOffer) return index; })
                    return tmp;
                })()}
                    accent="#6C5DD3" data={takenOffers?.status === 'ok' && takenOffers?.data.map(item => {
                        return { value: item.ID, name: `${item.TakenOfferName} ${item.FriendlyName} ` }
                    })} onChange={val => {
                        setPostbackOffer(val.value)
                    }} />
            </>}
            <p className="modals__postbackModal__label" >Postback Trigger</p>
            <Dropdown theme="light" selected={(() => {
                switch (item?.PostbackTrigger) {
                    case "Any": return 0;
                    case "AnyPublisher": return 1;
                    case "AnyAdvertiser": return 2;

                    case "OnCreate": return 3;
                    case "OnCreatePublisher": return 4;
                    case "OnCreateAdvertiser": return 5;

                    case "OnApproved": return 6;
                    case "OnApprovedPublisher": return 7;
                    case "OnApprovedAdvertiser": return 8;

                    case "OnDeclined": return 9;
                    case "OnDeclinedPublisher": return 10;
                    case "OnDeclinedAdvertiser": return 11;
                    default: return 0;
                };
            })()}
                accent="#6C5DD3" data={postbackStates.map(item => {
                    return { value: item, name: item }
                })} onChange={val => {
                    setPostbackTrigger(val.value)
                }} />
            <p className="modals__postbackModal__error">{error}</p>
            <div className="modals__postbackModal__buttons">
                {spinner ? <Spinner color={"##00A3FF"} /> : <button onClick={() => { saveData() }}>Save</button>}
            </div>
        </div>


    </div>
}


const DeletePostback = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [error, setError] = React.useState();
    const deleteData = () => {
        setSpinner(true)
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/postback/removePostback`,
            data: {
                ID: props.id
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.update();
                props.onClose();
            } else {
                setError('Please check all your info and try again');
            }
        }).catch(() => {
            setError('Something went wrong please try again later')
        }).finally(() => { setSpinner(false) })
    };
    return <div className="modals__deletePostback">
        <div className="modals__deletePostback__container">
            <p>You are about to delete <span style={{ color: "#6c5dd3" }}>{props.item?.Postback}</span></p>
            <p>Postback deletion is permanent.</p>
            <p>Are you sure you want to continue?</p>
            <p className="modals__postbackModal__error">{error}</p>
            <div className="modals__deletePostback__container__buttons">
                {spinner ? <Spinner color="white" style={{ width: "43px", height: "43px" }} /> : <>
                    <button onClick={() => [deleteData()]}>Yes</button>
                    <button onClick={() => { props.onClose() }}>No</button>
                </>}
            </div>
        </div>
    </div>
}

export default Postback;