import React from "react";
import "./index.scss";

const LandingPage = (props) => {

    const [activeTab, setActiveTab] = React.useState();

    return <div className="route__landingPage">
        <div className="route__landingPage__block1" style={{ backgroundImage: `url('/images/group_1_bg.png')` }}>
            <div className="route__landingPage__block1__wrapper">
                <p className="route__landingPage__block1__wrapper__prupleheadline">Affiliate Dating: </p>
                <p className="route__landingPage__block1__wrapper__whiteheadline">Performance Partnerships </p>
                <p className="route__landingPage__block1__wrapper__subheadline">With our collaboration, acceleration, and vision, your ambitions become achievements.</p>
                <div className="route__landingPage__block1__wrapper__btns">
                    <div className="route__landingPage__block1__wrapper__btns__item">
                        <p className="route__landingPage__block1__wrapper__btns__item__first">BECOME AN </p>
                        <p className="route__landingPage__block1__wrapper__btns__item__second">ADVERTISER</p>
                    </div>
                    <div className="route__landingPage__block1__wrapper__btns__second">
                        <p className="route__landingPage__block1__wrapper__btns__item__first">BECOME AN </p>
                        <p className="route__landingPage__block1__wrapper__btns__item__second">AFFILIATE</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="route__landingPage__block2" style={{ backgroundImage: `url('/images/group_2_bg.png')` }}>
            <div className="route__landingPage__block2__head">
                <p className="route__landingPage__block2__head__purple">Affiliate Dating </p>
                <p className="route__landingPage__block2__head__white">fosters network success</p>
            </div>
            <div className="route__landingPage__block2__body">
                <Block2Item 
                head={'Our mission'}
                image={'our_mission.png'}
                text={'At Affiliate Dating Marketing, our mission is to facilitate connections and relationships on a global scale. We are committed to assisting individuals in discovering companionship and satisfaction through our affiliate marketing programs.'}
                />
                <Block2Item 
                head={'Our vision'}
                image={'our_vision.png'}
                text={'We aspire to empower affiliate marketers and partners, catalysing change in the dating industry. Our goal is to create a mutually beneficial ecosystem where affiliates profit from generous commissions while aiding singles in finding their ideal matches.'}
                />
                <Block2Item 
                head={'Why choose us'}
                image={'why_choose_us.png'}
                text={'With years of experience in the dating and affiliate marketing space, we are industry leaders who understand the nuances of both worlds. You can trust us to provide you with the tools, resources, and guidance needed to succeed in this dynamic field.'}
                />
                <Block2Item 
                head={'What sets us apart'}
                image={'what_sets_us.png'}
                text={'We utilize advanced technology and data analytics to help affiliates maximize earnings. Our tracking systems, real-time reporting, and personalized support make us the top choice for your affiliate journey.'}
                />
            </div>
        </div>
        <div className="route__landingPage__block3">
            <div className="route__landingPage__block3__wrapper">
                <div className="route__landingPage__block3__wrapper__inner">
                    <p className="route__landingPage__block3__wrapper__inner__headline">What are the reasons</p>
                    <p className="route__landingPage__block3__wrapper__inner__subheadline">for becoming an affiliate with CutidCost?</p>
                    <div className="route__landingPage__block3__wrapper__inner__body">
                        <div className="route__landingPage__block3__wrapper__inner__body__left">
                            <p>We stand by your side throughout your journey, supporting you in achieving and surpassing all your business objectives. Our primary aim is growth, so let's embark on this growth journey together. </p>
                            <p>With nearly a decade of experience, we've cultivated a suite of distinctive and tailor-made tools that propel your outreach efforts and assist you in attaining your targets. Our technological solutions are unmatched in the market, enabling our clients to secure victories.</p>
                        </div>
                        <div className="route__landingPage__block3__wrapper__inner__body__right">
                            <p>Our established reputation gives us access to top-notch, high-converting offers from numerous global advertisers. We link you to premium-quality traffic sources, ensuring you reach the most valuable worldwide users.</p>
                            <p> Having worked in the affiliate marketing industry for nearly ten years, we have become leaders in the most successful verticals.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="route__landingPage__block4">
            <div className="route__landingPage__block4__wrapper">
                <div className="route__landingPage__block4__wrapper__left">
                    <p className="route__landingPage__block4__wrapper__left__headline">Choose the right</p>
                    <p className="route__landingPage__block4__wrapper__left__subheadline">affiliate program to join us</p>
                    <p className="route__landingPage__block4__wrapper__left__text">Yes, some affiliate programs may have age restrictions, and you should always comply with local laws and regulations regarding online marketing and dating.</p>
                    <div className="route__landingPage__block4__wrapper__left__btns">
                        <div className="route__landingPage__block4__wrapper__left__btns__item">
                            <p className="route__landingPage__block4__wrapper__left__btns__item__first">BECOME AN</p>
                            <p className="route__landingPage__block4__wrapper__left__btns__item__second">ADVERTISER</p>
                        </div>
                        <div className="route__landingPage__block4__wrapper__left__btns__item">
                            <p className="route__landingPage__block4__wrapper__left__btns__item__first">BECOME AN</p>
                            <p className="route__landingPage__block4__wrapper__left__btns__item__second">AFFILIATE</p>
                        </div>

                    </div>
                </div>
                <div className="route__landingPage__block4__wrapper__right">
                    <div className="route__landingPage__block4__wrapper__right__box">
                        <div className="route__landingPage__block4__wrapper__right__box__left">
                            <Block4Item headline={'Unique platform'} 
                            text={"You can simultaneously utilize both CPA offers and smart links, there's no need to make a choice between them!"} 
                            color={'#9552EC'}/>
                            <Block4Item headline={'Profit'} 
                            text={"Get additional revenue with our rewards and bonuses after monetizing 100% of your traffic"} 
                            color={'#FFDB32'}/>
                        </div>
                        <div className="route__landingPage__block4__wrapper__right__box__right">
                            <Block4Item headline={'The best offers'} 
                            text={"We have the best selection of the most converting offers"} 
                            color={'#5BFF32'}/>
                            <Block4Item headline={'Payment'} 
                            text={"We have flexible payment terms and also a wide selection of payment methods"} 
                            color={'#B535F6'}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="route__landingPage__block5">
            <div className="route__landingPage__block5__wrapper">
                <p className="route__landingPage__block5__wrapper__headline">FAQ</p>
                <div className="route__landingPage__block5__wrapper__questions">
                    <div className="route__landingPage__block5__wrapper__questions__section">

                        <div className="route__landingPage__block5__wrapper__questions__section__item"
                        onClick={() => {setActiveTab(0)}} style={{maxHeight : activeTab === 0 ? '1500px' : '44px'}}>
                            <div className="route__landingPage__block5__wrapper__questions__section__item__upper">
                                <p>How long will it take for my application to be approved?</p>
                                <div className="route__landingPage__block5__wrapper__questions__section__item__upper__img">
                                    <img style={{opacity : activeTab != 0 ? '1' : '0'}} src="./images/cupid_faq_plus.svg" />
                                    <img style={{opacity : activeTab === 0 ? '1' : '0'}} src="./images/cupid_faq_minus.svg" />
                                </div>
                            </div>
                            <p className="route__landingPage__block5__wrapper__questions__section__item__hidden">We check our new signups daily. You should receive a response within 24 hours.</p>
                        </div>

                        <div className="route__landingPage__block5__wrapper__questions__section__item"
                        onClick={() => {setActiveTab(1)}} style={{maxHeight : activeTab === 1 ? '1500px' : '44px'}}>
                            <div className="route__landingPage__block5__wrapper__questions__section__item__upper">
                                <p>Which payments options do we have?</p>
                                <div className="route__landingPage__block5__wrapper__questions__section__item__upper__img">
                                    <img style={{opacity : activeTab != 1 ? '1' : '0'}} src="./images/cupid_faq_plus.svg" />
                                    <img style={{opacity : activeTab === 1 ? '1' : '0'}} src="./images/cupid_faq_minus.svg" />
                                </div>
                            </div>
                            <p className="route__landingPage__block5__wrapper__questions__section__item__hidden">Wire, Cryptocurrency</p>
                        </div>

                        <div className="route__landingPage__block5__wrapper__questions__section__item"
                        onClick={() => {setActiveTab(3)}} style={{maxHeight : activeTab === 3 ? '1500px' : '44px'}}>
                            <div className="route__landingPage__block5__wrapper__questions__section__item__upper">
                                <p>What actions are not allowed?</p>
                                <div className="route__landingPage__block5__wrapper__questions__section__item__upper__img">
                                    <img style={{opacity : activeTab != 3 ? '1' : '0'}} src="./images/cupid_faq_plus.svg" />
                                    <img style={{opacity : activeTab === 3 ? '1' : '0'}} src="./images/cupid_faq_minus.svg" />
                                </div>
                            </div>
                            <p className="route__landingPage__block5__wrapper__questions__section__item__hidden">Break the rules and conditions regarding the work with an offer: spam, usage of creatives with false information, bringing incent or motivated traffic, sending fraud traffic or "real lead" simulation etc.</p>
                            <p className="route__landingPage__block5__wrapper__questions__section__item__hidden">Refer yourself or a family member.</p>
                            <p className="route__landingPage__block5__wrapper__questions__section__item__hidden">Create two or more accounts without the permission of the affiliate manager. An account may have multiple users, but an affiliate can't have multiple  accounts. If an affiliate is found to have multiple accounts, these accounts can be closed and the earnings forfeited.</p>
                        </div>

                    </div>

                    <div className="route__landingPage__block5__wrapper__questions__section">

                        <div className="route__landingPage__block5__wrapper__questions__section__item"
                        onClick={() => {setActiveTab(4)}} style={{maxHeight : activeTab === 4 ? '1500px' : '44px'}}>
                            <div className="route__landingPage__block5__wrapper__questions__section__item__upper">
                                <p>What type of traffic does the network acccept or forbid?</p>
                                <div className="route__landingPage__block5__wrapper__questions__section__item__upper__img">
                                    <img style={{opacity : activeTab != 4 ? '1' : '0'}} src="./images/cupid_faq_plus.svg" />
                                    <img style={{opacity : activeTab === 4 ? '1' : '0'}} src="./images/cupid_faq_minus.svg" />
                                </div>
                            </div>
                            <p className="route__landingPage__block5__wrapper__questions__section__item__hidden">CupidAffiliates accepts almost all traffic types: Social networks, Native traffic, Email, Facebook, SEO, Google Ads, Yandex, Push, Pop, Banner, Teaser, Tubes, PWA, Member area, and others. Please pay attention to the offer description as each offer has its own permitted and forbidden sources.</p>
                            <p className="route__landingPage__block5__wrapper__questions__section__item__hidden">It is forbidden to promote any CupidAffiliates offers through spam, content locking, incent, and other deceitful tactics. This includes, but is not limited to: </p>
                            <p className="route__landingPage__block5__wrapper__questions__section__item__hidden">Spam (via email, forums, comments, and instant messenger)</p>
                            <p className="route__landingPage__block5__wrapper__questions__section__item__hidden">Incent or motivated traffic, and traffic created by any misleading or deceptive practice, method, or technology (including through any spyware, adware, device, program, robot, redirects, spiders, computer script, or other automated, artificial, or fraudulent methods designed to appear like an individual, real live person performing a Conversion).</p>
                        </div>

                        <div className="route__landingPage__block5__wrapper__questions__section__item"
                        onClick={() => {setActiveTab(5)}} style={{maxHeight : activeTab === 5 ? '1500px' : '44px'}}>
                            <div className="route__landingPage__block5__wrapper__questions__section__item__upper">
                                <p>How can i be updated on the new offers, payouts etc?.</p>
                                <div className="route__landingPage__block5__wrapper__questions__section__item__upper__img">
                                    <img style={{opacity : activeTab != 5 ? '1' : '0'}} src="./images/cupid_faq_plus.svg" />
                                    <img style={{opacity : activeTab === 5 ? '1' : '0'}} src="./images/cupid_faq_minus.svg" />
                                </div>
                            </div>
                            <p className="route__landingPage__block5__wrapper__questions__section__item__hidden">We will send you a newsletter that may include company news, payout changes, new offers, or best working offers at the moment. So stay in touch and stay subscribed.</p>
                        </div>

                        <div className="route__landingPage__block5__wrapper__questions__section__item"
                        onClick={() => {setActiveTab(6)}} style={{maxHeight : activeTab === 6 ? '1500px' : '44px'}}>
                            <div className="route__landingPage__block5__wrapper__questions__section__item__upper">
                                <p>In what cases can my payment be put on hold?</p>
                                <div className="route__landingPage__block5__wrapper__questions__section__item__upper__img">
                                    <img style={{opacity : activeTab != 6 ? '1' : '0'}} src="./images/cupid_faq_plus.svg" />
                                    <img style={{opacity : activeTab === 6 ? '1' : '0'}} src="./images/cupid_faq_minus.svg" />
                                </div>
                            </div>
                            <p className="route__landingPage__block5__wrapper__questions__section__item__hidden">So you've reached your minimum payout, and you’ve been waiting for your check to arrive, but still nothing?</p>
                            <p className="route__landingPage__block5__wrapper__questions__section__item__hidden">This can happen in the following cases:</p>
                            <p className="route__landingPage__block5__wrapper__questions__section__item__hidden">You don't have enough leads for traffic quality analysis. However, we reserve the right to evaluate all conversions to confirm valid.</p>
                            <p className="route__landingPage__block5__wrapper__questions__section__item__hidden">You don't have payment details in your account. Please make sure you provide your payment details to your affiliate manager.</p>
                            <p className="route__landingPage__block5__wrapper__questions__section__item__hidden">Your traffic has been investigated for possible fraudulent patterns by a network or an advertiser. This means that we are undertaking a deep analysis of your leads that can take up to 7 days. But usually, it takes no more than a couple of days, and your manager will keep you updated on the process.</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div className="route__landingPage__block6">
            <div className="route__landingPage__block6__wrapper" style={{ backgroundImage: `url('/images/group_3_bg.png')` }}>
                <div className="route__landingPage__block6__wrapper__inner">

                    <div className="route__landingPage__block6__wrapper__inner__head">
                        <div className="route__landingPage__block6__wrapper__inner__head__left">
                            <p className="route__landingPage__block6__wrapper__inner__head__left__headline">Our partners</p>
                            <p className="route__landingPage__block6__wrapper__inner__head__left__subheadline">& products</p>
                        </div>
                        <div className="route__landingPage__block6__wrapper__inner__head__right">
                            <p className="route__landingPage__block6__wrapper__inner__head__right__text">We collaborate with a diverse network of dedicated partners who share our vision for modern dating. Together, we work tirelessly to connect people, foster relationships, and provide outstanding experiences through our flagship product, CupidCost.</p>
                            <div className="route__landingPage__block6__wrapper__inner__head__right__btns">
                                <div className="route__landingPage__block6__wrapper__inner__head__right__btns__item">
                                    <p className="route__landingPage__block6__wrapper__inner__head__right__btns__item__first">BECOME AN </p>
                                    <p className="route__landingPage__block6__wrapper__inner__head__right__btns__item__second">ADVERTISER</p>
                                </div>
                                <div className="route__landingPage__block6__wrapper__inner__head__right__btns__item btn-item-affiliate">
                                    <p className="route__landingPage__block6__wrapper__inner__head__right__btns__item__first">BECOME AN </p>
                                    <p className="route__landingPage__block6__wrapper__inner__head__right__btns__item__second">AFFILIATE</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="route__landingPage__block6__wrapper__inner__body">
                        <div className="route__landingPage__block6__wrapper__inner__body__top">
                            <div className="route__landingPage__block6__wrapper__inner__body__top__item">
                                <img src="./images/partner_1.png" />
                            </div>
                            <div className="route__landingPage__block6__wrapper__inner__body__top__item">
                                <img src="./images/partner_2.png" />
                            </div>
                            <div className="route__landingPage__block6__wrapper__inner__body__top__item">
                                <img src="./images/partner_3.png" />
                            </div>
                            <div className="route__landingPage__block6__wrapper__inner__body__top__item">
                                <img src="./images/partner_4.png" />
                            </div>

                        </div>
                        <div className="route__landingPage__block6__wrapper__inner__body__bot">
                            <div className="route__landingPage__block6__wrapper__inner__body__bot__item">
                                <img src="./images/partner_5.png" />
                            </div>
                            <div className="route__landingPage__block6__wrapper__inner__body__bot__item">
                                <img src="./images/partner_6.png" />
                            </div>
                            <div className="route__landingPage__block6__wrapper__inner__body__bot__item">
                                <img src="./images/partner_7.png" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="route__landingPage__block7">
            <div className="route__landingPage__block7__wrapper">
                <p className="route__landingPage__block7__wrapper__headline">Chat us</p>
                <p className="route__landingPage__block7__wrapper__subheadline">You can connect with us through Telegram, send us an email, or chat with us on Skype. We're here to help</p>
                <div className="route__landingPage__block7__wrapper__contacts">
                    <div className="route__landingPage__block7__wrapper__contacts__item">
                        <img src="./images/mail_icon.svg"></img>
                    </div>
                    <div className="route__landingPage__block7__wrapper__contacts__item">
                        <img src="./images/telegram_icon.svg"></img>
                    </div>
                    <div className="route__landingPage__block7__wrapper__contacts__item">
                        <img src="./images/skype_icon.svg"></img>
                    </div>
                </div>
            </div>
        </div>
    </div>
};

const Block2Item = (props) => {
    return <div className="route__landingPage__block2__body__item">
    <div className="route__landingPage__block2__body__item__head">
        <p>{props.head}</p>
        <img src={`./images/${props.image}`} />
    </div>
    <div className="route__landingPage__block2__body__item__body">
        <p>{props.text}</p>
    </div>

    </div>
}

const Block4Item = (props) => {
    return <div className="route__landingPage__block4__wrapper__right__box__item">
        <p className="route__landingPage__block4__wrapper__right__box__item__headline">{props.headline}</p>
        <p className="route__landingPage__block4__wrapper__right__box__item__text">{props.text}</p>
        <div className="route__landingPage__block4__wrapper__right__box__item__line" style={{backgroundColor: props.color}}></div>
    </div>
}

export default LandingPage;