import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import animateModule from "./modules/animateModule";
import * as backendModule from "./modules/backendModule";
import * as userDataActions from "./actions/userDataActions"
import * as timestmapActions from "./actions/timeStampAction";

import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import Spinner from "./components/customComponents/Spinner";
import UserHeader from "./components/UserHeader"

import Login from "./routes/Login";
import ContentWrapper from "./components/ContentWrapper";
import VerifyModal from "./components/VerifyModal";

// import Dashboard from "./routes/Dashboard";

import LandingPage from "./routes/LandingPage";
import Footer from "./components/Footer";
import Register from "./routes/Register";

// import PrivacyPolicy from "./routes/PrivacyPolicy";
// import TermsOfService from "./routes/TermsOfService";
import Currencies from "./routes/admin/Currencies";
import Domains from "./routes/admin/Domains";
import AdminUsers from "./routes/admin/Users";
import AdminAdvertisers from "./routes/admin/Advertisers";
import AdminOffers from "./routes/admin/Offers";
import AdminLeads from "./routes/admin/Leads";
import AdminPayouts from "./routes/admin/Payouts";
import ProcessedPayments from "./routes/admin/ProccesedPayments";
import AdminDashboard from "./routes/admin/Dashboard";
import AdminWebsites from "./routes/admin/Websites";
import { animateBox } from "./modules/componentAnimation";

import PublisherOffers from "./routes/publisher/Offers";
import TakenOffers from "./routes/publisher/TakenOffers";
import Postback from "./routes/publisher/Postback";
import PublisherLeads from "./routes/publisher/Leads";
import PublisherDashboard from "./routes/publisher/Dashboard";
import PublisherTeam from "./routes/publisher/Team";

import AdvertiserLeads from "./routes/advertiser/Leads";
import AdvertiserOffers from "./routes/advertiser/Offers";
import AdvertiserDashboard from "./routes/advertiser/Dashboard";


let loginTimeout = null;
const App = () => {
  const userDataSelector = useSelector(state => state?.userData ?? {});
  const timestampSelector = useSelector(state => state?.timestamp ?? null);
  const [sidebarOpened, setSidebarOpened] = React.useState(false);
  const [notifications, setNotifications] = React.useState();


  const mainDispatch = useDispatch();
  const mainNavigate = useNavigate();

  const animateNavigate = to => {
    animateModule(mainNavigate, to, document.querySelector(".component__contentWrapper"));
  };







  const checkLogin = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/auth/checkLogin`,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        mainDispatch(userDataActions.setUserData(true, res.data.data));
        if (window.location.toString().endsWith("/login") ||  window.location.toString().endsWith("/")) animateNavigate("/publisher-dashboard");
        loginTimeout = setTimeout(checkLogin, 20000);
      } else {
        mainDispatch(userDataActions.setUserData(false, null));
      };
    }).catch(() => {
      mainDispatch(userDataActions.setUserData(null, null));
      setTimeout(() => mainDispatch(timestmapActions.updateTimestamp()), 2000);

      loginTimeout = setTimeout(checkLogin, 2000);
    });
  };

  React.useEffect(() => {
    checkLogin();

    return () => clearTimeout(loginTimeout);
  }, [timestampSelector]);





  React.useEffect(() => {
    let handler = (e) => {
      if (
        e?.oldURL.endsWith("/login") ||
        e?.newURL.endsWith("/login")
      ) setTimeout(() => mainDispatch(timestmapActions.updateTimestamp()), 0);
    };

    window.addEventListener("hashchange", handler);

    return () => window.removeEventListener("hashchange", handler);
  }, []);

  const toggleSidebar = () => {
    setSidebarOpened(d => !d);
  }

  // React.useEffect(() => {
  //   if (!userDataSelector.isLoggedIn) return;
  //   if (!userDataSelector?.userData?.UserInfo?.Flags?.isVerified) {
  //     animateBox({ currentTarget: document.querySelector("#root") }, )
  //   }
  // }, [userDataSelector])


  return (userDataSelector.isLoggedIn ? <>

    <Sidebar sidebarOpened={sidebarOpened}
      toggleSidebar={() => toggleSidebar} sidebar={sidebarOpened} setSidebar={setSidebarOpened} />

    <ContentWrapper style={{
      display: "grid",
      gridTemplateRows: "102px 1fr",
      gridTemplateColumns: "1fr",
      paddingLeft: '84px',
      backgroundColor: "white"
    }}>
      <UserHeader />
      <div className="root__content">
        <Routes>
          {/* <Route path="/" element={<Dashboard />} /> */}
          {userDataSelector?.userData?.UserInfo?.Flags?.isVerified ? <>

            <Route path="/publisher-offers" element={<PublisherOffers />} />
            <Route path="/publisher-takenoffers" element={<TakenOffers />} />
            <Route path="/publisher-postback" element={<Postback />} />
            <Route path="/publisher-leads" element={<PublisherLeads />} />
            <Route path="/publisher-dashboard" element={<PublisherDashboard />} />
            <Route path="/publisher-team" element={<PublisherTeam />} />

            {(userDataSelector?.userData?.UserInfo?.Flags?.isAdvertiser) && <>
              <Route path="/advertiser-leads" element={<AdvertiserLeads />} />
              <Route path="/advertiser-offers" element={<AdvertiserOffers />} />
              <Route path="/advertiser-dashboard" element={<AdvertiserDashboard />} />
            </>}


            {userDataSelector?.userData?.UserInfo?.Flags?.isAdmin && <>
              <Route path="/admin-offers" element={<AdminOffers />} />
            </>}

            {(userDataSelector?.userData?.UserInfo?.Flags?.isAdmin || userDataSelector?.userData?.UserInfo?.Flags?.isManager) && <>
              <Route path="/admin-currencies" element={<Currencies />} />
              <Route path="/admin-domains" element={<Domains />} />
              <Route path="/admin-users" element={<AdminUsers />} />
              <Route path="/admin-advertisers" element={<AdminAdvertisers />} />
              <Route path="/admin-leads" element={<AdminLeads />} />
              <Route path="/admin-payments" element={<AdminPayouts />} />
              <Route path="/admin-processedpayments" element={<ProcessedPayments />} />
              <Route path="/admin-dashboard" element={<AdminDashboard />} />
              <Route path="/admin-websites" element={<AdminWebsites />} />
            </>}
          </> : <>
            <Route path="*" element={<VerifyModal navigate={animateNavigate} />} />
          </>}
        </Routes>
      </div>
    </ContentWrapper>
  </> : <>
    <ContentWrapper style={{ gridRow: "1 / span 2", gridColumn: "1 / span 2" }}>
      <Header />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />

        {/* <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsOfService />} /> */}
        <Route path="/" element={<LandingPage />} />
        <Route
          path="*"
          element={<LandingPage />}
        />
      </Routes>
      <Footer />
    </ContentWrapper>
  </>)
};

export default App;
