import React from "react";
import "./index.scss";

import { useSelector } from "react-redux";
import axios from "axios";
import * as backendModule from "../../../modules/backendModule";
import { animateBox } from "../../../modules/componentAnimation";
import Spinner from "../../../components/customComponents/Spinner";
import FilterPanel from "../../../components/customComponents/FilterPanel";
import { FilteredCustomTable } from "../../../components/customComponents/Table";
import Dropdown from "../../../components/customComponents/Dropdown";
import useDefer from "../../../modules/hooks/useDefer";
import useOnScreen from "../../../modules/hooks/useOnScreen";
import FilterBySearch from "../../../filters/FilterBySearch";
import animateModule from "../../../modules/animateModule";
import RotatingOffers from "./RotatingLanding";
import TrapPage from "./TrapPage";
import HomePage from "./HomePage";
import LadyLanding from "./LadyLanding";
import { useNavigate } from "react-router-dom";

const PublisherOffers = () => {
    const [data, setData] = React.useState();
    const [filters, setFilters] = React.useState([]);
    const [marketingType, setMarketingType] = React.useState("rotating");

    const curNavigate = useNavigate();
    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".root__content"));

    const curUserSelector = useSelector(state => state?.userData?.userData?.UserInfo);

    const getData = () => {
        const filtersTmp = [...filters];


        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOffersFrontend`,
            data: {
                filters: filtersTmp,
                limit: 1000
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.axiosConfig);
        });
    };
    React.useEffect(() => {
        getData();
    }, [])



    return <div className="component__puboffers">
        <div className="component__puboffers__buttons">
            <p onClick={() => {
                setMarketingType("rotating");
            }} className={`component__puboffers__buttons__single ${marketingType === 'rotating' && "component__puboffers__buttons__single--active"}`}>Rotating landings</p>
            <p onClick={() => {
                setMarketingType("homePage");
            }} className={`component__puboffers__buttons__single ${marketingType === 'homePage' && "component__puboffers__buttons__single--active"}`}>Home page </p>
            <p onClick={() => {
                setMarketingType("lady");
            }} className={`component__puboffers__buttons__single ${marketingType === 'lady' && "component__puboffers__buttons__single--active"}`}>Deep linking</p>
            <p onClick={() => {
                setMarketingType("landing");
            }} className={`component__puboffers__buttons__single ${marketingType === 'landing' && "component__puboffers__buttons__single--active"}`}>Landing page</p>

        </div>

        {(() => {
            switch (marketingType) {
                case "rotating":
                    return <RotatingOffers allOffers={data} />;
                case "landing":
                    return <TrapPage allOffers={data} />;
                case "homePage":
                    return <HomePage allOffers={data} />;
                case "lady":
                    return <LadyLanding allOffers={data} />;
                default:
                    return null;
                    break;
            }
        })()}

        {/* <div className="component__puboffers__head" >
            <FilterPanel

                accent="#dfdfdf"
                theme="light"
                filterCB={fi => setFilters(fi)}
                filters={[
                    { name: "ID", friendlyName: "ID", type: "string" },
                    { name: "AdvertiserID", friendlyName: "AdvertiserID", type: "string" },
                    { name: "Name", friendlyName: "Offer name", type: "string" },
                    { name: "Limit", friendlyName: "Offer limit", type: "string" },
                    { name: "createdAt", friendlyName: "Date created", type: "date" },
                    { name: "updatedAt", friendlyName: "Date updated", type: "date" }
                ]} />
            <FilterBySearch onChange={e => setSearch(e)} />
            <button onClick={() => { animateNavigate('/publisher-takenoffers') }}>Taken offers</button>
        </div>
        <div className="component__puboffers__content" style={{ gridTemplateColumns: "1fr" }} >
            <div className="component__puboffers__content__left">
                {data?.data ? data.data.map((item, index) => {
                    return <SingleOffer data={item} navigate={animateNavigate} />
                }) : <p>"Nothing to show for now.</p>}
                {spinner && <Spinner align={"center"} />}
                {canPaginate && <div ref={curOnScreen.measureRef} className="modals__ordersModal__wrap__content__left__paginate"></div>}
            </div>



        </div> */}

    </div>
};


const SingleOffer = (props) => {
    let offerStatuses = {
        1: "Approval needed",
        99: "Offer blocked",
        100: "Active"
    }
    return <div className="component__puboffers__single" onClick={(e) => { animateBox(e, <OfferMoreInfo navigate={props.navigate} data={props.data} statuses={offerStatuses} />) }} >
        <div className="component__puboffers__single__top">
            <img src={props?.data?.Images[0]} />
            <p>{props.data.Name}</p>
            <small>{props?.data?.Type}</small>
        </div>
        <div className="component__puboffers__single__bottom">
            <div>
                <p>Limit</p>
                <p>{props?.data?.Limit}</p>
            </div>
            <div>
                <p>Status</p>
                <p>{offerStatuses[props?.data?.Status]}</p>
            </div>
            <div>
                <p>Unlocked</p>
                <p>{props?.data?.Unlocked ? <span className="component__puboffers__single__bottom__yes">Yes</span> : <span className="component__puboffers__single__bottom__no">No</span>}</p>
            </div>
            <div>
                <p>Active</p>
                <p>{props?.data?.isActive ? <span className="component__puboffers__single__bottom__yes">Yes</span> : <span className="component__puboffers__single__bottom__no">No</span>}</p>

            </div>
            <div>
                <p>Inhouse</p>
                <p>{props?.data?.isInHouse ? <span className="component__puboffers__single__bottom__yes">Yes</span> : <span className="component__puboffers__single__bottom__no">No</span>}</p>
            </div>
        </div>
        <div className="component__puboffers__single__payouts">
            <p>Payouts:  </p>
            {props?.data?.Payouts.map((item, index) => {
                if (index < 5)
                    return <div><img src={`/images/flags/${item.Country.toLowerCase()}.png`} /> <p>{item.Payout.toFixed(2)} {props?.data?.Type === "RevShare" ? "%" : props?.data?.Currency}</p></div>
            })}

        </div>
    </div>
}


const OfferMoreInfo = (props) => {
    const [landings, setLandings] = React.useState();
    const [domains, setDomains] = React.useState();
    const [selectedDomain, setSelectedDomain] = React.useState();
    const [selectedPL, setSelectedPL] = React.useState();
    const [selectedL, setSelectedL] = React.useState();
    const friendlyNameRef = React.useRef();


    const getSites = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/websites/getAllWebsitesFrontend`,
            data: {
                OfferID: props?.data?.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setLandings(res.data)
        }).catch(e => {

        })
    }
    const getDomains = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/domains/getAllDomains`,
            ...backendModule.axiosConfig
        }).then(res => {
            setDomains(res.data)
        }).catch(e => {

        })
    }
    React.useEffect(() => {
        if (props?.data?.Status !== 100) return;
        getSites();
        getDomains();
    }, [props?.data?.Status])

    const takeOffer = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/takenoffers/addTakenOffer`,
            data: {
                OfferID: props?.data?.ID,
                DomainID: selectedDomain,
                LandingWebsiteID: selectedL.ID,
                PreLandingWebsiteID: selectedPL.ID,
                FriendlyName: friendlyNameRef.current.value
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                props.navigate('/publisher-takenoffers');
                props.onClose();
            }
        })
    }
    return <div className="modals__moreInfoOffer">
        <div className="modals__moreInfoOffer__container">
            <div className="modals__moreInfoOffer__container__close">
                <img src="/images/closeIcon.png" onClick={() => { props.onClose() }} />
            </div>
            <div className="modals__moreInfoOffer__container__content">
                <div className="modals__moreInfoOffer__container__content__image">
                    <div className="modals__moreInfoOffer__container__content__image__imageContainer">
                        <div className="modals__moreInfoOffer__container__content__image__imageContainer__hover">
                            <p onClick={(e) => { animateBox(e, <Galery images={props?.data?.Images} />) }}>Open galery</p>
                        </div>
                        <img src={props?.data?.Images[0]} />
                    </div>
                    <p>{props.data.Name}</p>
                </div>
                <div className="modals__moreInfoOffer__container__content__info">
                    <div className="modals__moreInfoOffer__container__content__info__data">
                        <p>Limit</p>
                        <p>{props?.data?.Limit}</p>
                    </div>
                    <div className="modals__moreInfoOffer__container__content__info__line"></div>
                    <div className="modals__moreInfoOffer__container__content__info__data">
                        <p>Status</p>
                        <p>{props.statuses[props?.data?.Status]}</p>
                    </div>
                    <div className="modals__moreInfoOffer__container__content__info__line"></div>
                    <div className="modals__moreInfoOffer__container__content__info__data">
                        <p>Unlocked</p>
                        <p>{props?.data?.Unlocked ? <span className="component__puboffers__single__bottom__yes">Yes</span> : <span className="component__puboffers__single__bottom__no">No</span>}</p>
                    </div>
                    <div className="modals__moreInfoOffer__container__content__info__line"></div>
                    <div className="modals__moreInfoOffer__container__content__info__data">
                        <p>Active</p>
                        <p>{props?.data?.isActive ? <span className="component__puboffers__single__bottom__yes">Yes</span> : <span className="component__puboffers__single__bottom__no">No</span>}</p>
                    </div>
                    <div className="modals__moreInfoOffer__container__content__info__line"></div>
                    <div className="modals__moreInfoOffer__container__content__info__data">
                        <p>Inhouse</p>
                        <p>{props?.data?.isInHouse ? <span className="component__puboffers__single__bottom__yes">Yes</span> : <span className="component__puboffers__single__bottom__no">No</span>}</p>
                    </div>
                    <div className="modals__moreInfoOffer__container__content__info__data" style={{ gridColumn: 'span 9' }}>
                        <p >Payouts</p>

                    </div>
                    {props?.data?.Payouts.map((item, index) => {
                        if (index % 5 === 4) {
                            return <>
                                <div className="modals__moreInfoOffer__container__content__info__data">
                                    <img src={`/images/flags/${item.Country.toLowerCase()}.png`} />
                                    <p>{item.Payout.toFixed(2)} {props?.data?.Type === "RevShare" ? "%" : props?.data?.Currency}</p>
                                </div>
                            </>
                        } else {
                            return <>
                                <div className="modals__moreInfoOffer__container__content__info__data">
                                    <img src={`/images/flags/${item.Country.toLowerCase()}.png`} />
                                    <p>{item.Payout.toFixed(2)} {props?.data?.Type === "RevShare" ? "%" : props?.data?.Currency}</p>
                                </div>
                                <div className="modals__moreInfoOffer__container__content__info__line"></div>
                            </>
                        }
                    })}

                </div>

            </div>
            <div className="modals__moreInfoOffer__container__description">
                <p>{props?.data?.Description}</p>
                {/* <div className="modals__moreInfoOffer__container__images">
                    {props?.data?.Images.map(item => {
                        return <img src={item} />
                    })}
                </div> */}
            </div>

            {props?.data?.Status === 100 && <div className="modals__moreInfoOffer__container__take">
                <div className="modals__moreInfoOffer__container__take__single">
                    <p>Select domain:</p>
                    {domains?.data.map(item => {
                        return <p style={{ backgroundColor: selectedDomain === item.ID ? "#373a43" : "" }} onClick={() => { setSelectedDomain(item.ID) }}>{item.Domain}</p>
                    })}

                </div>
                {selectedDomain && <div className="modals__moreInfoOffer__container__take__single">
                    <p>Select prelanding:</p>
                    <p style={{ backgroundColor: selectedPL === -1 ? "#373a43" : "" }} onClick={() => { setSelectedPL(-1) }}>None</p>
                    {landings?.data.map(item => {
                        if (item.WebsiteType !== "Landing") {
                            return <p style={{ backgroundColor: selectedPL === item ? "#373a43" : "" }} onClick={() => { setSelectedPL(item) }}>{item.WebsiteName}</p>
                        }
                    })}
                    {selectedPL && selectedPL !== -1 && <a target="_blank" href={selectedPL.URL}>Preview website</a>}

                </div>}
                {selectedPL && <div className="modals__moreInfoOffer__container__take__single">
                    <p>Select landing:</p>
                    {landings?.data.map(item => {
                        if (item.WebsiteType === "Landing")
                            return <p style={{ backgroundColor: selectedL === item ? "#373a43" : "" }} onClick={() => { setSelectedL(item) }}>{item.WebsiteName}</p>
                    })}
                    {selectedL && <a target="_blank" href={selectedL.URL}>Preview website</a>}

                </div>}
                {selectedL && <div className="modals__moreInfoOffer__container__take__button">
                    <p>Enter friendly name for this offer:</p>
                    <input placeholder="Friendly name" ref={friendlyNameRef} />
                </div>}
                {selectedL && <div className="modals__moreInfoOffer__container__take__button">
                    <button onClick={() => { takeOffer() }}>Take offer</button>
                </div>}
            </div>}
        </div>

    </div>
}


const Galery = (props) => {

    const [current, setCurrent] = React.useState(0);
    return <div className="modals__galery">
        <div className="modals__galery__container">
            <div className="modals__galery__container__close">
                <img src="/images/closeIcon.png" onClick={() => { props.onClose() }} />
            </div>
            <img src={props?.images[current]} />
            <p onClick={() => {
                if (current !== 0) {
                    setCurrent(current - 1)
                } else {
                    setCurrent(props?.images.length - 1)
                }
            }} class="modals__galery__container__previous">&#8249;</p>
            <p onClick={() => {
                if (current !== props?.images.length - 1) {
                    setCurrent(current + 1)
                } else {
                    setCurrent(0)
                }
            }} class="modals__galery__container__next">&#8250;</p>
            <div className="modals__galery__container__dots">
                {props?.images.map((item, index) => {
                    return <div onClick={() => { setCurrent(index) }} className={index === current ? `modals__galery__container__dots__single modals__galery__container__dots__single__active` : "modals__galery__container__dots__single"}></div>
                })}
            </div>
        </div>
    </div>
}


export default PublisherOffers;