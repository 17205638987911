import React from "react";
import "./index.scss";

import { useSelector } from "react-redux";
import axios from "axios";
import * as backendModule from "../../../modules/backendModule";
import { animateBox } from "../../../modules/componentAnimation";

import useDefer from "../../../modules/hooks/useDefer";
import useOnScreen from "../../../modules/hooks/useOnScreen";

import Spinner from "../../../components/customComponents/Spinner";
import FilterPanel from "../../../components/customComponents/FilterPanel";
import Dropdown from "../../../components/customComponents/Dropdown";
import FilterBySearch from "../../../filters/FilterBySearch";
import { FilteredCustomTable } from "../../../components/customComponents/Table";

const Currencies = () => {
    const [data, setData] = React.useState();
    const [filters, setFilters] = React.useState([]);
    const [spinner, setSpinner] = React.useState(false)
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [search, setSearch] = React.useState("");
    const curDefer = useDefer();
    const curOnScreen = useOnScreen();

    const curUserSelector = useSelector(state => state?.userData?.userData?.UserInfo);
 
    const getData = () => {
        const filtersTmp = [...filters];

        if (search) {
            filtersTmp.push({
                or: [
                    { name: "Currency", op: "like", value: search },
                    { name: "DefaultRate", op: "like", value: search },
                    { name: "ID", op: "like", value: search },
                ]
            })
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/currencies/getAllCurrencies`,
            data: {
                filters:filtersTmp,
                limit: null
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) {
                    setCanPaginate(true);
                } else {
                    setCanPaginate(false);
                };
            };
            setData(res.data);
        }).catch(() => {
            setData(backendModule.axiosConfig);
        });
    };

    const continueData = () => {
        setCanPaginate(false);
        if (!data) return;
        if (data.status !== "ok") return;
        if (data.data.length === 0) return;

        const filtersTmp = [...filters];

        if (search) {
            filtersTmp.push({
                or: [
                    { name: "Currency", op: "like", value: search },
                    { name: "DefaultRate", op: "like", value: search },
                    { name: "ID", op: "like", value: search },
                ]
            })
        };

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/currencies/getAllCurrencies`,
            data: {
                limit: 20,
                offset: data.data.length,
                filters: filtersTmp
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
                if (res.data.data.length === 20) {
                    setCanPaginate(true);
                } else {
                    setCanPaginate(false);
                };
            };
        }).catch(() => null).finally(() => {
            setSpinner(false);
        });
    };
    React.useEffect(() => {
        getData();
    }, [search, filters])

    React.useEffect(() => {
        if (curOnScreen.isIntersecting && canPaginate) {
            try {
                curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
            } catch { };

            curDefer(() => {
                continueData();
            }, 500);
        };
    }, [canPaginate, curOnScreen.isIntersecting]);


    return <div className="component__currencies">
        <div className="component__currencies__head" style={{
            gridTemplateColumns: curUserSelector?.Flags?.isAdmin ? null : "1fr 400px"
        }}>
            <FilterPanel
                accent="#dfdfdf"
                theme="light"
                filterCB={fi => setFilters(fi)}
                filters={[
                    { name: "ID", friendlyName: "ID", type: "string" },
                    { name: "Currency", friendlyName: "Currency", type: "string" },
                    { name: "Base", friendlyName: "Base", type: "custom", varType: "string", data: data?.data.filter(t => t.value !== null).map(t => {
                        return {text: t.Currency, value: t.ID}
                    }) },
                    { name: "isBase", friendlyName: "Is currency base", type: "boolean" },
                    { name: "createdAt", friendlyName: "Date created", type: "date" },
                    { name: "updatedAt", friendlyName: "Date updated", type: "date" }
                ]} />
            <FilterBySearch onChange={e => setSearch(e)} />
            {curUserSelector?.Flags?.isAdmin && <button onClick={() => { animateBox({ currentTarget: document.querySelector('.component__currencies__content') }, <SelectedCurrency item={null} allCurrencies={data?.data} update={getData} />) }}>Add currency</button>}
        </div>
        <div className="component__currencies__content" style={{ gridTemplateColumns: "1fr" }} >
            <div className="component__currencies__content__left">
                <FilteredCustomTable
                    theme="light"
                    accent="#6C5DD3"
                    headers={["ID", "Currency", "Default rate", "Base", "is Base", ""]}
                    customColumns={["1fr", "1fr", "1fr", "1fr", "70px", curUserSelector?.Flags?.isAdmin ? "200px" : "0px"]}
                    data={(() => {
                        let out = [];

                        if (!data) return [[{ keyID: "noData-spinner", type: "spinner", color: "white" }]];
                        if (data.status === "ok") {
                            for (let item of data.data) {


                                out.push({
                                    columns: [
                                        { keyID: item.ID, type: "text", text: item.ID },
                                        { keyID: item.ID, type: "text", text: item.Currency },
                                        { keyID: item.ID, type: "text", text: item.DefaultRate },
                                        { keyID: item.ID, type: "text", text: item.BaseCurrency ?? "-" },
                                        { keyID: item.ID, type: "custom", data: item.isBase ? <p className="component__currencies__yesbase">Yes</p> : <p className="component__currencies__nobase">No</p> },
                                        {
                                            keyID: item.ID, type: "custom", data: <div className="component__currencies__buttons">
                                                {curUserSelector?.Flags?.isAdmin && <p className="component__currencies__edit" onClick={(e) => {
                                                    e.stopPropagation();
                                                    animateBox({ currentTarget: document.querySelector('.component__currencies__content') }, <SelectedCurrency item={item} allCurrencies={data?.data} update={getData} edit={true} />)
                                                }}>Edit</p>}
                                                {curUserSelector?.Flags?.isAdmin && <p className="component__currencies__delete" onClick={(e) => {
                                                    e.stopPropagation();
                                                    animateBox({ currentTarget: document.querySelector('.component__currencies__content') }, <DeleteCurrency item={item} id={item.ID} update={getData} />)
                                                }}>Delete</p>}
                                            </div>
                                        }
                                    ]
                                })
                            };
                        } else {
                            out.push([{ keyID: "noData-error", type: "text", text: "Error while fetching orders!", color: "#f96666" }]);
                        };

                        if (spinner) out.push([{ keyID: "pagination-spinner", type: "spinner", color: "white" }]);
                        if (out.length === 0) out.push([{ keyID: "noData-noData", type: "text", text: "Nothing to show for now." }]);

                        return out;
                    })()}
                />
            </div>
            {canPaginate && <div ref={curOnScreen.measureRef} className="modals__ordersModal__wrap__content__left__paginate"></div>}

        </div>

    </div>
};


const SelectedCurrency = (props) => {
    const [isBase, setIsBase] = React.useState();
    const [base, setBase] = React.useState();
    const [item, setItem] = React.useState(props.item);
    const [spinner, setSpinner] = React.useState(false);
    const nameRef = React.useRef();
    const rateRef = React.useRef();
    const [error, setError] = React.useState();

    React.useEffect(() => {
        setItem(props.item);
    }, [props.item])

    const saveData = () => {
        let path = '/addCurrency'
        if (props.edit) path = '/editCurrency'
        setSpinner(true)
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/currencies${path}`,
            data: {
                ID: props.item?.ID,
                Currency: nameRef.current.value,
                DefaultRate: rateRef.current.value,
                isBase,
                Base: base
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.update();
                props.onClose();
            } else {
                setError('Please check all your info and try again');
            }
        }).catch(() => {
            setError('Something went wrong please try again later')
        }).finally(() => { setSpinner(false) })
    };

    return <div className="modals__currencyModal">
        <div className="modals__currencyModal__container">
            <div className="modals__currencyModal__close">
                <img src="/images/closeIcon.png" onClick={() => { props.onClose() }} />
            </div>
            <p className="modals__currencyModal__head">{props.edit ? "Edit currency" : "Add new currency"}</p>
            <p className="modals__currencyModal__label" >Currency</p>
            <input defaultValue={item?.Currency} ref={nameRef} type='text' placeholder="Enter Your currency here" />
            <p className="modals__currencyModal__label">Default rate</p>
            <input defaultValue={item?.DefaultRate} ref={rateRef} type="number" placeholder="Enter your default value here" />
            <p className="modals__currencyModal__label">Is Base</p>
            <Dropdown theme="light"
                accent="#6C5DD3" selected={item?.isBase ? 0 : 1} data={[
                    { value: 1, name: 'Yes' },
                    { value: 0, name: 'No' },
                ]} onChange={val => {
                    setIsBase(val.value)
                }} />
            {!isBase && <>
                <p className="modals__currencyModal__label">Select base currency</p>
                <Dropdown theme="light"
                    accent="#6C5DD3" data={props.allCurrencies.map(item => {
                        return {
                            name: item.Currency,
                            value: item.ID
                        }
                    })} onChange={val => {

                        setBase(val.value)
                    }} />
            </>}
            <p className="modals__currencyModal__error">{error}</p>
            <div className="modals__currencyModal__buttons">
                {spinner ? <Spinner color={"##00A3FF"} /> : <button onClick={() => { saveData() }}>Save</button>}
            </div>
        </div>


    </div>
}


const DeleteCurrency = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [error, setError] = React.useState();
    const deleteData = () => {
        setSpinner(true)
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/currencies/removeCurrency`,
            data: {
                ID: props.id
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.update();
                props.onClose();
            } else {
                setError('Please check all your info and try again');
            }
        }).catch(() => {
            setError('Something went wrong please try again later')
        }).finally(() => { setSpinner(false) })
    };
    return <div className="modals__deleteCurrency">
        <div className="modals__deleteCurrency__container">
            <p>You are about to delete <span style={{color: "#6c5dd3"}}>{props.item?.Currency}</span></p>
            <p>Currency deletion is permanent.</p>
            <p>Are you sure you want to continue?</p>
            <p className="modals__currencyModal__error">{error}</p>
            <div className="modals__deleteCurrency__container__buttons">
                <button onClick={() => [deleteData()]}>Yes</button>
                <button onClick={() => { props.onClose() }}>No</button>
            </div>
        </div>
    </div>
}

export default Currencies;