import React from "react";
import "./index.scss";

import { useNavigate } from "react-router";
import animateModule from "../../modules/animateModule";
import * as backendModule from '../../modules/backendModule';
import * as timestampActions from "../../actions/timeStampAction";

import { useDispatch, useSelector } from "react-redux";

import axios from "axios";

const Login = () => {
    const [showError, setShowError] = React.useState(false);
    const userData = useSelector((state) => state.userData);

    const curNavigate = useNavigate();
    let userDataDispatch = useDispatch();

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    const username = React.useRef();
    const password = React.useRef();
    if (userData.isLoggedIn) {
        animateNavigate('/publisher-dashboard')
    }
    const userLogin = (e) => {
        e.preventDefault()

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/auth/login`,
            data: {
                username: username.current.value,
                password: password.current.value,
            },
            ...backendModule.axiosConfig
        }).then((e) => {
            if (e.data.status === 'ok') {
                userDataDispatch(timestampActions.updateTimestamp());
                animateNavigate('/publisher-dashboard')
            } else {
                setShowError(true)

            }
        }).catch((e) => {
            setShowError(true)

        });
    }

    const handleEnter = e => {
        if (e.key === "Enter") {
            userLogin(e);
        };
    };

    return <div className="route__login" style={{ backgroundImage: `url("/images/group_1_bg.png")` }}>
        <div className="route__login__left">
            <div className="route__login__left__form">
                <div className="route__login__left__form__logo">
                    <img src="/images/logoSmall.svg" />
                    <p>CupidAffiliate</p>
                </div>
                <div className="route__login__left__form__header">Log in</div>

                <div className="route__login__left__form__input">
                    <p>Username</p>
                    <input ref={username} type="text" placeholder="Username" onKeyDown={(e)=>{handleEnter(e)}} />
                </div>
                <div className="route__login__left__form__input">
                    <p>Password</p>
                    <input ref={password} type="password" placeholder="Password" onKeyDown={(e)=>{handleEnter(e)}} />
                </div>

                <div className="route__login__left__form__forgot">Forgot your password</div>

                <div className="route__login__left__form__btn" onClick={(e) => { userLogin(e) }}>Log in</div>

                <div className="route__login__left__form__bottom">
                    <p>Need an account? <span onClick={() => animateNavigate("/register")}>Register</span></p>
                </div>

                <div className="route__login__left__form__footer">
                    <p>Privacy policy</p>
                    <p>Terms of service</p>
                </div>

                {showError && <p className="route__login__left__form__infoP">Incorrect username or password</p>}
            </div>
        </div>
        <div className="route__login__right"></div>
    </div>
};

export default Login;