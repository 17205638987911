import React from "react";
import "./index.scss";

import { useSelector } from "react-redux";
import axios from "axios";
import * as backendModule from "../../../modules/backendModule";
import { animateBox } from "../../../modules/componentAnimation";
import Spinner from "../../../components/customComponents/Spinner";
import FilterPanel from "../../../components/customComponents/FilterPanel";
import { FilteredCustomTable } from "../../../components/customComponents/Table";
import Dropdown from "../../../components/customComponents/Dropdown";
import useDefer from "../../../modules/hooks/useDefer";
import useOnScreen from "../../../modules/hooks/useOnScreen";
import FilterBySearch from "../../../filters/FilterBySearch";
import animateModule from "../../../modules/animateModule";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";

const AdminPayouts = () => {
    const [data, setData] = React.useState();
    const [filters, setFilters] = React.useState([]);
    const [spinner, setSpinner] = React.useState(false)
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [search, setSearch] = React.useState("");
    const curDefer = useDefer();
    const curOnScreen = useOnScreen();

    const [processSpinner, setProccesSpinner] = React.useState(false);

    const curNavigate = useNavigate();
    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".root__content"));

    const curUserSelector = useSelector(state => state?.userData?.userData?.UserInfo);

    const getData = () => {
        const filtersTmp = [...filters];

        if (search) {
            filtersTmp.push({
                or: [
                    { name: "Name", op: "like", value: search },
                    { name: "ID", op: "like", value: search },
                    { name: "AdvertiserID", op: "like", value: search },
                ]
            })
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/payments/calculateEarnings`,
            data: {
                filters: filtersTmp
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.axiosConfig);
        });
    };


    React.useEffect(() => {
        getData();
    }, [filters])




    return <div className="component__payouts">
        <div className="component__payouts__head"  >
            <FilterPanel

                accent="#dfdfdf"
                theme="light"
                filterCB={fi => setFilters(fi)}
                filters={[
                    { name: "UserID", friendlyName: "ID", type: "string" }
                ]} />
            <FilterBySearch onChange={e => setSearch(e)} />
            <button onClick={() => { animateNavigate("/admin-processedpayments") }}>Processed </button>
        </div>
        <div className="component__payouts__content">
            <div className="component__payouts__content__row">
                <p>Username</p>
                <p>Data</p>
                <p></p>
            </div>
            {data?.status === 'ok' ? <>
                {Object.keys(data?.data).map((item, index) => {
                    return <div className={`component__payouts__content__row ${index % 2 === 0 && "component__payouts__content__row-2"}`}>
                        <p>{item}</p>
                        <div className={`component__payouts__content__row__data `}>
                            <div className="component__payouts__content__row__data__single">
                                <p>Offer ID</p>
                                <p>Taken offer ID</p>
                                <p>Earned value</p>
                            </div>
                            {data.data[item].TakenOffers.map(elem => {
                                return <div className="component__payouts__content__row__data__single">
                                    <p>{elem.OfferID}</p>
                                    <p>{elem.TakenOfferID}</p>
                                    <p>€ {Number(elem.TotalEarnings).toFixed(2)}</p>
                                </div>
                            })}
                            <div className="component__payouts__content__row__data__single">
                                <p>Total</p>
                                <p></p>
                                <p>€ {(() => {
                                    let tmp = 0
                                    data.data[item].TakenOffers.map(elem => {
                                        tmp += Number(elem.TotalEarnings)
                                    })
                                    return tmp.toFixed(2)
                                })()}</p>
                            </div>
                        </div>
                        <div className="component__payouts__content__row__buttons">
                            {processSpinner ? <Spinner /> : <p onClick={() => {
                                setProccesSpinner(true)
                                let tmp = [];
                                let tmpEarnings = 0;
                                data.data[item].TakenOffers.map(elem => {
                                    tmp.push(elem.TakenOfferID);
                                    tmpEarnings += Number(elem.TotalEarnings);
                                })
                                axios({
                                    url: `${backendModule.backendURL}/payments/proccesPayment`, //your url
                                    method: 'POST',
                                    data: {
                                        TakenOffers: tmp,
                                        UserID: data.data[item].ID
                                    },
                                    ...backendModule.axiosConfig
                                }).then((response) => {
                                    getData();
                                }).catch((e) => { }).finally(() => {
                                    setProccesSpinner(false)

                                })

                            }} >Procces (€ {(() => {
                                let tmp = 0;
                                data.data[item].TakenOffers.map(elem => {
                                    tmp += Number(elem.TotalEarnings)
                                })
                                return tmp.toFixed(2)

                            })()})</p>}
                            <p onClick={() => {
                                let tmp = [];
                                data.data[item].TakenOffers.map(elem => {
                                    tmp.push(elem.TakenOfferID)
                                })
                                axios({
                                    url: `${backendModule.backendURL}/payments/getLeadsPayoutFile`, //your url
                                    method: 'POST',
                                    data: {
                                        TakenOffers: tmp
                                    },
                                    responseType: 'blob', // important
                                    ...backendModule.axiosConfig
                                }).then((response) => {
                                    const url = window.URL.createObjectURL(new Blob([response.data]));
                                    const link = document.createElement('a');
                                    link.href = url;
                                    link.setAttribute('download', `leads-${item}-${moment().format("DD/MM/YYYY")}.csv`); //or any other extension
                                    document.body.appendChild(link);
                                    link.click();
                                }).catch((e) => { })
                            }}>Download leads</p>
                        </div>


                    </div>
                })}
            </> : <Spinner />}
        </div>



    </div>
};










export default AdminPayouts;