import React from "react";
import "./index.scss";

import { useSelector } from "react-redux";
import axios from "axios";
import * as backendModule from "../../../modules/backendModule";
import { animateBox } from "../../../modules/componentAnimation";
import { countries } from "../../../modules/countryModule";

import useDefer from "../../../modules/hooks/useDefer";
import useOnScreen from "../../../modules/hooks/useOnScreen";

import Spinner from "../../../components/customComponents/Spinner";
import FilterPanel from "../../../components/customComponents/FilterPanel";
import Dropdown from "../../../components/customComponents/Dropdown";
import FilterBySearch from "../../../filters/FilterBySearch";
import RadioButton from "../../../components/customComponents/RadioButton";
import { FilteredCustomTable } from "../../../components/customComponents/Table";
import { checkMail } from "../../../modules/miscModule";
import moment from "moment";

const TeamLeader = (props) => {
    const [teamMembers, setTeamMembers] = React.useState();
    const [data, setData] = React.useState();
    const [dataOld, setDataOld] = React.useState();
    const [filters, setFilters] = React.useState([]);
    const [filters2, setFilters2] = React.useState([]);
    const [pageFilters, setPageFilters] = React.useState("today");
    const [individualData, setIndividualData] = React.useState();

    const userDataSelector = useSelector(state => state?.userData?.userData.UserInfo.Flags ?? {});

    const getTeamMembers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/teams/getTeamMembers`,
            ...backendModule.axiosConfig
        }).then(res => {
            setTeamMembers(res.data)
        }).catch(() => {

        })
    }

    React.useEffect(() => {
        getTeamMembers();
    }, [])

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/statistic/getTeamStatistic`,
            data: {
                filters: filters,
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.axiosConfig);
        });
    };

    const getDataOld = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/statistic/getTeamStatistic`,
            data: {
                filters: filters2,
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setDataOld(res.data);
        }).catch(() => {
            setData(backendModule.axiosConfig);
        });
    };

    React.useEffect(() => {
        if (filters.length === 0) return;
        getData()
    }, [filters]);
    React.useEffect(() => {
        if (filters2.length === 0) return;
        getDataOld()
    }, [filters2]);


    const getDataIndividual = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/statistic/getTeamStatisticIndividual`,
            data: {
                filters: filters,
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setIndividualData(res.data);
        }).catch(() => {
            setData(backendModule.axiosConfig);
        });
    };

    React.useEffect(() => {
        if (filters.length === 0) return;
        getDataIndividual()
    }, [filters]);



    const calculateValues = (curr, prev) => {
        if (!curr) {
            return <span className="component__dashboard__content__kpi__single__data2__red">&#129033; 0 (100%)</span>;
        }
        if (!prev) {
            return <span className="component__dashboard__content__kpi__single__data2__green">&#129035; 0 (-100%)</span>;
        }
        if (curr > prev) {
            return <span className="component__dashboard__content__kpi__single__data2__green">
                &#129033; {prev.toFixed(2)} ({(100 - prev / curr * 100).toFixed(2)}%)
            </span>;
        } else return <span className="component__dashboard__content__kpi__single__data2__red">
            &#129035; {prev.toFixed(2)} (-{(100 - curr / prev * 100).toFixed(2)}%)
        </span>;
    }

    const calculateFilters = () => {
        let out = [];
        let out2 = [];

        switch (pageFilters) {
            case "all":
                break;
            case "today":
                out.push({ name: "createdAt", op: "deq", value: moment().toDate().getTime() });
                out2.push({ name: "createdAt", op: "deq", value: moment().add(-1, "days").toDate().getTime() });
                break;
            case "yesterday":
                out.push({ name: "createdAt", op: "deq", value: moment().add(-1, "days").toDate().getTime() });
                out2.push({ name: "createdAt", op: "deq", value: moment().add(-2, "days").toDate().getTime() });
                break;
            case "week":
                out.push({ name: "createdAt", op: "dgeq", value: moment().add(-7, "days").startOf("day").toDate().getTime() });
                out2.push({ name: "createdAt", op: "dgeq", value: moment().add(-14, "days").startOf("day").toDate().getTime() });
                out2.push({ name: "createdAt", op: "dleq", value: moment().add(-7, "days").startOf("day").toDate().getTime() });
                break;
            case "month":
                out.push({ name: "createdAt", op: "dgeq", value: moment().startOf('month').toDate().getTime() });
                out.push({ name: "createdAt", op: "dleq", value: moment().endOf('month').toDate().getTime() });
                out2.push({ name: "createdAt", op: "dgeq", value: moment().add(-1, "month").startOf('month').toDate().getTime() });
                out2.push({ name: "createdAt", op: "dleq", value: moment().add(-1, "month").endOf('month').toDate().getTime() });
                break;
            case "lastmonth":
                out.push({ name: "createdAt", op: "dgeq", value: moment().add(-1, "month").startOf('month').toDate().getTime() });
                out.push({ name: "createdAt", op: "dleq", value: moment().add(-1, "month").endOf('month').toDate().getTime() });
                out2.push({ name: "createdAt", op: "dgeq", value: moment().add(-2, "month").startOf('month').toDate().getTime() });
                out2.push({ name: "createdAt", op: "dleq", value: moment().add(-2, "month").endOf('month').toDate().getTime() });
                break;
            default: break;
        }


        setFilters(out);
        setFilters2(out2);
        return out;
    };

    React.useEffect(() => {
        calculateFilters(pageFilters)
    }, [pageFilters]);


    return <div className="component__teamLeader">
        {teamMembers?.status === "ok" ? <div className="component__teamLeader__members">
            {teamMembers.data.map(item => {
                return <div className="component__teamLeader__members__single" onClick={e => {
                    if (!JSON.parse(item.Flags).isTeamLeader) {
                        animateBox(e, <CreateUser update={getTeamMembers} edit={true} item={item} />)
                    }
                }} style={JSON.parse(item.Flags).isTeamLeader ? {
                    borderRight: "5px solid green"
                } : null}>
                    <p className="component__teamLeader__members__single__name">{item.FirstName} {item.LastName}</p>
                    <p className="component__teamLeader__members__single__username">{item.Username}</p>
                    <p className="component__teamLeader__members__single__role">{JSON.parse(item.Flags).isTeamLeader ? "Team leader" : "Team member"}</p>
                </div>
            })}
            <div className="route__team__create__button" onClick={e => {
                animateBox(e, <CreateUser update={getTeamMembers} />)
            }}>Add new user</div>
        </div> : <Spinner />}
        <div className="component__dashboard__buttons">
            <p onClick={() => {
                setPageFilters("today");
            }} className={`component__dashboard__buttons__single ${pageFilters === 'today' && "component__dashboard__buttons__single--active"}`}>Today </p>
            <p onClick={() => {
                setPageFilters("yesterday");
            }} className={`component__dashboard__buttons__single ${pageFilters === 'yesterday' && "component__dashboard__buttons__single--active"}`}>Yesterday </p>
            <p onClick={() => {
                setPageFilters("week");
            }} className={`component__dashboard__buttons__single ${pageFilters === 'week' && "component__dashboard__buttons__single--active"}`}>7 days</p>
            <p onClick={() => {
                setPageFilters("month");
            }} className={`component__dashboard__buttons__single ${pageFilters === 'month' && "component__dashboard__buttons__single--active"}`}>This Month</p>
            <p onClick={() => {
                setPageFilters("lastmonth");
            }} className={`component__dashboard__buttons__single ${pageFilters === 'lastmonth' && "component__dashboard__buttons__single--active"}`}>Last Month</p>


        </div>


        {data ? <>
            <div className="component__dashboard__content__kpi">
                <div className="component__dashboard__content__kpi__single">
                    <p className="component__dashboard__content__kpi__single__head">
                        Clicks
                    </p>
                    <p className="component__dashboard__content__kpi__single__data">
                        {data?.data?.leads?.View ? data?.data?.leads?.View : 0}
                    </p>
                    <p className="component__dashboard__content__kpi__single__data2">
                        {calculateValues(data?.data?.leads?.View, dataOld?.data?.leads?.View)}
                    </p>
                </div>
                <div className="component__dashboard__content__kpi__single">
                    <p className="component__dashboard__content__kpi__single__head">
                        Registrations
                    </p>
                    <p className="component__dashboard__content__kpi__single__data">
                        {data?.data?.leads?.Registration ? data?.data?.leads?.Registration : 0}
                    </p>
                    <p className="component__dashboard__content__kpi__single__data2">
                        {calculateValues(data?.data?.leads?.Registration, dataOld?.data?.leads?.Registration)}
                    </p>
                </div>
                <div className="component__dashboard__content__kpi__single">
                    <p className="component__dashboard__content__kpi__single__head">
                        CR (Registrations)
                    </p>
                    <p className="component__dashboard__content__kpi__single__data">
                        {data?.data?.leads?.Registration && data?.data?.leads?.View ? (data?.data?.leads?.Registration / data?.data?.leads?.View * 100).toFixed(2) : 0} %
                    </p>
                    <p className="component__dashboard__content__kpi__single__data2">
                        {calculateValues(data?.data?.leads?.Registration / data?.data?.leads?.View * 100, dataOld?.data?.leads?.Registration / dataOld?.data?.leads?.View * 100)}

                    </p>
                </div>
                <div className="component__dashboard__content__kpi__single">
                    <p className="component__dashboard__content__kpi__single__head">
                        DOI
                    </p>
                    <p className="component__dashboard__content__kpi__single__data">
                        {data?.data?.leads?.Verification ? data?.data?.leads?.Verification : 0}
                    </p>
                    <p className="component__dashboard__content__kpi__single__data2">
                        {calculateValues(data?.data?.leads?.Verification, dataOld?.data?.leads?.Verification)}

                    </p>
                </div>
                <div className="component__dashboard__content__kpi__single">
                    <p className="component__dashboard__content__kpi__single__head">
                        CR (DOI)
                    </p>
                    <p className="component__dashboard__content__kpi__single__data">
                        {data?.data?.leads?.Verification ? (data?.data?.leads?.Verification / data?.data?.leads?.Registration * 100).toFixed(2) : 0} %
                    </p>
                    <p className="component__dashboard__content__kpi__single__data2">
                        {calculateValues(data?.data?.leads?.Verification / data?.data?.leads?.Registration * 100, dataOld?.data?.leads?.Verification / dataOld?.data?.leads?.Registration * 100)}

                    </p>
                </div>

                <div className="component__dashboard__content__kpi__single">
                    <p className="component__dashboard__content__kpi__single__head">
                        Unique buyers
                    </p>
                    <p className="component__dashboard__content__kpi__single__data">
                        {data?.data?.users ? Object.keys(data?.data?.users)?.length : 0}
                    </p>
                    <p className="component__dashboard__content__kpi__single__data2">
                        {data?.data?.users && dataOld?.data?.users && calculateValues(Object.keys(data?.data?.users)?.length, Object.keys(dataOld?.data?.users)?.length)}
                    </p>
                </div>
                <div className="component__dashboard__content__kpi__single">
                    <p className="component__dashboard__content__kpi__single__head">
                        Unique buyers (CR)
                    </p>
                    <p className="component__dashboard__content__kpi__single__data">
                        {data?.data?.users && data?.data?.leads?.Registration ? (Number(Object.keys(data?.data?.users)?.length / data?.data?.leads?.Registration * 100)).toFixed(2) : 0} %
                    </p>
                    <p className="component__dashboard__content__kpi__single__data2">
                        {data?.data?.users && dataOld?.data?.users && calculateValues(Object.keys(data?.data?.users)?.length / data?.data?.leads?.Registration * 100, Object.keys(dataOld?.data?.users)?.length / dataOld?.data?.leads?.Registration * 100)}

                    </p>
                </div>
                <div className="component__dashboard__content__kpi__single">
                    <p className="component__dashboard__content__kpi__single__head">
                        Unique sales
                    </p>
                    <p className="component__dashboard__content__kpi__single__data">
                        {data.data?.users ? (() => {
                            let tmp = 0;
                            Object.keys(data.data?.users).map(user => {
                                tmp += data.data?.users[user][0]
                            })
                            return tmp
                        })() : 0} €
                    </p>
                    <p className="component__dashboard__content__kpi__single__data2">
                        {data?.data?.users && dataOld?.data?.users && (() => {
                            let salesOld = 0;
                            let sales = 0;
                            Object.keys(data.data?.users).map(user => {
                                sales += data.data?.users[user]?.[0]
                            })
                            Object.keys(dataOld.data?.users).map(user => {
                                salesOld += dataOld.data?.users[user]?.[0]
                            })
                            return calculateValues(sales, salesOld)
                        })()}
                    </p>
                </div>
                <div className="component__dashboard__content__kpi__single">
                    <p className="component__dashboard__content__kpi__single__head">
                        Tail sales
                    </p>
                    <p className="component__dashboard__content__kpi__single__data">
                        {data.data?.users ? (() => {
                            let tmp = 0;
                            Object.keys(data.data?.users).map(user => {
                                if (data.data?.users[user].length > 1) {
                                    data.data?.users[user].map((item, index) => {
                                        if (index > 0) {
                                            tmp += item;
                                        }
                                    })
                                }
                            })
                            return tmp.toFixed(2)
                        })() : 0} €
                    </p>
                    <p className="component__dashboard__content__kpi__single__data2">
                        {data?.data?.users && dataOld?.data?.users && (() => {
                            let salesOld = 0;
                            let sales = 0;
                            Object.keys(data.data?.users).map(user => {
                                if (data.data?.users[user].length > 1) {
                                    data.data?.users[user].map((item, index) => {
                                        if (index > 0) {
                                            sales += item;
                                        }
                                    })
                                }
                            })
                            Object.keys(dataOld.data?.users).map(user => {
                                if (dataOld.data?.users[user].length > 1) {
                                    dataOld.data?.users[user].map((item, index) => {
                                        if (index > 0) {
                                            salesOld += item;
                                        }
                                    })
                                }
                            })
                            return calculateValues(sales, salesOld)
                        })()}
                    </p>
                </div>
                <div className="component__dashboard__content__kpi__single">
                    <p className="component__dashboard__content__kpi__single__head">
                        Total sales
                    </p>
                    <p className="component__dashboard__content__kpi__single__data">
                        {data.data?.users ? (() => {
                            let tmp = 0;
                            Object.keys(data.data?.users).map(user => {
                                data.data?.users[user].map((item, index) => {
                                    tmp += item;
                                })
                            })
                            return tmp.toFixed(2)
                        })() : 0} €
                    </p>
                    <p className="component__dashboard__content__kpi__single__data2">
                        {data?.data?.users && dataOld?.data?.users && (() => {
                            let salesOld = 0;
                            let sales = 0;
                            Object.keys(data.data?.users).map(user => {
                                data.data?.users[user].map((item, index) => {
                                    sales += item;
                                })
                            })
                            Object.keys(dataOld.data?.users).map(user => {
                                dataOld.data?.users[user].map((item, index) => {
                                    salesOld += item;
                                })
                            })
                            return calculateValues(sales, salesOld)
                        })()}
                    </p>

                </div>
                <div className="component__dashboard__content__kpi__single">
                    <p className="component__dashboard__content__kpi__single__head">
                        Commisions
                    </p>
                    <p className="component__dashboard__content__kpi__single__data">
                        {data.data?.commisions ? (() => {
                            let tmp = 0;
                            Object.keys(data.data?.commisions).map(com => {
                                tmp += data.data?.commisions[com];
                            })
                            return tmp.toFixed(2)
                        })() : 0} €
                    </p>
                    <p className="component__dashboard__content__kpi__single__data2">
                        {data?.data?.commisions && dataOld?.data?.commisions && (() => {
                            let salesOld = 0;
                            let sales = 0;
                            Object.keys(data.data?.commisions).map(com => {
                                sales += data.data?.commisions[com];
                            })
                            Object.keys(dataOld.data?.commisions).map(com => {
                                salesOld += dataOld.data?.commisions[com];
                            })
                            return calculateValues(sales, salesOld)
                        })()}
                    </p>
                </div>
                <div className="component__dashboard__content__kpi__single">
                    <p className="component__dashboard__content__kpi__single__head">
                        Crew expenses
                    </p>
                    <p className="component__dashboard__content__kpi__single__data">
                        {data.data?.crewCommisions ? (() => {
                            let tmp = 0;
                            Object.keys(data.data?.crewCommisions).map(com => {
                                tmp += data.data?.crewCommisions[com];
                            })
                            return tmp.toFixed(2)
                        })() : 0} €
                    </p>
                    <p className="component__dashboard__content__kpi__single__data2">
                        {data?.data?.crewCommisions && dataOld?.data?.crewCommisions && (() => {
                            let salesOld = 0;
                            let sales = 0;
                            Object.keys(data.data?.crewCommisions).map(com => {
                                sales += data.data?.crewCommisions[com];
                            })
                            Object.keys(dataOld.data?.crewCommisions).map(com => {
                                salesOld += dataOld.data?.crewCommisions[com];
                            })
                            return calculateValues(sales, salesOld)
                        })()}
                    </p>
                </div>


            </div>
            <div className="component__dashboard__content__kpi" style={{ gridTemplateColumns: "1fr", marginTop: "20px" }}>
                <div className="component__dashboard__content__kpi__single">
                    <p className="component__dashboard__content__kpi__single__head">
                        Profit
                    </p>
                    <p className="component__dashboard__content__kpi__single__data">
                        {data.data?.profit ? (() => {
                            let tmp = 0;
                            Object.keys(data.data?.profit).map(com => {
                                tmp += data.data?.profit[com];
                            })
                            return tmp.toFixed(2)
                        })() : 0} €
                    </p>
                    <p className="component__dashboard__content__kpi__single__data2">
                        {data?.data?.profit && dataOld?.data?.profit && (() => {
                            let salesOld = 0;
                            let sales = 0;
                            Object.keys(data.data?.profit).map(com => {
                                sales += data.data?.profit[com];
                            })
                            Object.keys(dataOld.data?.profit).map(com => {
                                salesOld += dataOld.data?.profit[com];
                            })
                            return calculateValues(sales, salesOld)
                        })()}
                    </p>
                </div>
            </div>

        </> : <Spinner />}
        {individualData?.status === "ok" ? <div className="component__teamLeader__individual">
            <div className="component__teamLeader__individual__row" >
                <p>User</p>
                <p>Clicks</p>
                <p>Registrations</p>
                <p>CR (Registrations)</p>
                <p>DOI</p>
                <p>CR (DOI)</p>
                <p>Unique buyers</p>
                <p>Unique buyers (CR)</p>
                <p>Unique sales</p>
                <p>Tail sales</p>
                <p>Total sales</p>
                <p>Commisions</p>
                <p>Crew commision</p>
                <p>Profit</p>
                <p>Earnings per click</p>
                <p>Earnings per registration</p>
                <p>Earnings per Purchase</p>
                <p>Earnings per Buyer</p>
            </div>
            {individualData?.status === 'ok' && Object.keys(individualData.data).map((item, index) => {
                return <div className={`component__teamLeader__individual__row ${index % 2 === 0 && "component__teamLeader__individual__row-2"} `} >
                    <p>{individualData.data[item].user}</p>
                    <p>{individualData?.data[item]?.leadReport?.View ? individualData?.data[item]?.leadReport?.View : 0}</p>
                    <p>{individualData?.data[item]?.leadReport?.Registration ? individualData?.data[item]?.leadReport?.Registration : 0}</p>
                    <p>{individualData?.data[item]?.leadReport?.Registration && individualData?.data[item]?.leadReport?.View ? (individualData?.data[item]?.leadReport?.Registration / individualData?.data[item]?.leadReport?.View * 100).toFixed(2) : 0} %</p>
                    <p>{individualData?.data[item]?.leadReport?.Verification ? individualData?.data[item]?.leadReport?.Verification : 0}</p>
                    <p>{individualData?.data[item]?.leadReport?.Verification ? (individualData?.data[item]?.leadReport?.Verification / individualData?.data[item]?.leadReport?.Registration * 100).toFixed(2) : 0} %</p>
                    <p>{individualData?.data[item]?.users ? Object.keys(individualData?.data[item]?.users)?.length : 0}</p>
                    <p>{individualData?.data[item]?.users && individualData?.data[item]?.leadReport?.Registration ?
                        (Number(Object.keys(individualData?.data[item]?.users)?.length / individualData?.data[item]?.leadReport?.Registration * 100)).toFixed(2) : 0} %</p>
                    <p>{individualData?.data[item]?.users ? (() => {
                        let tmp = 0;
                        Object.keys(individualData?.data[item]?.users).map(user => {
                            tmp += individualData?.data[item]?.users[user][0]
                        })
                        return tmp.toFixed(2)
                    })() : 0} €</p>
                    <p>
                        {(() => {
                            if (!individualData.data[item]?.users) return 0;
                            let tmp = 0;
                            Object.keys(individualData?.data[item]?.users).map(user => {
                                if (individualData?.data[item]?.users[user].length > 1) {
                                    individualData?.data[item]?.users[user].map((elem, index) => {
                                        if (index > 0) {
                                            tmp += elem;
                                        }
                                    })
                                }
                            })
                            return tmp.toFixed(2)
                        })()} €
                    </p>
                    <p>
                        {individualData.data[item]?.users ? (() => {
                            let tmp = 0;
                            Object.keys(individualData?.data[item]?.users).map(user => {
                                individualData?.data[item]?.users[user].map((elem, index) => {
                                    tmp += elem;
                                })
                            })
                            return tmp.toFixed(2)
                        })() : 0} €
                    </p>
                    <p>
                        {individualData?.data[item]?.commisions ? (() => {
                            let tmp = 0;
                            Object.keys(individualData?.data[item]?.commisions).map(com => {
                                tmp += individualData?.data[item]?.commisions[com];
                            })
                            return tmp.toFixed(2)
                        })() : 0} €
                    </p>

                    <p>
                        {individualData?.data[item]?.crewCommisions ? (() => {
                            let tmp = 0;
                            Object.keys(individualData?.data[item]?.crewCommisions).map(com => {
                                tmp += individualData?.data[item]?.crewCommisions[com];
                            })
                            return tmp.toFixed(2)
                        })() : 0} €
                    </p>
                    <p>
                        {individualData?.data[item]?.profit ? (() => {
                            let tmp = 0;
                            Object.keys(individualData?.data[item]?.profit).map(com => {
                                tmp += individualData?.data[item]?.profit[com];
                            })
                            return tmp.toFixed(2)
                        })() : 0} €
                    </p>
                    <p>
                        {individualData?.data[item]?.commisions ? (() => {
                            let tmp = 0;
                            Object.keys(individualData?.data[item]?.commisions).map(com => {
                                tmp += individualData?.data[item]?.commisions[com];
                            })
                            return (tmp.toFixed(2) / Number(individualData?.data[item]?.leadReport?.View)).toFixed(2)
                        })() : 0} €
                    </p>
                    <p>
                        {individualData?.data[item]?.commisions ? (() => {
                            let tmp = 0;
                            Object.keys(individualData?.data[item]?.commisions).map(com => {
                                tmp += individualData?.data[item]?.commisions[com];
                            })
                            return (tmp.toFixed(2) / Number(individualData?.data[item]?.leadReport?.Registration)).toFixed(2)
                        })() : 0} €
                    </p>
                    <p>
                        {(individualData?.data[item]?.commisions && individualData?.data[item]?.users) ? (() => {
                            let tmp = 0;
                            Object.keys(individualData?.data[item]?.commisions).map(com => {
                                tmp += individualData?.data[item]?.commisions[com];
                            })
                            let tmp2 = 0;
                            Object.keys(individualData?.data[item]?.users).map(user => {
                                individualData?.data[item]?.users[user].map((item, index) => {
                                    tmp2 += 1;
                                })
                            })

                            return (tmp / tmp2).toFixed(2)
                        })() : 0} €
                    </p>
                    <p>
                        {(individualData?.data[item]?.commisions && individualData?.data[item]?.users) ? (() => {
                            let tmp = 0;
                            Object.keys(individualData?.data[item]?.commisions).map(com => {
                                tmp += individualData?.data[item]?.commisions[com];
                            })
                            let tmp2 = 0;
                            Object.keys(individualData?.data[item]?.users).map(user => {
                                individualData?.data[item]?.users[user].map((item, index) => {
                                    tmp2 += item;
                                })
                            })

                            return (tmp / Object.keys(individualData?.data[item]?.users).length).toFixed(2)
                        })() : 0} €
                    </p>

                </div>
            })}

        </div> : <Spinner />}











    </div>
};


export default TeamLeader;



const CreateUser = (props) => {
    const [item, setItem] = React.useState(props.item);
    const [spinner, setSpinner] = React.useState(false);
    const [selectedCountry, setSelectedCountry] = React.useState();
    const [error, setError] = React.useState();
    const [focusedInput, setFocusedInput] = React.useState();
    const [earningState, setEarningState] = React.useState(0);

    const usernameRef = React.useRef();
    const emailRef = React.useRef();
    const passwordRef = React.useRef();
    const firstNameRef = React.useRef();
    const lastNameRef = React.useRef();
    const zipRef = React.useRef();
    const experienceRef = React.useRef();

    const whatsappRef = React.useRef();
    const skypeRef = React.useRef();
    const telegramRef = React.useRef();

    const earningsRef = React.useState();



    const saveData = () => {
        setError();
        let data = {
            username: firstNameRef.current.value.toLowerCase()+"."+lastNameRef.current.value.toLowerCase(),
            password: passwordRef.current.value,
            email: emailRef.current.value,
            flags: {
                isAdmin: false,
                isAdvertiser: false,
                isManager: false,
                isManager: false,
                isVerified: true,
                isTeamLeader: false,
                isTeamMember: true
            },

            FirstName: firstNameRef.current.value,
            LastName: lastNameRef.current.value,
            EarningsPercentage: earningsRef.current.value
        };

        if (!props.edit) {
            if (!data.username) return setError("Username is required");
            if (data.username.length < 8 || data.username.length > 64) return setError("Usernaem must be between 8 and 64 characters");
            if (!data.password) return setError("Password is required");
            if (data.password.length < 8 || data.password.length > 64) return setError("Password must be between 8 and 64 characters");
        } else {
            if (data.password) {
                if (data.password.length < 8 || data.password.length > 64) return setError("Password must be between 8 and 64 characters");
            };
            data["id"] = item?.ID;
            data["newPassword"] = data.password;
            data["newEmail"] = data.email;
            data["newFlags"] = data.flags;
        };
        if (!data.email) return setError("Email is required");
        if (!checkMail(data.email)) return setError("Email is invalid");
        if (!data.FirstName) return setError("First name is required");
        if (!data.LastName) return setError("Last name is required");
        let path = '/createNewTeamMember';
        if (props.edit) path = '/editTeamMember';
        setSpinner(true)
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/teams${path}`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.update();
                props.onClose();
            } else {
                setError('Please check all your info and try again');
            }
        }).catch((e) => {
            setError('Something went wrong please try again later')
        }).finally(() => { setSpinner(false) })
    };

    return <div className="modals__userModal">
        <div className="modals__userModal__container">
            <div className="modals__userModal__close">
                <img src="/images/closeIcon.png" onClick={() => { props.onClose() }} />
            </div>
            <p className="modals__userModal__head">{props.edit ? "Edit user" : "Add new user"}</p>
            <p className="modals__userModal__label">Email  of team member  </p>
            <input ref={emailRef} defaultValue={item?.Email} type="text" placeholder="Email" />
            <p className="modals__userModal__label">Password  of team member </p>
            <input ref={passwordRef} type="password" placeholder="Password" />
            <p className="modals__userModal__label">First name  of team member </p>
            <input ref={firstNameRef} defaultValue={item?.FirstName} type="text" placeholder="First name" />
            <p className="modals__userModal__label">Last name  of team member </p>
            <input ref={lastNameRef} defaultValue={item?.LastName} type="text" placeholder="Last name" />
            <p className="modals__userModal__label">Percentage of earnings taken by the team leader</p>
            <input ref={earningsRef} onChange={(e) => { setEarningState(e.target.value) }} type="number" defaultValue={item?.FirstName} placeholder="Percentage of earnings" />
            {earningState ? <p className="modals__userModal__label">Team member will get {100 - Number(earningState)} % of earnings and you will get {earningState} % of earnings </p>:""}
            <p className="modals__userModal__error">{error}</p>
            <div className="modals__userModal__buttons">
                {spinner ? <Spinner color={"#00A3FF"} /> : <button onClick={() => { saveData() }}>Save</button>}
            </div>
        </div>
    </div>
}


