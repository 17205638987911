import React from "react";
import "./index.scss";
import moment from "moment";

import { useSelector } from "react-redux";
import axios from "axios";
import * as backendModule from "../../../modules/backendModule";
import FilterPanel from "../../../components/customComponents/FilterPanel";
import Spinner from "../../../components/customComponents/Spinner";
import useDefer from "../../../modules/hooks/useDefer";
import useOnScreen from "../../../modules/hooks/useOnScreen";
import animateModule from "../../../modules/animateModule";
import { useNavigate } from "react-router-dom";

import { Pie, Line } from 'react-chartjs-2';
import { Chart, ArcElement } from 'chart.js'
import { CategoryScale } from 'chart.js'
import { registerables } from 'chart.js';



const AdvertiserDashboard = () => {
    const [data, setData] = React.useState();
    const [dataOld, setDataOld] = React.useState();
    const [filters, setFilters] = React.useState([]);
    const [filters2, setFilters2] = React.useState([]);
    const [spinner, setSpinner] = React.useState(false)
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [pageFilters, setPageFilters] = React.useState("today");
    const [dateModal, setDateModal] = React.useState(false);
    const curDefer = useDefer();
    const curOnScreen = useOnScreen();

    let customDateStart = React.useRef('')
    let customDateEnd = React.useRef('');

    const curUserSelector = useSelector(state => state?.userData?.userData?.UserInfo);

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/statistic/getAdvertiserStatistic`,
            data: {
                filters: filters,
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.axiosConfig);
        });
    };

    const getDataOld = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/statistic/getAdvertiserStatistic`,
            data: {
                filters: filters2,
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setDataOld(res.data);
        }).catch(() => {
            setData(backendModule.axiosConfig);
        });
    };

    React.useEffect(() => {
        if (filters.length === 0) return;
        getData()
    }, [filters]);
    React.useEffect(() => {
        if (filters2.length === 0) return;
        getDataOld()
    }, [filters2]);



    const calculateFilters = () => {
        let out = [];
        let out2 = [];

        switch (pageFilters) {
            case "all":
                break;
            case "today":
                out.push({ name: "createdAt", op: "deq", value: moment().toDate().getTime() });
                out2.push({ name: "createdAt", op: "deq", value: moment().add(-1, "days").toDate().getTime() });
                break;
            case "yesterday":
                out.push({ name: "createdAt", op: "deq", value: moment().add(-1, "days").toDate().getTime() });
                out2.push({ name: "createdAt", op: "deq", value: moment().add(-2, "days").toDate().getTime() });
                break;
            case "week":
                out.push({ name: "createdAt", op: "dgeq", value: moment().add(-7, "days").startOf("day").toDate().getTime() });
                out2.push({ name: "createdAt", op: "dgeq", value: moment().add(-14, "days").startOf("day").toDate().getTime() });
                out2.push({ name: "createdAt", op: "dleq", value: moment().add(-7, "days").startOf("day").toDate().getTime() });
                break;
            case "month":
                out.push({ name: "createdAt", op: "dgeq", value: moment().startOf('month').toDate().getTime() });
                out.push({ name: "createdAt", op: "dleq", value: moment().endOf('month').toDate().getTime() });
                out2.push({ name: "createdAt", op: "dgeq", value: moment().add(-1, "month").startOf('month').toDate().getTime() });
                out2.push({ name: "createdAt", op: "dleq", value: moment().add(-1, "month").endOf('month').toDate().getTime() });
                break;
            case "lastmonth":
                out.push({ name: "createdAt", op: "dgeq", value: moment().add(-1, "month").startOf('month').toDate().getTime() });
                out.push({ name: "createdAt", op: "dleq", value: moment().add(-1, "month").endOf('month').toDate().getTime() });
                out2.push({ name: "createdAt", op: "dgeq", value: moment().add(-2, "month").startOf('month').toDate().getTime() });
                out2.push({ name: "createdAt", op: "dleq", value: moment().add(-2, "month").endOf('month').toDate().getTime() });
                break;
            default: break;
        }


        setFilters(out);
        setFilters2(out2);
        return out;
    };

    React.useEffect(() => {

        calculateFilters(pageFilters)
    }, [pageFilters]);

    const calculateValues = (curr, prev) => {
        if (!curr) {
            return <span className="component__dashboard__content__kpi__single__data2__red">&#129033; 0 (100%)</span>;
        }
        if (!prev) {
            return <span className="component__dashboard__content__kpi__single__data2__green">&#129035; 0 (-100%)</span>;
        }
        if (curr > prev) {
            return <span className="component__dashboard__content__kpi__single__data2__green">
                &#129033; {prev.toFixed(2)} ({(100 - prev / curr * 100).toFixed(2)}%)
            </span>;
        } else return <span className="component__dashboard__content__kpi__single__data2__red">
            &#129035; {prev.toFixed(2)} (-{(100 - curr / prev * 100).toFixed(2)}%)
        </span>;
    }

    let colors = [
        "#FFA459",
        "#FF59DB",
        "#FF59A0",
        "#59FFEC",
        "#59C6FF",
        "#598BFF",
        "#5963FF",
        "#8F59FF",
        "#B759FF",
        "#FFED59",
        "#9FFF59",
        "#59FF74",
        "#59FFA4",
        "#59FFD3",
        "#5999FF",
        "#FF5959",
        "#FFA059",
        "#FFCC59",
        "#FFE659",
        "#FFFE59",
        "#D8FF59",
        "#59FF5E",
        "#59FFB6",
        "#F459FF",
        "#FF59CE",
        "#FF597C",
        "#FF6259",
        "#FF7F59",
        "#FF9959",
        "#FFB559",
        "#FFD959",
        "#FFF759",
        "#C0FF59",
        "#59FF66",
        "#59FF96",
        "#59FFC6",
        "#59A8FF",
        "#5986FF",
        "#5962FF",
        "#A159FF",
        "#C659FF",
        "#F459FF",
        "#FF5C59",
        "#FF7259",
        "#FF8A59",
        "#FFC059",
        "#FFDA59",
        "#596BFF",
        "#5954FF",
        "#8C59FF"
    ];

    Chart.register(ArcElement);
    Chart.register(CategoryScale);
    Chart.register(...registerables);



    return <div className="component__dashboard">
        <div className="component__dashboard__buttons">
            <p onClick={() => {
                setPageFilters("today");
            }} className={`component__dashboard__buttons__single ${pageFilters === 'today' && "component__dashboard__buttons__single--active"}`}>Today </p>
            <p onClick={() => {
                setPageFilters("yesterday");
            }} className={`component__dashboard__buttons__single ${pageFilters === 'yesterday' && "component__dashboard__buttons__single--active"}`}>Yesterday </p>
            <p onClick={() => {
                setPageFilters("week");
            }} className={`component__dashboard__buttons__single ${pageFilters === 'week' && "component__dashboard__buttons__single--active"}`}>7 days</p>
            <p onClick={() => {
                setPageFilters("month");
            }} className={`component__dashboard__buttons__single ${pageFilters === 'month' && "component__dashboard__buttons__single--active"}`}>This Month</p>
            <p onClick={() => {
                setPageFilters("lastmonth");
            }} className={`component__dashboard__buttons__single ${pageFilters === 'lastmonth' && "component__dashboard__buttons__single--active"}`}>Last Month</p>


        </div>
        <div className="component__dashboard__head" style={{
            gridTemplateColumns: curUserSelector?.Flags?.isAdmin ? null : "1fr 400px"
        }}>
            <FilterPanel
                accent="#dfdfdf"
                theme="light"
                filterCB={fi => setFilters(fi)}
                filters={[
                    { name: "ID", friendlyName: "ID", type: "string" },
                    { name: "OfferID", friendlyName: "Offer ID", type: "string" },
                    { name: "TakenOfferID", friendlyName: "Taken offer ID", type: "string" },
                    { name: "AdvertiserID", friendlyName: "Advertiser ID", type: "string" },
                    { name: "Name", friendlyName: "Name", type: "string" },
                    { name: "Type", friendlyName: "Type", type: "string" },

                    { name: "createdAt", friendlyName: "Date created", type: "date" },
                    { name: "updatedAt", friendlyName: "Date updated", type: "date" }
                ]} />

        </div>
        <div className="component__dashboard__content">
            {data ? <>
                <div className="component__dashboard__content__kpi">
                    <div className="component__dashboard__content__kpi__single">
                        <p className="component__dashboard__content__kpi__single__head">
                            Clicks
                        </p>
                        <p className="component__dashboard__content__kpi__single__data">
                            {data?.data?.leads?.View ? data?.data?.leads?.View : 0}
                        </p>
                        <p className="component__dashboard__content__kpi__single__data2">
                            {calculateValues(data?.data?.leads?.View, dataOld?.data?.leads?.View)}
                        </p>
                    </div>
                    <div className="component__dashboard__content__kpi__single">
                        <p className="component__dashboard__content__kpi__single__head">
                            Registrations
                        </p>
                        <p className="component__dashboard__content__kpi__single__data">
                            {data?.data?.leads?.Registration ? data?.data?.leads?.Registration : 0}
                        </p>
                        <p className="component__dashboard__content__kpi__single__data2">
                            {calculateValues(data?.data?.leads?.Registration, dataOld?.data?.leads?.Registration)}
                        </p>
                    </div>
                    <div className="component__dashboard__content__kpi__single">
                        <p className="component__dashboard__content__kpi__single__head">
                            CR (Registrations)
                        </p>
                        <p className="component__dashboard__content__kpi__single__data">
                            {data?.data?.leads?.Registration && data?.data?.leads?.View ? (data?.data?.leads?.Registration / data?.data?.leads?.View * 100).toFixed(2) : 0} %
                        </p>
                        <p className="component__dashboard__content__kpi__single__data2">
                            {calculateValues(data?.data?.leads?.Registration / data?.data?.leads?.View * 100, dataOld?.data?.leads?.Registration / dataOld?.data?.leads?.View * 100)}

                        </p>
                    </div>
                    <div className="component__dashboard__content__kpi__single">
                        <p className="component__dashboard__content__kpi__single__head">
                            DOI
                        </p>
                        <p className="component__dashboard__content__kpi__single__data">
                            {data?.data?.leads?.Verification ? data?.data?.leads?.Verification : 0}
                        </p>
                        <p className="component__dashboard__content__kpi__single__data2">
                            {calculateValues(data?.data?.leads?.Verification, dataOld?.data?.leads?.Verification)}

                        </p>
                    </div>
                    <div className="component__dashboard__content__kpi__single">
                        <p className="component__dashboard__content__kpi__single__head">
                            CR (DOI)
                        </p>
                        <p className="component__dashboard__content__kpi__single__data">
                            {data?.data?.leads?.Verification ? (data?.data?.leads?.Verification / data?.data?.leads?.Registration * 100).toFixed(2) : 0} %
                        </p>
                        <p className="component__dashboard__content__kpi__single__data2">
                            {calculateValues(data?.data?.leads?.Verification / data?.data?.leads?.Registration * 100, dataOld?.data?.leads?.Verification / dataOld?.data?.leads?.Registration * 100)}

                        </p>
                    </div>

                    <div className="component__dashboard__content__kpi__single">
                        <p className="component__dashboard__content__kpi__single__head">
                            Unique buyers
                        </p>
                        <p className="component__dashboard__content__kpi__single__data">
                            {data?.data?.users ? Object.keys(data?.data?.users)?.length : 0}
                        </p>
                        <p className="component__dashboard__content__kpi__single__data2">
                            {data?.data?.users && dataOld?.data?.users && calculateValues(Object.keys(data?.data?.users)?.length, Object.keys(dataOld?.data?.users)?.length)}
                        </p>
                    </div>
                    <div className="component__dashboard__content__kpi__single">
                        <p className="component__dashboard__content__kpi__single__head">
                            Unique buyers (CR)
                        </p>
                        <p className="component__dashboard__content__kpi__single__data">
                            {data?.data?.users && data?.data?.leads?.Registration ? (Number(Object.keys(data?.data?.users)?.length / data?.data?.leads?.Registration * 100)).toFixed(2) : 0} %
                        </p>
                        <p className="component__dashboard__content__kpi__single__data2">
                            {data?.data?.users && dataOld?.data?.users && calculateValues(Object.keys(data?.data?.users)?.length / data?.data?.leads?.Registration * 100, Object.keys(dataOld?.data?.users)?.length / dataOld?.data?.leads?.Registration * 100)}

                        </p>
                    </div>
                    <div className="component__dashboard__content__kpi__single">
                        <p className="component__dashboard__content__kpi__single__head">
                            Unique sales
                        </p>
                        <p className="component__dashboard__content__kpi__single__data">
                            {data.data?.users ? (() => {
                                let tmp = 0;
                                Object.keys(data.data?.users).map(user => {
                                    tmp += data.data?.users[user][0]
                                })
                                return tmp
                            })() : 0} €
                        </p>
                        <p className="component__dashboard__content__kpi__single__data2">
                            {data?.data?.users && dataOld?.data?.users && (() => {
                                let salesOld = 0;
                                let sales = 0;
                                Object.keys(data.data?.users).map(user => {
                                    sales += data.data?.users[user]?.[0]
                                })
                                Object.keys(dataOld.data?.users).map(user => {
                                    salesOld += dataOld.data?.users[user]?.[0]
                                })
                                return calculateValues(sales, salesOld)
                            })()}
                        </p>
                    </div>
                    <div className="component__dashboard__content__kpi__single">
                        <p className="component__dashboard__content__kpi__single__head">
                            Tail sales
                        </p>
                        <p className="component__dashboard__content__kpi__single__data">
                            {data.data?.users ? (() => {
                                let tmp = 0;
                                Object.keys(data.data?.users).map(user => {
                                    if (data.data?.users[user].length > 1) {
                                        data.data?.users[user].map((item, index) => {
                                            if (index > 0) {
                                                tmp += item;
                                            }
                                        })
                                    }
                                })
                                return tmp.toFixed(2)
                            })() : 0} €
                        </p>
                        <p className="component__dashboard__content__kpi__single__data2">
                            {data?.data?.users && dataOld?.data?.users && (() => {
                                let salesOld = 0;
                                let sales = 0;
                                Object.keys(data.data?.users).map(user => {
                                    if (data.data?.users[user].length > 1) {
                                        data.data?.users[user].map((item, index) => {
                                            if (index > 0) {
                                                sales += item;
                                            }
                                        })
                                    }
                                })
                                Object.keys(dataOld.data?.users).map(user => {
                                    if (dataOld.data?.users[user].length > 1) {
                                        dataOld.data?.users[user].map((item, index) => {
                                            if (index > 0) {
                                                salesOld += item;
                                            }
                                        })
                                    }
                                })
                                return calculateValues(sales, salesOld)
                            })()}
                        </p>
                    </div>
                    <div className="component__dashboard__content__kpi__single">
                        <p className="component__dashboard__content__kpi__single__head">
                            Total sales
                        </p>
                        <p className="component__dashboard__content__kpi__single__data">
                            {data.data?.users ? (() => {
                                let tmp = 0;
                                Object.keys(data.data?.users).map(user => {
                                    data.data?.users[user].map((item, index) => {
                                        tmp += item;
                                    })
                                })
                                return tmp.toFixed(2)
                            })() : 0} €
                        </p>
                        <p className="component__dashboard__content__kpi__single__data2">
                            {data?.data?.users && dataOld?.data?.users && (() => {
                                let salesOld = 0;
                                let sales = 0;
                                Object.keys(data.data?.users).map(user => {
                                    data.data?.users[user].map((item, index) => {
                                        sales += item;
                                    })
                                })
                                Object.keys(dataOld.data?.users).map(user => {
                                    dataOld.data?.users[user].map((item, index) => {
                                        salesOld += item;
                                    })
                                })
                                return calculateValues(sales, salesOld)
                            })()}
                        </p>
                    </div>
                    <div className="component__dashboard__content__kpi__single">
                        <p className="component__dashboard__content__kpi__single__head">
                            Expenses
                        </p>
                        <p className="component__dashboard__content__kpi__single__data">
                            {data.data?.expenses ? (() => {
                                let tmp = 0;
                                Object.keys(data.data?.expenses).map(com => {
                                    tmp += data.data?.expenses[com];
                                })
                                return tmp.toFixed(2)
                            })() : 0} €
                        </p>
                        <p className="component__dashboard__content__kpi__single__data2">
                            {data?.data?.expenses && dataOld?.data?.expenses && (() => {
                                let salesOld = 0;
                                let sales = 0;
                                Object.keys(data.data?.expenses).map(com => {
                                    sales += data.data?.expenses[com];
                                })
                                Object.keys(dataOld.data?.expenses).map(com => {
                                    salesOld += dataOld.data?.expenses[com];
                                })
                                return calculateValues(sales, salesOld)
                            })()}
                        </p>
                    </div>
                    <div className="component__dashboard__content__kpi__single">
                        <p className="component__dashboard__content__kpi__single__head">
                            Profit
                        </p>
                        <p className="component__dashboard__content__kpi__single__data">
                        {(data.data?.expenses && data.data.users) ? (() => {
                                let tmp = 0;
                                Object.keys(data.data?.expenses).map(com => {
                                    tmp += data.data?.expenses[com];
                                })
                                let tmp2 = 0;
                                Object.keys(data.data?.users).map(user => {
                                    data.data?.users[user].map((item, index) => {
                                        tmp2 += item;
                                    })
                                })
                                return (tmp2 - tmp).toFixed(2)
                            })() : 0} €
                        </p>
                        <p className="component__dashboard__content__kpi__single__data2">
                            {data?.data?.expenses && dataOld?.data?.expenses && (() => {
                                let salesOld = 0;
                                let sales = 0;
                                Object.keys(data.data?.expenses).map(com => {
                                    sales += data.data?.expenses[com];
                                })
                                Object.keys(dataOld.data?.expenses).map(com => {
                                    salesOld += dataOld.data?.expenses[com];
                                })
                                let salesOld1 = 0;
                                let sales1 = 0;
                                Object.keys(data.data?.users).map(user => {
                                    data.data?.users[user].map((item, index) => {
                                        sales1 += item;
                                    })
                                })
                                Object.keys(dataOld.data?.users).map(user => {
                                    dataOld.data?.users[user].map((item, index) => {
                                        salesOld1 += item;
                                    })
                                })
                                return calculateValues(sales1-sales, salesOld1-salesOld)
                            })()}
                        </p>
                    </div>

                </div>
                <div className="component__dashboard__content__charts" style={{ gridTemplateColumns: "1fr 1fr 2fr" }}>
                    <div>
                        <p>Sales/Expenses</p>
                        {data && data.data?.users && <Pie data={{
                            labels: [(() => {
                                let sales = 0;
                                Object.keys(data.data?.users).map(user => {
                                    data.data?.users[user].map((item, index) => {
                                        sales += item;
                                    })
                                })
                                return `Sales € ${sales.toFixed(2)}`
                            })(), (() => {
                                let sales = 0;
                                Object.keys(data.data?.expenses).map(com => {
                                    sales += data.data?.expenses[com];
                                })
                                return `Expenses € ${sales.toFixed(2)}`
                            })()],
                            datasets: [{
                                data: [(() => {
                                    let sales = 0;
                                    Object.keys(data.data?.users).map(user => {
                                        data.data?.users[user].map((item, index) => {
                                            sales += item;
                                        })
                                    })
                                    return sales.toFixed(2)
                                })(), (() => {
                                    let sales = 0;
                                    Object.keys(data.data?.expenses).map(com => {
                                        sales += data.data?.expenses[com];
                                    })
                                    return sales.toFixed(2)
                                })()],
                                backgroundColor: [colors[0], colors[1]]
                            }]
                        }} />}
                    </div>
                    <div>
                        <p>Packages</p>
                        {data && data.data?.leads && <Pie data={{
                            labels: (() => {
                                let sales = [];
                                Object.keys(data.data?.users).map(user => {
                                    data.data?.users[user].map((item, index) => {
                                        if (!sales.includes(item))
                                            sales.push(item);
                                    })
                                })
                                return sales.sort((a, b) => a - b)
                            })(),
                            datasets: [{
                                data: (() => {
                                    let sorted = {};
                                    Object.keys(data.data?.users).map(user => {
                                        data.data?.users[user].map((item, index) => {
                                            if (!sorted[item]) {
                                                sorted[item] = 0;
                                            }
                                            sorted[item] += 1;

                                        })

                                    })
                                    let final = Object.keys(sorted).map(item => {
                                        return sorted[item]
                                    })
                                    return final;
                                })(),
                                backgroundColor: colors
                            }]
                        }} />}
                    </div>
                    <div>
                        <p>Leads by day</p>
                        {data && data.data?.leads && <Line data={{
                            responsive: true,
                            labels: Object.keys(data.data?.leadsByDate).map(date => {
                                return date;

                            }),
                            datasets: [{
                                label: "Clicks",
                                data: Object.keys(data.data?.leadsByDate).map(date => {
                                    return data.data?.leadsByDate[date].leads.View ? data.data?.leadsByDate[date].leads.View : 0;
                                }),
                                borderColor: colors[0],
                                backgroundColor: colors[0],
                            }, {
                                label: "SOI",
                                data: Object.keys(data.data?.leadsByDate).map(date => {
                                    return data.data?.leadsByDate[date].leads.Registration ? data.data?.leadsByDate[date].leads.Registration : 0;
                                }),
                                borderColor: colors[1],
                                backgroundColor: colors[1],
                            }, {
                                label: "DOI",
                                data: Object.keys(data.data?.leadsByDate).map(date => {
                                    return data.data?.leadsByDate[date].leads.Verification ? data.data?.leadsByDate[date].leads.Verification : 0;
                                }),
                                borderColor: colors[3],
                                backgroundColor: colors[3],
                            }, {
                                label: "RevShare",
                                data: Object.keys(data.data?.leadsByDate).map(date => {
                                    return data.data?.leadsByDate[date].leads.Purchase ? data.data?.leadsByDate[date].leads.Purchase : 0;
                                }),
                                borderColor: colors[4],
                                backgroundColor: colors[4],
                            }]
                        }} />}
                    </div>
                </div>
                <div className="component__dashboard__content__charts" style={{ gridTemplateColumns: "2fr 1fr 1fr" }}>
                    <div>
                        <p>Purchases by day</p>
                        {data && data.data?.leads && <Line data={{
                            responsive: true,
                            labels: Object.keys(data.data?.leadsByDate).map(date => {
                                return date;

                            }),
                            datasets: [{
                                label: "Purchased (€)",
                                data: Object.keys(data.data?.leadsByDate).map(date => {
                                    let tmp = data.data?.leadsByDate[date].purchaces
                                    return tmp ? Number(tmp) : 0;
                                }),
                                borderColor: colors[0],
                                backgroundColor: colors[0],
                            }]
                        }} />}
                    </div>
                    <div>
                        <p>Number of sales per country</p>
                        {data && data.data?.countries && <Pie data={{
                            labels: Object.keys(data.data.countries).map(item => {
                                return item
                            }),
                            datasets: [{
                                data: Object.keys(data.data.countries).map(item => {
                                    return data.data.countries[item].packages
                                }),
                                backgroundColor: colors
                            }]
                        }} />}
                    </div>
                    <div>
                        <p>Total value of sales per country</p>
                        {data && data.data?.countries && <Pie data={{
                            labels: Object.keys(data.data.countries).map(item => {
                                return `${item} (€)`
                            }),
                            datasets: [{
                                data: Object.keys(data.data.countries).map(item => {
                                    return data.data.countries[item].purchaseTotal
                                }),
                                backgroundColor: colors
                            }]
                        }} />}
                    </div>
                </div>

            </> : <Spinner />}
        </div>





    </div>
};



export default AdvertiserDashboard;