import React from "react";
import "./index.scss";
import moment from "moment";

import { useSelector } from "react-redux";
import axios from "axios";
import * as backendModule from "../../../modules/backendModule";
import FilterPanel from "../../../components/customComponents/FilterPanel";
import useDefer from "../../../modules/hooks/useDefer";
import useOnScreen from "../../../modules/hooks/useOnScreen";
import animateModule from "../../../modules/animateModule";
import { useNavigate } from "react-router-dom";
import PublisherDashboardDomain from "./domain";
import PublisherDashboardGeneral from "./general";
import PublisherDashboardDate from "./date";
import PublisherDashboardCountry from "./country";
import PublisherDashboardMarketing from "./marketing";
import UserStatistic from "./users";
import PublisherDashboardLanding from "./landing";



const PublisherDashboard = () => {
    const [data, setData] = React.useState();
    const [filters, setFilters] = React.useState([]);
    const [customFilters, setCustomFilters]=React.useState([]);
    const [finalFilters, setFinalFilters]=React.useState([]);
    const [filters2, setFilters2] = React.useState([]);
    const [spinner, setSpinner] = React.useState(false)
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [activeTab, setactiveTab] = React.useState('general');
    const [pageFilters, setPageFilters] = React.useState("today");
    const [dateModal, setDateModal] = React.useState(false);
    const curDefer = useDefer();
    const curOnScreen = useOnScreen();

    let customDateStart = React.useRef('')
    let customDateEnd = React.useRef('');

    const curUserSelector = useSelector(state => state?.userData?.userData?.UserInfo);


    const calculateFilters = () => {
        let out = [];
        let out2 = [];

        switch (pageFilters) {
            case "all":
                break;
            case "today":
                out.push({ name: "createdAt", op: "deq", value: moment().toDate().getTime() });
                out2.push({ name: "createdAt", op: "deq", value: moment().add(-1, "days").toDate().getTime() });
                break;
            case "yesterday":
                out.push({ name: "createdAt", op: "deq", value: moment().add(-1, "days").toDate().getTime() });
                out2.push({ name: "createdAt", op: "deq", value: moment().add(-2, "days").toDate().getTime() });
                break;
            case "week":
                out.push({ name: "createdAt", op: "dgeq", value: moment().add(-7, "days").startOf("day").toDate().getTime() });
                out2.push({ name: "createdAt", op: "dgeq", value: moment().add(-14, "days").startOf("day").toDate().getTime() });
                out2.push({ name: "createdAt", op: "dleq", value: moment().add(-7, "days").startOf("day").toDate().getTime() });
                break;
            case "month":
                out.push({ name: "createdAt", op: "dgeq", value: moment().startOf('month').toDate().getTime() });
                out.push({ name: "createdAt", op: "dleq", value: moment().endOf('month').toDate().getTime() });
                out2.push({ name: "createdAt", op: "dgeq", value: moment().add(-1, "month").startOf('month').toDate().getTime() });
                out2.push({ name: "createdAt", op: "dleq", value: moment().add(-1, "month").endOf('month').toDate().getTime() });
                break;
            case "lastmonth":
                out.push({ name: "createdAt", op: "dgeq", value: moment().add(-1, "month").startOf('month').toDate().getTime() });
                out.push({ name: "createdAt", op: "dleq", value: moment().add(-1, "month").endOf('month').toDate().getTime() });
                out2.push({ name: "createdAt", op: "dgeq", value: moment().add(-2, "month").startOf('month').toDate().getTime() });
                out2.push({ name: "createdAt", op: "dleq", value: moment().add(-2, "month").endOf('month').toDate().getTime() });
                break;
            default: break;
        }


        setFilters(out);
        setFilters2(out2);
        return out;
    };

    React.useEffect(() => {
        calculateFilters(pageFilters)
    }, [pageFilters]);

    React.useEffect(() => {
        setFinalFilters([...filters, ...customFilters])
    }, [filters, customFilters]);






    return <div className="component__dashboard">
        <div className="component__dashboard__buttons">
            <p onClick={() => {
                setactiveTab("general");
            }} className={`component__dashboard__buttons__single ${activeTab === 'general' && "component__dashboard__buttons__single--active"}`}>General Statistic</p>
            <p onClick={() => {
                setactiveTab("day");
            }} className={`component__dashboard__buttons__single ${activeTab === 'day' && "component__dashboard__buttons__single--active"}`}>Statistic by day</p>
            <p onClick={() => {
                setactiveTab("domain");
            }} className={`component__dashboard__buttons__single ${activeTab === 'domain' && "component__dashboard__buttons__single--active"}`}>Statistic by domain </p>
            {/* <p onClick={() => {
                setactiveTab("user");
            }} className={`component__dashboard__buttons__single ${activeTab === 'user' && "component__dashboard__buttons__single--active"}`}>Statistic by user </p> */}
            <p onClick={() => {
                setactiveTab("country");
            }} className={`component__dashboard__buttons__single ${activeTab === 'country' && "component__dashboard__buttons__single--active"}`}>Statistic by country</p>
            <p onClick={() => {
                setactiveTab("marketing");
            }} className={`component__dashboard__buttons__single ${activeTab === 'marketing' && "component__dashboard__buttons__single--active"}`}>Statistic by marketing type</p>
            <p onClick={() => {
                setactiveTab("usersStats");
            }} className={`component__dashboard__buttons__single ${activeTab === 'usersStats' && "component__dashboard__buttons__single--active"}`}>Users Statistic</p>
            <p onClick={() => {
                setactiveTab("landing");
            }} className={`component__dashboard__buttons__single ${activeTab === 'landing' && "component__dashboard__buttons__single--active"}`}>Statistic by landing</p>

        </div>
        <div className="component__dashboard__buttons">
            <p onClick={() => {
                setPageFilters("today");
            }} className={`component__dashboard__buttons__single ${pageFilters === 'today' && "component__dashboard__buttons__single--active"}`}>Today </p>
            <p onClick={() => {
                setPageFilters("yesterday");
            }} className={`component__dashboard__buttons__single ${pageFilters === 'yesterday' && "component__dashboard__buttons__single--active"}`}>Yesterday </p>
            <p onClick={() => {
                setPageFilters("week");
            }} className={`component__dashboard__buttons__single ${pageFilters === 'week' && "component__dashboard__buttons__single--active"}`}>7 days</p>
            <p onClick={() => {
                setPageFilters("month");
            }} className={`component__dashboard__buttons__single ${pageFilters === 'month' && "component__dashboard__buttons__single--active"}`}>This Month</p>
            <p onClick={() => {
                setPageFilters("lastmonth");
            }} className={`component__dashboard__buttons__single ${pageFilters === 'lastmonth' && "component__dashboard__buttons__single--active"}`}>Last Month</p>


        </div>
        <div className="component__dashboard__head" style={{
            gridTemplateColumns: curUserSelector?.Flags?.isAdmin ? null : "1fr 400px"
        }}>
            <FilterPanel
                accent="#dfdfdf"
                theme="light"
                filterCB={fi => setCustomFilters(fi)}
                filters={[
                    { name: "ID", friendlyName: "ID", type: "string" },
                    { name: "OfferID", friendlyName: "Offer ID", type: "string" },
                    { name: "TakenOfferID", friendlyName: "Taken offer ID", type: "string" },
                    { name: "AdvertiserID", friendlyName: "Advertiser ID", type: "string" },
                    { name: "Name", friendlyName: "Name", type: "string" },
                    { name: "Type", friendlyName: "Type", type: "string" },

                    { name: "createdAt", friendlyName: "Date created", type: "date" },
                    { name: "updatedAt", friendlyName: "Date updated", type: "date" }
                ]} />

        </div>

        {activeTab === 'domain' && <PublisherDashboardDomain filters={finalFilters} />}
        {activeTab === 'general' && <PublisherDashboardGeneral filters={finalFilters} filters2={filters2} />}
        {activeTab === 'day' && <PublisherDashboardDate filters={finalFilters} />}
        {activeTab === 'country' && <PublisherDashboardCountry filters={finalFilters} />}
        {activeTab === 'marketing' && <PublisherDashboardMarketing filters={finalFilters} />}
        {activeTab === 'usersStats' && <UserStatistic filters={finalFilters} />}
        {activeTab === 'landing' && <PublisherDashboardLanding filters={finalFilters} />}



    </div>
};



export default PublisherDashboard;