import React from "react";
import "./index.scss";

const Checkbox = props => {
    const [checked, setChecked] = React.useState(props.checked ?? props.defaultValue ?? false);

    React.useEffect(() => {
        if (props.checked === null || props.checked === undefined) return;
        if (checked !== props.checked) setChecked(props.checked);
    }, [props.checked]);

    React.useEffect(() => {
        if (props.onChange) props.onChange(checked);
    }, [checked]);

    return <div className={`component__checkbox ${checked ? "component__checkbox--active" : ""} ${props.className ?? ""}`} onClick={e => {
        e.stopPropagation();
        setChecked(c => !c);

        if (props.onClick) props.onClick(e);
    }}>
        <img src="/images/icon_checked.svg" />
    </div>
};

export default Checkbox;