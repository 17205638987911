import React from "react";
import "./index.scss";
import axios from "axios";

import { useNavigate, useLocation } from "react-router-dom";
import animateModule from "../../modules/animateModule";
import { checkMail } from "../../modules/miscModule";
import * as backendModule from "../../modules/backendModule";
import { countries } from "../../modules/countryModule";
import Dropdown from "../../components/customComponents/Dropdown";
import * as timestampActions from "../../actions/timeStampAction";

import { useDispatch } from "react-redux";




const Register = () => {
    const curNavigate = useNavigate();
    const curLocation = useLocation();
    let userDataDispatch = useDispatch();

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    const [spinner, setSpinner] = React.useState(false);
    const [selectedCountry, setSelectedCountry] = React.useState();
    const [error, setError] = React.useState();
    const [focusedInput, setFocusedInput] = React.useState();

    const usernameRef = React.useRef();
    const emailRef = React.useRef();
    const passwordRef = React.useRef();
    const confirmPasswordRef=React.useRef();
    const firstNameRef = React.useRef();
    const lastNameRef = React.useRef();
    const zipRef = React.useRef();
    const experienceRef = React.useRef();

    const whatsappRef = React.useRef();
    const skypeRef = React.useRef();
    const telegramRef = React.useRef();

    const userLogin = (e) => {
        e.preventDefault()

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/auth/login`,
            data: {
                username: usernameRef.current.value,
                password: passwordRef.current.value,
            },
            ...backendModule.axiosConfig
        }).then((e) => {
            if (e.data.status === 'ok') {
                userDataDispatch(timestampActions.updateTimestamp());
                animateNavigate('/dashboard')
            } else {

            }
        }).catch((e) => {

        });
    }
    const saveData = (e) => {
        setError();
        let data = {
            username: usernameRef.current.value,
            password: passwordRef.current.value,
            email: emailRef.current.value,

            FirstName: firstNameRef.current.value,
            LastName: lastNameRef.current.value,
            Country: selectedCountry,
            ZIP: zipRef.current.value,
            Experience: experienceRef.current.value,

            ContactWhatsapp: whatsappRef.current.value,
            ContactSkype: skypeRef.current.value,
            ContactTelegram: telegramRef.current.value
        };

        if (!data.username) return setError("Username is required");
        if (data.username.length < 8 || data.username.length > 64) return setError("Usernaem must be between 8 and 64 characters");
        if (!data.password) return setError("Password is required");
        if (data.password!==confirmPasswordRef.current.value) return setError("Both fields for password must match")
        if (data.password.length < 8 || data.password.length > 64) return setError("Password must be between 8 and 64 characters");

        if (!data.email) return setError("Email is required");
        if (!checkMail(data.email)) return setError("Email is invalid");
        if (!data.FirstName) return setError("First name is required");
        if (!data.LastName) return setError("Last name is required");
        if (!data.Country) return setError("Country is required");
        if (!data.ZIP) return setError("ZIP is required");
        if (data.Experience === null || data.Experience === undefined) return setError("Experience is required");

        setSpinner(true)
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/createNewUser`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                userLogin(e)
            } else {
                setError('Please check all your info and try again');
            }
        }).catch((e) => {
            setError('Something went wrong please try again later')
        }).finally(() => { setSpinner(false) })
    };

    return <div className="route__register" style={{ backgroundImage: `url("/images/group_1_bg.png")` }}>
        <div className="route__register__left">
            <div className="route__register__left__form">
                <div className="route__register__left__form__logo">
                    <img src="/images/logoSmall.svg" />
                    <p>CupidAffiliate</p>
                </div>
                <div className="route__register__left__form__header">Signup</div>
                <div className="route__register__left__form__input">
                    <div>
                        <p className="route__register__left__form__label" >Username</p>
                        <input ref={usernameRef} type='text' placeholder="Username" />
                    </div>
                    <div>
                        <p className="route__register__left__form__label">Email</p>
                        <input ref={emailRef} type="text" placeholder="Email" />
                    </div>
                    <div>
                        <p className="route__register__left__form__label">Password</p>
                        <input ref={passwordRef} type="password" placeholder="Password" />
                    </div>
                    <div>
                        <p className="route__register__left__form__label">Confirm Password</p>
                        <input ref={confirmPasswordRef} type="password" placeholder="Confirm password" />
                    </div>
                    <div>
                        <p className="route__register__left__form__label">First name</p>
                        <input ref={firstNameRef} type="text" placeholder="First name" />
                    </div>
                    <div>
                        <p className="route__register__left__form__label">Last name</p>
                        <input ref={lastNameRef} type="text" placeholder="Last name" />
                    </div>
                    <div>
                        <p className="route__register__left__form__label">Country</p>
                        <Dropdown theme="light"
                            accent="#6C5DD3" data={countries.map(item => {
                                return {
                                    name: item.name,
                                    value: item.code
                                }
                            })} onChange={val => {
                                setSelectedCountry(val.value)
                            }}
                            style={{ height: "45px", marginTop: "15px" }}
                            selected={(() => {
                                return countries.indexOf(countries.find(c => c.code === selectedCountry));
                            })()}
                        />
                    </div>
                    <div>
                        <p className="route__register__left__form__label">ZIP</p>
                        <input ref={zipRef} type="text" placeholder="ZIP" />
                    </div>
                    <div>
                        <p className="route__register__left__form__label">Experience (in years)</p>
                        <input ref={experienceRef} type="number" placeholder="Experience" />
                    </div>
                    <div>
                        <p className="route__register__left__form__label">Contact <span style={{ color: focusedInput === 1 ? "#fff" : null }}>Whatsapp</span> / <span style={{ color: focusedInput === 2 ? "#fff" : null }}>Skype</span> / <span style={{ color: focusedInput === 3 ? "#fff" : null }}>Telegram</span></p>
                        <div className="route__register__left__form__split">
                            <input ref={whatsappRef} type="text" placeholder="Whatsapp" onFocus={() => setFocusedInput(1)} onBlur={() => setFocusedInput(f => f === 1 ? null : f)} />
                            <input ref={skypeRef} type="text" placeholder="Skype" onFocus={() => setFocusedInput(2)} onBlur={() => setFocusedInput(f => f === 2 ? null : f)} />
                            <input ref={telegramRef} type="text" placeholder="Telegram" onFocus={() => setFocusedInput(3)} onBlur={() => setFocusedInput(f => f === 3 ? null : f)} />
                        </div>
                    </div>
                </div>
                <p className="route__register__left__form__error">{error}</p>
                <button className="route__register__left__form__button" onClick={(e) => { saveData(e) }}>SIGNUP</button>
                <p className="route__register__left__form__haveAccount">Already have an account an account?
                    <spam onClick={() => animateNavigate("/login")} > Sign in </spam></p>
                <div className="route__register__left__form__buttons">
                    <p>Privacy Policy</p>
                    <p>Terms of Service</p>
                </div>
            </div>
        </div>
        <div className="route__register__right"></div>
    </div>
};

export default Register;