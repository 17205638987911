import React from "react";
import "./index.scss";

import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment/moment";
import * as backendModule from "../../../modules/backendModule";
import { animateBox } from "../../../modules/componentAnimation";
import Spinner from "../../../components/customComponents/Spinner";
import FilterPanel from "../../../components/customComponents/FilterPanel";
import { FilteredCustomTable } from "../../../components/customComponents/Table";
import Dropdown from "../../../components/customComponents/Dropdown";
import useDefer from "../../../modules/hooks/useDefer";
import useOnScreen from "../../../modules/hooks/useOnScreen";
import FilterBySearch from "../../../filters/FilterBySearch";
import animateModule from "../../../modules/animateModule";
import { useNavigate } from "react-router-dom";

const AdminLeads = () => {
    const [data, setData] = React.useState();
    const [filters, setFilters] = React.useState([]);
    const [spinner, setSpinner] = React.useState(false)
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [search, setSearch] = React.useState("");
    const curDefer = useDefer();
    const curOnScreen = useOnScreen();

    const curUserSelector = useSelector(state => state?.userData?.userData?.UserInfo);

    const curNavigate = useNavigate();
    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".root__content"));

    let leadStatusList = {
        "0": "Click",
        "50": "Retrying",
        "97": "CPL",
        "98": "SOI",
        "99": "DOI",
        "100": "RevShare"
    }

    const getData = () => {
        const filtersTmp = [...filters];

        if (search) {
            filtersTmp.push({
                or: [
                    { name: "Name", op: "like", value: search },
                    { name: "Type", op: "like", value: search },
                    { name: "ID", op: "like", value: search },
                ]
            })
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/leads/getAllLeads`,
            data: {
                filters: filtersTmp,
                limit: 10
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 10) {
                    setCanPaginate(true);
                } else {
                    setCanPaginate(false);
                };
            };
            setData(res.data);
        }).catch(() => {
            setData(backendModule.axiosConfig);
        });
    };

    const continueData = () => {
        setCanPaginate(false);
        if (!data) return;
        if (data.status !== "ok") return;
        if (data.data.length === 0) return;

        const filtersTmp = [...filters];

        if (search) {
            filtersTmp.push({
                or: [
                    { name: "Name", op: "like", value: search },
                    { name: "Type", op: "like", value: search },
                    { name: "ID", op: "like", value: search },
                ]
            })
        };

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/leads/getAllLeads`,
            data: {
                limit: 10,
                offset: data.data.length,
                filters: filtersTmp
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
                if (res.data.data.length === 10) {
                    setCanPaginate(true);
                } else {
                    setCanPaginate(false);
                };
            };
        }).catch(() => null).finally(() => {
            setSpinner(false);
        });
    };

    const returnFilteredIP = ip => {
        if (String(ip).startsWith("::ffff:")) {
            let tmp = String(ip);
            return tmp.substring(7, tmp.length);
        };
        return ip;
    };

    React.useEffect(() => {
        getData();
    }, [search, filters])

    React.useEffect(() => {
        if (curOnScreen.isIntersecting && canPaginate) {
            try {
                curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
            } catch { };

            curDefer(() => {
                continueData();
            }, 500);
        };
    }, [canPaginate, curOnScreen.isIntersecting]);


    return <div className="component__leads">
        <div className="component__leads__head" style={{
            gridTemplateColumns: curUserSelector?.Flags?.isAdmin ? null : "1fr 400px"
        }}>
            {/* return {
        "0": "View",
        "50": "Retrying",
        "97": "CPL",
        "98": "SOI",
        "99": "DOI",
        "100": "RevShare"
    }; */}
            <FilterPanel

                accent="#dfdfdf"
                theme="light"
                filterCB={fi => setFilters(fi)}
                filters={[
                    { name: "ID", friendlyName: "ID", type: "string" },
                    { name: "OfferID", friendlyName: "Offer ID", type: "string" },
                    {
                        name: "Status", friendlyName: "Status", type: "custom", varType: "string", data: [
                            {text:"View" , value: 0},
                            {text:"Retrying" , value: 50},
                            {text:"CPL" , value: 97},
                            {text:"SOI" , value: 98},
                            {text:"DOI" , value: 99},
                            {text:"RevShare" , value: 100}
                        ]
                    },
                    { name: "TakenOfferID", friendlyName: "Taken offer ID", type: "string" },
                    { name: "AdvertiserID", friendlyName: "Advertiser ID", type: "string" },
                    { name: "Name", friendlyName: "Name", type: "string" },
                    { name: "Type", friendlyName: "Type", type: "string" },

                    { name: "createdAt", friendlyName: "Date created", type: "date" },
                    { name: "updatedAt", friendlyName: "Date updated", type: "date" }
                ]} />
            <FilterBySearch onChange={e => setSearch(e)} />

        </div>
        <div className="component__leads__content" style={{ gridTemplateColumns: "1fr" }} >
            <div className="component__leads__content__left">
                <FilteredCustomTable
                    theme="light"
                    accent="#6C5DD3"
                    headers={["ID", "Status", "Platform earned", "IP Adress", ""]}
                    customColumns={["1fr", "1fr", "1fr", "1fr"]}
                    data={(() => {
                        let out = [];

                        if (!data) return [[{ keyID: "noData-spinner", type: "spinner", color: "white" }]];
                        if (data.status === "ok") {
                            for (let item of data.data) {
                                console.log(item)
                                out.push({
                                    columns: [
                                        { keyID: item.ID, type: "text", text: item.ID },
                                        // { keyID: item.ID, type: "text", text: item.Name },
                                        { keyID: item.ID, type: "custom", data: < p className="component__leads__status"><span style={{ backgroundColor: item.Status <= 50 ? "gray" : item.Status === 100 ? "green" : item.Status === 99 ? "blue" : "yellow" }}></span> {leadStatusList[item.Status]}</p> },
                                        { keyID: item.ID, type: "text", text: item.PlatformEarnedAmount ?? "-" },
                                        { keyID: item.ID, type: "text", text: item.NetworkData?.IP ? returnFilteredIP(item.NetworkData.IP) : "-" },
                                        {
                                            keyID: item.ID, type: "custom", data: <div className="component__leads__moreInfo" onClick={(e) => {
                                                animateBox(e, <LeadMoreInfo data={item} />)
                                            }}>
                                                More info
                                            </div>
                                        },

                                    ]
                                })
                            };
                        } else {
                            out.push([{ keyID: "noData-error", type: "text", text: "Error while fetching leads!", color: "#f96666" }]);
                        };

                        if (spinner) out.push([{ keyID: "pagination-spinner", type: "spinner", color: "white" }]);
                        if (out.length === 0) out.push([{ keyID: "noData-noData", type: "text", text: "Nothing to show for now." }]);

                        return out;
                    })()}
                />
            </div>
            {canPaginate && <div ref={curOnScreen.measureRef} className="modals__ordersModal__wrap__content__left__paginate"></div>}

        </div>

    </div>
};


const LeadMoreInfo = (props) => {
    return <div className="modal__leadMoreInfo">
        <div className="modal__leadMoreInfo__container">
            <div className="modal__leadMoreInfo__container__close">
                <img src="/images/closeIcon.png" onClick={() => { props.onClose() }} />
            </div>
            <div className="modal__leadMoreInfo__container__info">
                <div>
                    <p>Advertiser ID</p>
                    <p>{props.data?.AdvertiserID}</p>
                </div>
                <div>
                    <p>Domain ID</p>
                    <p>{props.data?.DomainID}</p>
                </div>
                <div>
                    <p>Offer ID</p>
                    <p>{props.data?.OfferID}</p>
                </div>
                <div>
                    <p>Taken Offer ID</p>
                    <p>{props.data?.TakenOfferID}</p>
                </div>
                <div>
                    <p>Platform earned</p>
                    <p>{props.data?.PlatformEarnedAmount}</p>
                </div>
                <div>
                    <p>Publisher earned</p>
                    <p>{props.data?.EarnedAmount}</p>
                </div>
                <div>
                    <p>Advertiser paid amount</p>
                    <p>{props.data?.AdvertiserPaidAmount}</p>
                </div>
                <div>
                    <p>Paid</p>
                    <p>{props.data?.PublisherPaid ? <span className="modal__leadMoreInfo__container__info__paidYes">Yes</span> : <span className="modal__leadMoreInfo__container__info__paidNo">No</span>}</p>
                </div>
                <div>
                    <p>Lead status</p>
                    <p>{props.data?.Status}</p>
                </div>
                <div>
                    <p>Client Country</p>
                    <p>{props.data?.LocationData?.countryCode ? <><img src={`/images/flags/${props.data?.LocationData?.countryCode?.toLowerCase()}.png`} /> {props.data?.LocationData?.countryCode}</> : "?"}</p>
                </div>
                <div>
                    <p>Created at</p>
                    <p>{moment(props.data?.createdAt).format("DD/MM/YYYY hh:mm:ss")}</p>
                </div>
                <div>
                    <p>Updated at</p>
                    <p>{moment(props.data?.updatedAt).format("DD/MM/YYYY hh:mm:ss")}</p>
                </div>
            </div>
        </div>
    </div>
}



export default AdminLeads;