import React from "react";
import "./index.scss";

import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment/moment";
import * as backendModule from "../../../modules/backendModule";
import { animateBox } from "../../../modules/componentAnimation";
import Spinner from "../../../components/customComponents/Spinner";
import FilterPanel from "../../../components/customComponents/FilterPanel";
import { FilteredCustomTable } from "../../../components/customComponents/Table";
import useDefer from "../../../modules/hooks/useDefer";
import useOnScreen from "../../../modules/hooks/useOnScreen";
import FilterBySearch from "../../../filters/FilterBySearch";

const Domains = () => {
    const [data, setData] = React.useState();
    const [filters, setFilters] = React.useState([]);
    const [spinner, setSpinner] = React.useState(false)
    const [selectedItem, setSelectedItem] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [search, setSearch] = React.useState("");
    const curNavigate = useNavigate();
    const curLocation = useLocation();
    const curDefer = useDefer();
    const curOnScreen = useOnScreen();

    const curUserSelector = useSelector(state => state?.userData?.userData?.UserInfo);
    
    const getData = () => {
        const filtersTmp = [...filters];

        if (search) {
            filtersTmp.push({
                or: [
                    { name: "Domain", op: "like", value: search }
                ]
            })
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/domains/getAllDomains`,
            data: {
                filters: filtersTmp,
                limit: 20
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) {
                    setCanPaginate(true);
                } else {
                    setCanPaginate(false);
                };
            };
            setData(res.data);
        }).catch(() => {
            setData(backendModule.axiosConfig);
        });
    };

    const continueData = () => {
        
        setCanPaginate(false);
        if (!data) return;
        if (data.status !== "ok") return;
        if (data.data.length === 0) return;

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/domains/getAllDomains`,
            data: {
                limit: 20,
                offset: data.data.length
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
                if (res.data.data.length === 20) {
                    setCanPaginate(true);
                } else {
                    setCanPaginate(false);
                };
            };
        }).catch(() => null).finally(() => {
            setSpinner(false);
        });
    };
    React.useEffect(() => {
        getData();
    }, [search, filters])

    React.useEffect(() => {
        if (curOnScreen.isIntersecting && canPaginate) {
            try {
                curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
            } catch { };

            curDefer(() => {
                continueData();
            }, 500);
        };
    }, [canPaginate, curOnScreen.isIntersecting]);


    return <div className="component__domains">
        <div className="component__domains__head" style={{
            gridTemplateColumns: curUserSelector?.Flags?.isAdmin ? null : "1fr 400px"
        }}>
        <FilterPanel
                    
                    accent="#dfdfdf"
                    theme="light"
                    filterCB={fi => setFilters(fi)}
                    filters={[
                        { name: "ID", friendlyName: "ID", type: "string" },
                        { name: "Domain", friendlyName: "Domain", type: "string" },
                        { name: "createdAt", friendlyName: "Date created", type: "date" },
                        { name: "updatedAt", friendlyName: "Date updated", type: "date" }
                    ]} />
            <FilterBySearch onChange={e => setSearch(e)} />
            {curUserSelector?.Flags?.isAdmin && <button onClick={() => { animateBox({ currentTarget: document.querySelector('.component__domains__content') }, <SelectedDomain item={null} allDomains={data?.data} update={getData} />) }}>Add domain</button>}
        </div>
        <div className="component__domains__content" style={{ gridTemplateColumns: "1fr" }} >
            <div className="component__domains__content__left">
                <FilteredCustomTable
                    theme="light"
                    accent="#6C5DD3"
                    headers={["ID", "Domain", "Created at", ""]}
                    customColumns={["360px", "1fr", "1fr", curUserSelector?.Flags?.isAdmin ? "200px" : "0px"]}
                    style={{width: "100%"}}
                    data={(() => {
                        let out = [];

                        if (!data) return [[{ keyID: "noData-spinner", type: "spinner", color: "white" }]];
                        if (data.status === "ok") {
                            for (let item of data.data) {
                                out.push({
                                    columns: [
                                        { keyID: item.ID, type: "text", text: item.ID },
                                        { keyID: item.ID, type: "text", text: item.Domain },
                                        { keyID: item.ID, type: "text", text: moment(item.createdAt).format("DD-MM-YYYY hh:mm") },
                                         {
                                            keyID: item.ID, type: "custom", data: <div className="component__currencies__buttons">
                                            {curUserSelector?.Flags?.isAdmin && <p className="component__currencies__edit" onClick={(e) => {
                                                e.stopPropagation();
                                                animateBox({ currentTarget: document.querySelector('.component__domains__content') }, <SelectedDomain item={item} allCurrencies={data?.data} update={getData} edit={true} />)
                                            }}>Edit</p>}
                                            {curUserSelector?.Flags?.isAdmin && <p className="component__currencies__delete" onClick={(e) => {
                                                e.stopPropagation();
                                                animateBox({ currentTarget: document.querySelector('.component__domains__content') }, <DeleteDomain item={item} id={item.ID} update={getData} />)
                                            }}>Delete</p>}
                                        </div>
                                        }
                                    ]
                                })
                            };
                        } else {
                            out.push([{ keyID: "noData-error", type: "text", text: "Error while fetching orders!", color: "#f96666" }]);
                        };

                        if (spinner) out.push([{ keyID: "pagination-spinner", type: "spinner", color: "white" }]);
                        if (out.length === 0) out.push([{ keyID: "noData-noData", type: "text", text: "Nothing to show for now." }]);

                        return out;
                    })()}
                />
            </div>
            {canPaginate && <div ref={curOnScreen.measureRef} className="modals__ordersModal__wrap__content__left__paginate"></div>}

        </div>

    </div>
};


const SelectedDomain = (props) => {
    const [isBase, setIsBase] = React.useState();
    const [base, setBase] = React.useState();
    const [item, setItem] = React.useState(props.item);
    const [spinner, setSpinner] = React.useState(false);
    const nameRef = React.useRef();
    const rateRef = React.useRef();
    const [error, setError] = React.useState();

    React.useEffect(() => {
        setItem(props.item);
    }, [props.item])

    const saveData = () => {
        let path = '/addDomain'
        if (props.edit) path = '/editDomain'
        setSpinner(true)
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/domains${path}`,
            data: {
                ID: props.item?.ID,
                Domain: nameRef.current.value
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.update();
                props.onClose();
            } else {
                setError('Please check all your info and try again');
            }
        }).catch(() => {
            setError('Something went wrong please try again later')
        }).finally(() => { setSpinner(false) })
    };

    return <div className="modals__domainModal">
        <div className="modals__domainModal__container">
            <div className="modals__domainModal__close">
                <img src="/images/closeIcon.png" onClick={() => { props.onClose() }} />
            </div>
            <p className="modals__domainModal__head">{props.edit ? "Edit Domain" : "Add new Domain"}</p>
            <p className="modals__domainModal__label" >Domain</p>
            <input defaultValue={item?.Domain} ref={nameRef} type='text' placeholder="Enter your domain here" />
            
            <p className="modals__domainModal__error">{error}</p>
            <div className="modals__domainModal__buttons">
                {spinner ? <Spinner color={"##00A3FF"} /> : <button onClick={() => { saveData() }}>Save</button>}
            </div>
        </div>


    </div>
}


const DeleteDomain = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [error, setError] = React.useState();
    const deleteData = () => {
        setSpinner(true)
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/domains/removeDomain`,
            data: {
                ID: props.id
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.update();
                props.onClose();
            } else {
                setError('Please check all your info and try again');
            }
        }).catch(() => {
            setError('Something went wrong please try again later')
        }).finally(() => { setSpinner(false) })
    };
    return <div className="modals__deleteDomain">
        <div className="modals__deleteDomain__container">
            <p>You are about to delete <span style={{color: "#6c5dd3"}}>{props.item?.Domain}</span></p>
            <p>Domain deletion is permanent.</p>
            <p>Are you sure you want to continue?</p>
            <p className="modals__domainModal__error">{error}</p>
            <div className="modals__deleteDomain__container__buttons">
                {spinner ? <Spinner color="white" style={{width: "43px", height: "43px"}} /> : <>
                    <button onClick={() => [deleteData()]}>Yes</button>
                    <button onClick={() => { props.onClose() }}>No</button>
                </>}
            </div>
        </div>
    </div>
}

export default Domains;