import React from "react";
import "./index.scss";

import axios from "axios";

import * as backendModule from "../../../modules/backendModule";
import { UseSelector, useSelector } from "react-redux/es/hooks/useSelector";
import { animateBox } from "../../../modules/componentAnimation";
import { checkMail } from "../../../modules/miscModule";
import { countries } from "../../../modules/countryModule";

import Spinner from "../../../components/customComponents/Spinner";
import FilterPanel from "../../../components/customComponents/FilterPanel";
import { FilteredCustomTable } from "../../../components/customComponents/Table";
import Dropdown from "../../../components/customComponents/Dropdown";
import FilterBySearch from "../../../filters/FilterBySearch";
import RadioButton from "../../../components/customComponents/RadioButton";

import useOnScreen from "../../../modules/hooks/useOnScreen";
import useDefer from "../../../modules/hooks/useDefer";

const AdminUsers = () => {
    const [data, setData] = React.useState();
    const [filters, setFilters] = React.useState([]);
    const [spinner, setSpinner] = React.useState(false)
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [search, setSearch] = React.useState("");
    const curDefer = useDefer();
    const curOnScreen = useOnScreen();

    const curUserSelector = useSelector(state => state?.userData?.userData?.UserInfo);

    const getData = () => {
        const filtersTmp = [...filters];

        if (search) {
            filtersTmp.push({
                or: [
                    { name: "Username", op: "like", value: search },
                    { name: "Email", op: "like", value: search },
                    { name: "FirstName", op: "like", value: search },
                    { name: "LastName", op: "like", value: search },
                    { name: "ContactTelegram", op: "like", value: search },
                    { name: "ContactSkype", op: "like", value: search },
                    { name: "ContactWhatsapp", op: "like", value: search },
                    { name: "ID", op: "like", value: search },
                ]
            })
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsers`,
            data: {
                filters: filtersTmp,
                limit: null
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) {
                    setCanPaginate(true);
                } else {
                    setCanPaginate(false);
                };
            };
            setData(res.data);
        }).catch(() => {
            setData(backendModule.axiosConfig);
        });
    };

    const continueData = () => {
        setCanPaginate(false);
        if (!data) return;
        if (data.status !== "ok") return;
        if (data.data.length === 0) return;

        const filtersTmp = [...filters];

        if (search) {
            filtersTmp.push({
                or: [
                    { name: "Username", op: "like", value: search },
                    { name: "Email", op: "like", value: search },
                    { name: "FirstName", op: "like", value: search },
                    { name: "LastName", op: "like", value: search },
                    { name: "ContactTelegram", op: "like", value: search },
                    { name: "ContactSkype", op: "like", value: search },
                    { name: "ContactWhatsapp", op: "like", value: search },
                    { name: "ID", op: "like", value: search },
                ]
            })
        };

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsers`,
            data: {
                limit: 20,
                offset: data.data.length,
                filters: filtersTmp
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
                if (res.data.data.length === 20) {
                    setCanPaginate(true);
                } else {
                    setCanPaginate(false);
                };
            };
        }).catch(() => null).finally(() => {
            setSpinner(false);
        });
    };
    React.useEffect(() => {
        getData();
    }, [search, filters])

    React.useEffect(() => {
        if (curOnScreen.isIntersecting && canPaginate) {
            try {
                curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
            } catch { };

            curDefer(() => {
                continueData();
            }, 500);
        };
    }, [canPaginate, curOnScreen.isIntersecting]);

    return <div className="route__adminUsers">
        <div className="route__adminUsers__head" style={{
            gridTemplateColumns: curUserSelector?.Flags?.isAdmin ? null : "1fr 400px"
        }}>
            <FilterPanel

                accent="#dfdfdf"
                theme="light"
                filterCB={fi => setFilters(fi)}
                filters={[
                    { name: "ID", friendlyName: "ID", type: "string" },
                    { name: "Username", friendlyName: "Username", type: "string" },
                    { name: "Email", friendlyName: "Email", type: "string" },
                    { name: "FirstName", friendlyName: "First name", type: "string" },
                    { name: "LastName", friendlyName: "Last name", type: "string" },
                    { name: "ZIP", friendlyName: "ZIP", type: "string" },
                    { name: "Experience", friendlyName: "Experience", type: "number" },
                    { name: "ContactWhatsapp", friendlyName: "Contact - Whatsapp", type: "string" },
                    { name: "ContactSkype", friendlyName: "Contact - Skype", type: "string" },
                    { name: "ContactTelegram", friendlyName: "Contact - Telegram", type: "string" },
                    { name: "createdAt", friendlyName: "Date created", type: "date" },
                    { name: "updatedAt", friendlyName: "Date updated", type: "date" }
                ]} />
            <FilterBySearch onChange={e => setSearch(e)} />
            {curUserSelector?.Flags?.isAdmin && <button onClick={() => { animateBox({ currentTarget: document.querySelector('.route__adminUsers__content') }, <SelectedUser item={null} update={getData} />) }}>Add User</button>}
        </div>
        <div className="route__adminUsers__content" style={{ gridTemplateColumns: "1fr" }} >
            <div className="route__adminUsers__content__left">
                <FilteredCustomTable
                    theme="light"
                    accent="#6C5DD3"
                    headers={["ID", "Username", "Email", ""]}
                    customColumns={["360px", "1fr", "1fr", (curUserSelector?.Flags?.isAdmin || curUserSelector?.Flags?.isManager) ? "500px" : "0px"]}
                    style={{ width: "100%" }}
                    data={(() => {
                        let out = [];

                        if (!data) return [[{ keyID: "noData-spinner", type: "spinner", color: "white" }]];
                        if (data.status === "ok") {
                            for (let item of data.data) {

                                out.push({
                                    columns: [
                                        { keyID: item.ID, type: "text", text: item.ID },
                                        { keyID: item.ID, type: "text", text: item.Username },
                                        { keyID: item.ID, type: "text", text: item.Email },
                                        {
                                            keyID: item.ID, type: "custom", data: <div className="route__adminUsers__buttons">
                                                {((curUserSelector?.Flags?.isAdmin || curUserSelector?.Flags?.isManager) && !item.Flags?.isVerified) && <>
                                                    <p className="route__adminUsers__verify" onClick={(e) => {
                                                        e.stopPropagation();
                                                        animateBox({ currentTarget: document.querySelector('.route__adminUsers__content') }, <VerifyUser item={item} id={item.ID} update={getData} />)
                                                    }}>Verify</p>
                                                </>}
                                                {curUserSelector?.Flags?.isAdmin && <>
                                                    <p className="route__adminUsers__delete" onClick={(e) => {
                                                        e.stopPropagation();
                                                        animateBox({ currentTarget: document.querySelector('.route__adminUsers__content') }, <AssignDomain item={item} update={getData} edit={true} />)
                                                    }}>Assign domain</p>
                                                    <p className="route__adminUsers__edit" onClick={(e) => {
                                                        e.stopPropagation();
                                                        animateBox({ currentTarget: document.querySelector('.route__adminUsers__content') }, <SelectedUser item={item} update={getData} edit={true} />)
                                                    }}>Edit</p>
                                                    <p className="route__adminUsers__delete" onClick={(e) => {
                                                        e.stopPropagation();
                                                        animateBox({ currentTarget: document.querySelector('.route__adminUsers__content') }, <DeleteUser item={item} id={item.ID} update={getData} />)
                                                    }}>Delete</p>
                                                </>}
                                            </div>
                                        },
                                        {
                                            key: item.ID, type: "groupNewline", group: [
                                                {
                                                    keyID: item.ID, type: "custom", data: <div className="route__adminUsers__content__left__pills">
                                                        {Object.keys(item.Flags).map(key => <p>{key}: <span>{item.Flags[key] ? "Yes" : "No"}</span></p>)}
                                                    </div>
                                                }
                                            ]
                                        }
                                    ]
                                })
                            };
                        } else {
                            out.push([{ keyID: "noData-error", type: "text", text: "Error while fetching orders!", color: "#f96666" }]);
                        };

                        if (spinner) out.push([{ keyID: "pagination-spinner", type: "spinner", color: "white" }]);
                        if (out.length === 0) out.push([{ keyID: "noData-noData", type: "text", text: "Nothing to show for now." }]);

                        return out;
                    })()}
                />
            </div>
            {canPaginate && <div ref={curOnScreen.measureRef} className="modals__ordersModal__wrap__content__left__paginate"></div>}

        </div>

    </div>
};

const SelectedUser = (props) => {
    const [item, setItem] = React.useState(props.item);
    const [spinner, setSpinner] = React.useState(false);
    const [selectedCountry, setSelectedCountry] = React.useState();
    const [flags, setFlags] = React.useState({});
    const [error, setError] = React.useState();
    const [focusedInput, setFocusedInput] = React.useState();

    const usernameRef = React.useRef();
    const emailRef = React.useRef();
    const passwordRef = React.useRef();
    const firstNameRef = React.useRef();
    const lastNameRef = React.useRef();
    const zipRef = React.useRef();
    const experienceRef = React.useRef();

    const whatsappRef = React.useRef();
    const skypeRef = React.useRef();
    const telegramRef = React.useRef();

    React.useEffect(() => {
        if (!props.item?.ID) return;

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getUserByID`,
            data: {
                ID: props.item.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "error") return props.onClose();

            setItem(res.data.data);
            setFlags(res.data.data.Flags ?? {});
            setSelectedCountry(res.data.data.Country);
        }).finally(() => {
            setSpinner(false);
        });
    }, [props.item])

    const saveData = () => {
        setError();
        let data = {
            username: usernameRef.current.value,
            password: passwordRef.current.value,
            email: emailRef.current.value,
            flags: flags,

            FirstName: firstNameRef.current.value,
            LastName: lastNameRef.current.value,
            Country: selectedCountry,
            ZIP: zipRef.current.value,
            Experience: experienceRef.current.value,

            ContactWhatsapp: whatsappRef.current.value,
            ContactSkype: skypeRef.current.value,
            ContactTelegram: telegramRef.current.value
        };

        if (!props.edit) {
            if (!data.username) return setError("Username is required");
            if (data.username.length < 8 || data.username.length > 64) return setError("Usernaem must be between 8 and 64 characters");
            if (!data.password) return setError("Password is required");
            if (data.password.length < 8 || data.password.length > 64) return setError("Password must be between 8 and 64 characters");
        } else {
            if (data.password) {
                if (data.password.length < 8 || data.password.length > 64) return setError("Password must be between 8 and 64 characters");
            };
            data["id"] = item.ID;
            data["newPassword"] = data.password;
            data["newEmail"] = data.email;
            data["newFlags"] = data.flags;
        };
        if (!data.email) return setError("Email is required");
        if (!checkMail(data.email)) return setError("Email is invalid");
        if (!data.FirstName) return setError("First name is required");
        if (!data.LastName) return setError("Last name is required");
        if (!data.Country) return setError("Country is required");
        if (!data.ZIP) return setError("ZIP is required");
        if (data.Experience === null || data.Experience === undefined) return setError("Experience is required");

        let path = '/createNewUser';
        if (props.edit) path = '/editUser';
        setSpinner(true)
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users${path}`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.update();
                props.onClose();
            } else {
                setError('Please check all your info and try again');
            }
        }).catch(() => {
            setError('Something went wrong please try again later')
        }).finally(() => { setSpinner(false) })
    };

    return <div className="modals__userModal">
        <div className="modals__userModal__container">
            <div className="modals__userModal__close">
                <img src="/images/closeIcon.png" onClick={() => { props.onClose() }} />
            </div>
            <p className="modals__userModal__head">{props.edit ? "Edit user" : "Add new user"}</p>
            <p className="modals__userModal__label" >Username</p>
            <input ref={usernameRef} defaultValue={item?.Username} type='text' placeholder="Username" readOnly={props.edit} />
            <p className="modals__userModal__label">Email</p>
            <input ref={emailRef} defaultValue={item?.Email} type="text" placeholder="Email" />
            <p className="modals__userModal__label">Password</p>
            <input ref={passwordRef} type="password" placeholder="Password" />
            <p className="modals__userModal__label">First name</p>
            <input ref={firstNameRef} defaultValue={item?.FirstName} type="text" placeholder="First name" />
            <p className="modals__userModal__label">Last name</p>
            <input ref={lastNameRef} defaultValue={item?.LastName} type="text" placeholder="Last name" />
            <p className="modals__userModal__label">Country</p>
            <Dropdown theme="light"
                accent="#6C5DD3" data={countries.map(item => {
                    return {
                        name: item.name,
                        value: item.code
                    }
                })} onChange={val => {
                    setSelectedCountry(val.value)
                }}
                style={{ height: "45px", marginTop: "15px" }}
                selected={(() => {
                    return countries.indexOf(countries.find(c => c.code === selectedCountry));
                })()}
            />
            <p className="modals__userModal__label">ZIP</p>
            <input ref={zipRef} defaultValue={item?.ZIP} type="text" placeholder="ZIP" />
            <p className="modals__userModal__label">Experience (in years)</p>
            <input ref={experienceRef} defaultValue={item?.Experience} type="number" placeholder="Experience" />
            <p className="modals__userModal__label">Contact <span style={{ color: focusedInput === 1 ? "#fff" : null }}>Whatsapp</span> / <span style={{ color: focusedInput === 2 ? "#fff" : null }}>Skype</span> / <span style={{ color: focusedInput === 3 ? "#fff" : null }}>Telegram</span></p>
            <div className="modals__userModal__split">
                <input ref={whatsappRef} defaultValue={item?.ContactWhatsapp} type="text" placeholder="Whatsapp" onFocus={() => setFocusedInput(1)} onBlur={() => setFocusedInput(f => f === 1 ? null : f)} />
                <input ref={skypeRef} defaultValue={item?.ContactSkype} type="text" placeholder="Skype" onFocus={() => setFocusedInput(2)} onBlur={() => setFocusedInput(f => f === 2 ? null : f)} />
                <input ref={telegramRef} defaultValue={item?.ContactTelegram} type="text" placeholder="Telegram" onFocus={() => setFocusedInput(3)} onBlur={() => setFocusedInput(f => f === 3 ? null : f)} />
            </div>

            <div className="modals__userModal__inline">
                <RadioButton checked={!!flags["isAdmin"]} onChange={e => setFlags(f => {
                    return {
                        ...f,
                        isAdmin: e
                    }
                })} />
                <span>Is admin</span>
            </div>
            <div className="modals__userModal__inline">
                <RadioButton checked={!!flags["isManager"]} onChange={e => setFlags(f => {
                    return {
                        ...f,
                        isManager: e
                    }
                })} />
                <span>Is manager</span>
            </div>
            <div className="modals__userModal__inline">
                <RadioButton checked={!!flags["isVerified"]} onChange={e => setFlags(f => {
                    return {
                        ...f,
                        isVerified: e
                    }
                })} />
                <span>Is verified</span>
            </div>
            <div className="modals__userModal__inline">
                <RadioButton checked={!!flags["isBanned"]} onChange={e => setFlags(f => {
                    return {
                        ...f,
                        isBanned: e
                    }
                })} />
                <span>Is banned</span>
            </div>

            <p className="modals__userModal__error">{error}</p>
            <div className="modals__userModal__buttons">
                {spinner ? <Spinner color={"#00A3FF"} /> : <button onClick={() => { saveData() }}>Save</button>}
            </div>
        </div>


    </div>
}

const DeleteUser = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [error, setError] = React.useState();
    const deleteData = () => {
        setSpinner(true)
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/removeUser`,
            data: {
                ID: props.id
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.update();
                props.onClose();
            } else {
                setError('There was an error while removing the user');
            }
        }).catch(() => {
            setError('Something went wrong please try again later')
        }).finally(() => { setSpinner(false) })
    };
    return <div className="modals__deleteUser">
        <div className="modals__deleteUser__container">
            <p>You are about to delete <span style={{ color: "#6c5dd3" }}>{props.item?.Username}</span></p>
            <p>User deletion is permanent.</p>
            <p>Are you sure you want to continue?</p>
            <p className="modals__domainModal__error">{error}</p>
            <div className="modals__deleteUser__container__buttons">
                {spinner ? <Spinner color="white" style={{ width: "43px", height: "43px" }} /> : <>
                    <button onClick={() => [deleteData()]}>Yes</button>
                    <button onClick={() => { props.onClose() }}>No</button>
                </>}
            </div>
        </div>
    </div>
}

const VerifyUser = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [error, setError] = React.useState();
    const deleteData = () => {
        setSpinner(true)
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/verifyUser`,
            data: {
                ID: props.id
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.update();
                props.onClose();
            } else {
                setError('There was an error while verifying the user');
            }
        }).catch(() => {
            setError('Something went wrong please try again later')
        }).finally(() => { setSpinner(false) })
    };
    return <div className="modals__deleteUser">
        <div className="modals__deleteUser__container">
            <p>You are about to verify <span style={{ color: "#6c5dd3" }}>{props.item?.Username}</span></p>
            <p>By verifying the user you grant them rights to run offers and see the platform.</p>
            <p>Are you sure you want to continue?</p>
            <p className="modals__domainModal__error">{error}</p>
            <div className="modals__deleteUser__container__buttons">
                {spinner ? <Spinner color="white" style={{ width: "43px", height: "43px" }} /> : <>
                    <button onClick={() => [deleteData()]}>Yes</button>
                    <button onClick={() => { props.onClose() }}>No</button>
                </>}
            </div>
        </div>
    </div>
}


const AssignDomain = (props) => {
    const curUserSelector = useSelector(state => state?.userData?.userData?.UserInfo);
    const [data, setData] = React.useState();
    const [allDomains, setAllDomains] = React.useState();
    const [selectedDomain, setSelectedDomain] = React.useState();


    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/domains/getAllUserDomains`,
            data: {
                UserID: props.item.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.axiosConfig);
        });
    };

    const getDomains = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/domains/getAllDomains`,
            data: {
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setAllDomains(res.data);
        }).catch(() => {
            setAllDomains(backendModule.axiosConfig);
        });
    };

    React.useEffect(() => {
        getData();
        getDomains();
    }, [])

    const assign = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/domains/assignDomain`,
            data: {
                UserID: props.item.ID,
                DomainID: selectedDomain
            },
            ...backendModule.axiosConfig
        }).then(res => {
            getData();
        }).catch(() => {
        });
    };
    const assignRandom = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/domains/assignDomain`,
            data: {
                UserID: props.item.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            getData();
        }).catch(() => {
        });
    };
    const unassign = (id) => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/domains/unassignDomain`,
            data: {
                UserID: props.item.ID,
                DomainID: id
            },
            ...backendModule.axiosConfig
        }).then(res => {
            getData();
        }).catch(() => {
        });
    };

    return <div className="modals__userModal">
        <div className="modals__userModal__container">
            <div className="modals__userModal__close">
                <img src="/images/closeIcon.png" onClick={() => { props.onClose() }} />
            </div>
            {curUserSelector?.Flags?.isAdmin ? <div>
                <p className="modals__userModal__container__text">Select domain</p>
                {allDomains?.status === 'ok' && <Dropdown theme="light"
                    accent="#6C5DD3" data={allDomains.data.map(item => {
                        return {
                            name: item.Domain,
                            value: item.ID
                        }
                    })} onChange={val => {
                        setSelectedDomain(val.value)
                    }}
                    style={{ height: "45px", marginTop: "15px" }}

                />}
                {selectedDomain && <div className="modals__userModal__container__button">
                    <button onClick={() => { assign() }} >
                        Assing domain to user
                    </button> </div>}
            </div> : <div className="modals__userModal__container__button">
                <button onClick={() => { assignRandom() }} >
                    Assing domain to user
                </button>
            </div>}
            <p className="modals__userModal__container__text">Total domains ({data?.data?.length ? data?.data?.length : 0})</p>
            <div className="modals__userModal__domains">
                {data?.status === 'ok' && data.data.map(item => {
                    return <p className="modals__userModal__container__list">{item.Domain} <span onClick={()=>{unassign(item.ID)}}>Unassign</span> </p>
                })}
            </div>
        </div>
    </div>
}

export default AdminUsers;