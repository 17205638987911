import React from "react";
import "./index.scss";

import { useSelector } from "react-redux";
import axios from "axios";
import * as backendModule from "../../../modules/backendModule";
import { animateBox } from "../../../modules/componentAnimation";
import Spinner from "../../../components/customComponents/Spinner";
import FilterPanel from "../../../components/customComponents/FilterPanel";
import { FilteredCustomTable } from "../../../components/customComponents/Table";
import Dropdown from "../../../components/customComponents/Dropdown";
import useDefer from "../../../modules/hooks/useDefer";
import useOnScreen from "../../../modules/hooks/useOnScreen";
import FilterBySearch from "../../../filters/FilterBySearch";
import animateModule from "../../../modules/animateModule";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";

const ProcessedPayments = () => {
    const [data, setData] = React.useState();
    const [filters, setFilters] = React.useState([]);
    const [spinner, setSpinner] = React.useState(false)
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [search, setSearch] = React.useState("");
    const curDefer = useDefer();
    const curOnScreen = useOnScreen();

    const curNavigate = useNavigate();
    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".root__content"));

    const curUserSelector = useSelector(state => state?.userData?.userData?.UserInfo);

    const getData = () => {
        const filtersTmp = [...filters];

        if (search) {
            filtersTmp.push({
                or: [
                    { name: "TotalPaidOut", op: "like", value: search }
                ]
            })
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/payments/getAllPayments`,
            data: {
                filters: filtersTmp,
                limit: null
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) {
                    setCanPaginate(true);
                } else {
                    setCanPaginate(false);
                };
            };
            setData(res.data);
        }).catch(() => {
            setData(backendModule.axiosConfig);
        });
    };

    const continueData = () => {
        setCanPaginate(false);
        if (!data) return;
        if (data.status !== "ok") return;
        if (data.data.length === 0) return;

        const filtersTmp = [...filters];

        if (search) {
            filtersTmp.push({
                or: [
                    { name: "TotalPaidOut", op: "like", value: search }
                ]
            })
        };

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/payments/getAllPayments`,
            data: {
                limit: 20,
                offset: data.data.length,
                filters: filtersTmp
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
                if (res.data.data.length === 20) {
                    setCanPaginate(true);
                } else {
                    setCanPaginate(false);
                };
            };
        }).catch(() => null).finally(() => {
            setSpinner(false);
        });
    };
    React.useEffect(() => {
        getData();
    }, [search, filters])

    React.useEffect(() => {
        if (curOnScreen.isIntersecting && canPaginate) {
            try {
                curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
            } catch { };

            curDefer(() => {
                continueData();
            }, 500);
        };
    }, [canPaginate, curOnScreen.isIntersecting]);

    return <div className="route__processedPayments">
        <div className="route__processedPayments__head" style={{
            gridTemplateColumns: curUserSelector?.Flags?.isAdmin ? null : "1fr 400px"
        }}>
            <FilterPanel

                accent="#dfdfdf"
                theme="light"
                filterCB={fi => setFilters(fi)}
                filters={[
                    { name: "ID", friendlyName: "ID", type: "string" },
                    { name: "UserID", friendlyName: "User ID", type: "string" },
                    { name: "TotalPaidOut", friendlyName: "Paid SUM", type: "string" },
                ]} />
            <FilterBySearch onChange={e => setSearch(e)} />
            <button onClick={() => { animateNavigate("/admin-payments") }}>Unprocessed </button>
        </div>
        <div className="route__processedPayments__content" style={{ gridTemplateColumns: "1fr" }} >
            <div className="route__processedPayments__content__left">
                <FilteredCustomTable
                    theme="light"
                    accent="#6C5DD3"
                    headers={["User ID", "Username", "Total paid out", "created at"]}
                    customColumns={["1fr", "1fr", "1fr", "1fr"]}
                    style={{ width: "100%" }}
                    data={(() => {
                        let out = [];

                        if (!data) return [[{ keyID: "noData-spinner", type: "spinner", color: "white" }]];
                        if (data.status === "ok") {
                            for (let item of data.data) {

                                out.push({
                                    columns: [
                                        { keyID: item.ID, type: "text", text: item.UserID },
                                        { keyID: item.ID, type: "text", text: item.Username },
                                        { keyID: item.ID, type: "text", text: `€ ${item.TotalPaidOut}` },
                                        { keyID: item.ID, type: "text", text: moment(item.createdAt).format("DD/MM/YYYY hh:mm") }
                                    ]
                                })
                            };
                        } else {
                            out.push([{ keyID: "noData-error", type: "text", text: "Error while fetching orders!", color: "#f96666" }]);
                        };

                        if (spinner) out.push([{ keyID: "pagination-spinner", type: "spinner", color: "white" }]);
                        if (out.length === 0) out.push([{ keyID: "noData-noData", type: "text", text: "Nothing to show for now." }]);

                        return out;
                    })()}
                />
            </div>
            {canPaginate && <div ref={curOnScreen.measureRef} className="modals__ordersModal__wrap__content__left__paginate"></div>}

        </div>

    </div>
};









export default ProcessedPayments;