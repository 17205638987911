import "./index.scss";

import { useNavigate, useLocation } from "react-router-dom";
import animateModule from "../../modules/animateModule";

const Header = () => {
    const curNavigate = useNavigate();
    const curLocation = useLocation();

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    const navigateToChat = () => {
        if (curLocation.pathname === "/") {
            try {
                document.querySelector(".route__landingPage__block7__wrapper").scrollIntoView({behavior: "smooth"});
            } catch {};
        } else {
            animateNavigate("/").then(() => {
                setTimeout(() => {
                    try {
                        document.querySelector(".route__landingPage__block7__wrapper").scrollIntoView({behavior: "smooth"});
                    } catch {};
                }, 100);
            });
        };
    };

    return <div className="component__header">
        <div className="component__header__wrapper">
            <div className="component__header__wrapper__left">
                <img src="./images/header_logo.svg" onClick={() => animateNavigate("/")}></img>
            </div>
            <div className="component__header__wrapper__right">
                {/* <p className="component__header__wrapper__right__item">affiliates</p> */}
                {/* <p className="component__header__wrapper__right__item">advertisers</p> */}
                {/* <p className="component__header__wrapper__right__item">about</p> */}
                {/* <p className="component__header__wrapper__right__item">events</p> */}
                {/* <p className="component__header__wrapper__right__item">blog</p> */}
                {/* <p className="component__header__wrapper__right__item">careers</p> */}
                <p className="component__header__wrapper__right__item header__item__contant" onClick={navigateToChat}>contact</p>
                <p className="component__header__wrapper__right__item header__item__login" onClick={() => animateNavigate("/login")}>log in</p>
                <div className="component__header__wrapper__right__btn header__item__signup" onClick={() => animateNavigate("/register")}>
                    <p>SIGN UP</p>
                </div>
            </div>
        </div>
    </div>
};

export default Header;