import React from "react";
import "./index.scss";

import { useSelector } from "react-redux";
import axios from "axios";
import * as backendModule from "../../../modules/backendModule";
import Spinner from "../../../components/customComponents/Spinner";
import { useNavigate } from "react-router-dom";

import { Chart, ArcElement } from 'chart.js'
import { CategoryScale } from 'chart.js'
import { registerables } from 'chart.js';
import moment from "moment/moment";

import { Pie, Bar } from 'react-chartjs-2';

const UserStatistic = (props) => {
    const [data, setData] = React.useState();
    const [filters, setFilters] = React.useState(props.filters);
    const [packages, setPackages] = React.useState([]);
    const curUserSelector = useSelector(state => state?.userData?.userData?.UserInfo);

    const curNavigate = useNavigate();


    const getData = () => {
        setData(null)
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/statistic/getStatisticForUsers`,
            data: {
                filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch((e) => {
            setData(backendModule.axiosConfig);
        });
    };

    React.useEffect(() => {
        getData()
    }, [filters]);


    React.useEffect(() => {
        setFilters(props.filters)
    }, [props.filters]);


    Chart.register(ArcElement);
    Chart.register(CategoryScale);
    Chart.register(...registerables);





    return <div className="component__dashboard__content">
        {data?.status==="ok" ? <>
            <div className="component__dashboard__content__row" style={{ gridTemplateColumns: "repeat(6, 1fr)" }}>
                <p>Username</p>
                <p>Last login</p>
                <p>Credits</p>
                <p>Messages send</p>
                <p>Number of purchases</p>
                <p>Spend amount</p>

            </div>
            {data.data.map((item, index) => {
                return <div className={`component__dashboard__content__row ${index % 2 === 0 && "component__dashboard__content__row-2"} `} style={{ gridTemplateColumns: "repeat(6, 1fr)" }}>
                    <p>{item?.Username}</p>
                    <p>{moment(item?.LastLogin).format("DD/MM/YYYY hh:mm")}</p>
                    <p>{item?.Credits}</p>
                    <p>{item?.MessagesSent}</p>
                    <p>{item?.TotalPurchases}</p>
                    <p>{item?.SpendAmount}</p>
                </div>
            })}

        </> : <Spinner align={"center"} color={"#6c5dd3"} />}

    </div>


};



export default UserStatistic;