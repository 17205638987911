import "./index.scss";
import { Provider } from "react-redux";


const ContentWrapper = (props) => {

    

    return <div className="component__contentWrapper" style={props.style}>
        {props.children}
    </div>
};

export default ContentWrapper;