import React from "react";
import "./index.scss";

import { useSelector } from "react-redux";
import axios from "axios";
import * as backendModule from "../../../../modules/backendModule";
import { animateBox } from "../../../../modules/componentAnimation";
import Dropdown from "../../../../components/customComponents/Dropdown";
import Spinner from "../../../../components/customComponents/Spinner";

const RotatingOffers = (props) => {
    const [data, setData] = React.useState();
    const [selectedOffers, setSelectedOffer] = React.useState();

    const getData = () => {


        axios({
            method: "POST",
            url: `${backendModule.backendURL}/takenoffers/getAllTakenOffersFrontend`,
            data: {
                MarketingType: "rotating",
                limit: 1000
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.axiosConfig);
        });
    };
    React.useEffect(() => {
        getData();
    }, [])

    const generateLink = (ID, elem) => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/takenOffers/generateOfferLink`,
            data: {
                ID: ID
            },
            ...backendModule.axiosConfig
        }).then((e) => {
            if (e.data.status === 'ok') {
                animateBox({ currentTarget: document.querySelector('.component__puboffers__type') }, <ShowURL URL={e.data.data} />)
            }
        }).catch((e) => {
        })
    }



    return <div className="component__puboffers__type">
        <div className="component__puboffers__type__add">
            <p onClick={(e) => { animateBox(e, <AddNew allOffers={props.allOffers} getData={getData} />, false) }}>Add new</p>
        </div>

        {data?.status === "ok" ? <div className="component__puboffers__type__list">
            <div className="component__puboffers__type__list__single">
                <p>ID</p>
                <p>Offer name</p>
                <p>Friendly name</p>
                <p></p>
            </div>
            {data.data.map((item, index) => {
                return <div className={`component__puboffers__type__list__single ${index % 2 === 0 && " component__puboffers__type__list__single--2"} `}>
                    <p>{item.ID}</p>
                    <p>{item.TakenOfferName}</p>
                    <p>{item.FriendlyName}</p>
                    <div><button onClick={(e) => {
                        generateLink(item.ID, e)
                    }} >Generate URL</button>
                        <button onClick={(e) => {
                            animateBox(e, <DeleteTakenOffer id={item.ID} update={getData} />)
                        }} >Delete</button>
                    </div>

                </div>
            })}

        </div> : <p className="component__puboffers__type__error">
            Failed to fetch offers!
        </p>}





    </div>
};

const ShowURL = (props) => {

    return <div className="component__takeOffer">
        <div className="component__takeOffer__container">
            <div className="component__takeOffer__close">
                <img src="/images/closeIcon.png" onClick={() => { props.onClose() }} />
            </div>
            <p style={{ color: "white", textAlign: "center" }}>{props.URL}</p>
            <div className="modal__createRotator__container__landings__button">
                <button onClick={() => { props.onClose() }}>
                    Close
                </button>
            </div>
        </div>

    </div>

}


const AddNew = (props) => {
    const [selectedOffer, setSelectedOffer] = React.useState();
    const [offerLandings, setOfferLadnings] = React.useState();
    const [selectedLandings, setSelectedLandings] = React.useState([]);
    const [domains, setDomains] = React.useState();
    const [selectedDomain, setSelectedDomain] = React.useState();
    const [spinner, setSpinner] = React.useState(false);

    const friendlyNameRef = React.useRef();

    const getLandings = () => {
        setOfferLadnings(null);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/websites/getAllWebsitesFrontend`,
            data: {
                OfferID: selectedOffer,
                WebsiteType: "Landing"
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setOfferLadnings(res.data)
        }).catch(e => {

        })
    }

    const getAllDomains = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/domains/getAllDomains`,
            ...backendModule.axiosConfig
        }).then(res => {
            setDomains(res.data)
        }).catch(e => {

        })
    }

    React.useEffect(() => {
        getAllDomains();
    }, []);

    React.useEffect(() => {
        getLandings();
    }, [selectedOffer]);

    const createLandingsArray = (land) => {
        if (selectedLandings.includes(land)) {
            setSelectedLandings(selectedLandings.filter(e => e !== land))
        } else {
            setSelectedLandings([...selectedLandings, land])
        }

    }


    return <div className="component__takeOffer">
        <div className="component__takeOffer__container">
            <div className="component__takeOffer__close">
                <img src="/images/closeIcon.png" onClick={() => { props.onClose() }} />
            </div>
            <div className="component__takeOffer__container__content">

                <p className="component__takeOffer__container__content__label">Friendly Name</p>
                <input placeholder="Friendly name" ref={friendlyNameRef} />
                <p className="component__takeOffer__container__content__label">Select offer</p>
                <Dropdown theme={"dark"} selected={0} selectText="Select offer" data={props?.allOffers?.data.map((item, index) => {
                    return {
                        name: item.URL+"-"+item.Type,
                        value: item.ID
                    }
                })} onChange={val => {
                    setSelectedOffer(val.value);
                }} />
                <p className="component__takeOffer__container__content__label">Select domain</p>
                {domains?.status && <Dropdown theme={"dark"} selected={0} selectText="Select offer" data={domains?.data.map((item, index) => {
                    return {
                        name: item.Domain,
                        value: item.ID
                    }
                })} onChange={val => {
                    setSelectedDomain(val.value);
                }} />}
                {offerLandings?.status === "ok" && <><p className="component__takeOffer__container__content__label">Select landings ({selectedLandings.length})
                    {(selectedLandings.length > 1 && selectedDomain && selectedOffer) && <button onClick={(e) => {
                        animateBox(e, <ProceedWithCreating
                            landings={selectedLandings} domain={selectedDomain} friendlyName={friendlyNameRef?.current?.value} offer={selectedOffer} parentClose={props.onClose} getData={props.getData} />, false)
                    }}
                    >Proceed</button>}
                </p>
                    <div className="component__takeOffer__container__content__landingsContainer">
                        {offerLandings.data.map(item => {
                            return <div className={`component__takeOffer__container__content__landingsContainer__image ${selectedLandings?.includes(item) &&
                                "component__takeOffer__container__content__landingsContainer__image--selected"}`}>
                                <p ><a href={item.URL} target="_blank">Link preview</a></p>
                                <img src={item.Images[0]} onClick={() => { createLandingsArray(item) }} />
                            </div>
                        })}
                    </div></>}

                {(selectedLandings.length > 1 && selectedDomain && selectedOffer) && <div style={{
                    position: "sticky",
                    bottom: "0",
                    width: "100%",
                    backgroundColor: "#242731",
                    padding: "20px",
                    margin: "20px 0"
                }} className="modal__createRotator__container__landings__button">
                    <button onClick={(e) => {
                        animateBox(e, <ProceedWithCreating
                            landings={selectedLandings} domain={selectedDomain} friendlyName={friendlyNameRef?.current?.value} offer={selectedOffer} parentClose={props.onClose} getData={props.getData} />, false)
                    }}>
                        Save
                    </button>
                </div>}
            </div>
        </div>

    </div>

}

const ProceedWithCreating = (props) => {
    const [landingsSum, setLandingsSum] = React.useState({});
    const [spinner, setSpinner] = React.useState(false);

    const calculateSelectedLandings = () => {
        let tmpA
        props.landings.forEach(element => {
            if (!landingsSum[element.ID]) {
                setLandingsSum({
                    ...landingsSum,
                    [element.ID]: 0
                })
            }
        });

    }

    React.useEffect(() => {
        if (!props.landings) return
        calculateSelectedLandings();
    }, [props.landings])


    const takeOffer = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/takenoffers/addTakenOffer`,
            data: {
                OfferID: props?.offer,
                DomainID: props.domain,
                LandingWebsiteID: landingsSum,
                PreLandingWebsiteID: null,
                FriendlyName: props?.friendlyName,
                MarketingType: "rotating"
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                props.getData();
                props.parentClose();
                props.onClose();
            }
        }).catch(() => { }).finally(() => {
            setSpinner(false)
        })
    }





    return <div className="modal__createRotator">
        <div className="modal__createRotator__container">
            <div className="component__takeOffer__close">
                <img src="/images/closeIcon.png" onClick={() => { props.onClose() }} />
            </div>
            <p className="modal__createRotator__container__head">Create ROTATOR</p>
            <div className="modal__createRotator__container__landings">
                <div className="modal__createRotator__container__landings__single">
                    <p>Preview</p>
                    <p>Name</p>
                    <p>URL</p>
                    <p>Height</p>
                </div>
                {props.landings && props.landings.map(landing => {
                    return <div className="modal__createRotator__container__landings__single">
                        <div className="modal__createRotator__container__landings__single__image">
                            <img src={landing.Images[0]} />
                        </div>
                        <div className="modal__createRotator__container__landings__single__image">
                            <p>{landing.WebsiteName} </p>
                        </div>
                        <div className="modal__createRotator__container__landings__single__image">
                            <p><a href={landing.URL} target="_blank">Link preview</a></p>
                        </div>
                        <div className="modal__createRotator__container__landings__single__image">
                            <p><input type="number" onChange={(event) => {
                                setLandingsSum({
                                    ...landingsSum,
                                    [landing.ID]: Number(event.target.value)
                                })
                            }} />%</p>
                        </div>

                    </div>
                })}
                <div className="modal__createRotator__container__landings__info">
                    <p>Total SUM of heights should be 100 %</p>
                    <p>Current SUM:</p>
                    <p>{(() => {
                        let tmp = 0;
                        for (let item of Object.keys(landingsSum)) {
                            tmp += landingsSum[item]
                        }
                        return <span style={{ color: tmp === 100 ? "green" : "red" }} >{tmp}%</span>
                    })()}</p>
                </div>
                <div className="modal__createRotator__container__landings__button">
                    {spinner ? <Spinner /> : <button onClick={() => { takeOffer() }}>
                        Save
                    </button>}
                </div>

            </div>

        </div>

    </div>
}

const DeleteTakenOffer = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [error, setError] = React.useState();
    const deleteData = () => {
        setSpinner(true)
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/takenOffers/removeTakenOffer`,
            data: {
                ID: props.id
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.update();
                props.onClose();
            } else {
                setError('There was an error while removing the offer');
            }
        }).catch(() => {
            setError('Something went wrong please try again later')
        }).finally(() => { setSpinner(false) })
    };
    return <div className="modals__deleteAdvertiser">
        <div className="modals__deleteAdvertiser__container">
            <p>You are about to delete this offer</p>
            <p>Deletion is permanent.</p>
            <p>Are you sure you want to continue?</p>
            <p className="modals__domainModal__error">{error}</p>
            <div className="modals__deleteAdvertiser__container__buttons">
                {spinner ? <Spinner color="white" style={{ width: "43px", height: "43px" }} /> : <>
                    <button onClick={() => [deleteData()]}>Yes</button>
                    <button onClick={() => { props.onClose() }}>No</button>
                </>}
            </div>
        </div>
    </div>
}







export default RotatingOffers;