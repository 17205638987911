import React from "react";
import "./index.scss";

import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import axios from "axios";

import * as backendModule from "../../modules/backendModule";
import { updateTimestamp } from "../../actions/timeStampAction";
import { animateBox } from "../../modules/componentAnimation";
import animateModule from "../../modules/animateModule";

import Spinner from "../customComponents/Spinner";

import { ReactComponent as ModalMyProfile_img } from "./images/header_usermodal_myProfile.svg";
import { ReactComponent as ModalChangePassword_img } from "./images/header_usermodal_changePassword.svg";
import { ReactComponent as ModalLogout_img } from "./images/header_usermodal_logout.svg";


const UserHeader = () => {
    const [userModalVisible, setUserModalVisible] = React.useState(false);
    const userDataSelector = useSelector(state => state?.userData?.userData ?? {});
    const curNavigate = useNavigate();
    const curLocation = useLocation();
    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__users__wrap"));
    const getHeaderName = () => {
        if (curLocation.pathname.startsWith("/dashboard")) return "Dashboard";
        if (curLocation.pathname.startsWith("/campaigns")) return "Campaigns";
        if (curLocation.pathname.startsWith("/optimize")) return "Optimize";
        if (curLocation.pathname.startsWith("/reports")) return "Reports";
        if (curLocation.pathname.startsWith("/creatives")) return "Creatives";
        if (curLocation.pathname.startsWith("/sites")) return "Sites";
        if (curLocation.pathname.startsWith("/heatmaps")) return "Heatmaps";
        if (curLocation.pathname.startsWith("/integrations")) return "Integrations";
        if (curLocation.pathname.startsWith("/smsMarketing")) return "SMS Marketing";
        if (curLocation.pathname.startsWith("/currencies")) return "Currencies";

        // Team leader
        if (curLocation.pathname.startsWith("/team-usergroups")) return "[Team] User groups";

        // Admin
        if (curLocation.pathname.startsWith("/admin-users")) return "[Admin] Users";
        if (curLocation.pathname.startsWith("/admin-advertisers")) return "[Admin] Advertisers";
        if (curLocation.pathname.startsWith("/admin-currencies")) return "[Admin] Currencies";
        if (curLocation.pathname.startsWith("/admin-domains")) return "[Admin] Domains";
        if (curLocation.pathname.startsWith("/admin-offers")) return "[Admin] Offers";
        if (curLocation.pathname.startsWith("/admin-leads")) return "[Admin] Leads";
        if (curLocation.pathname.startsWith("/admin-payments")) return "[Admin] Payments";
        if (curLocation.pathname.startsWith("/admin-processedpayments")) return "[Admin] Payments";
        if (curLocation.pathname.startsWith("/admin-dashboard")) return "[Admin] Dashboard";

        //publisher
        if (curLocation.pathname.startsWith("/publisher-offers")) return "Offers";
        if (curLocation.pathname.startsWith("/publisher-takenoffers")) return "Offers";
        if (curLocation.pathname.startsWith("/publisher-postback")) return "Postback";
        if (curLocation.pathname.startsWith("/publisher-leads")) return "Leads";
        if (curLocation.pathname.startsWith("/publisher-dashboard")) return "Dashboard";
        if (curLocation.pathname.startsWith("/publisher-team")) return "Team";

        //advertiser
        if (curLocation.pathname.startsWith("/advertiser-offers")) return "Offers";
        if (curLocation.pathname.startsWith("/advertiser-dashboard")) return "Dashboard";
        if (curLocation.pathname.startsWith("/advertiser-leads")) return "Leads";
    };




    return <div className="component__userHeader">
        <div className="component__userHeader__left">
            <p>{getHeaderName()}</p>
        </div>
        <div className="component__userHeader__right">

            <img src="/images/userIcon.png" onClick={(e) => {
                e.stopPropagation();
                if (!userModalVisible) setUserModalVisible(true);
            }} />
            <UserModal visible={userModalVisible} setVisible={setUserModalVisible} />
        </div>

    </div>
};

export default UserHeader;

const UserModal = (props) => {
    const [spinner, setSpinner] = React.useState(false);

    const userDataSelector = useSelector(state => state?.userData?.userData ?? {});
    const curNavigate = useNavigate();
    const curDispatch = useDispatch();
    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".root__content"));
    const performLogout = () => {
        setSpinner(true);

        axios({
            method: "GET",
            url: `${backendModule.backendURL}/auth/logout`,
            ...backendModule.axiosConfig
        }).then(() => null).catch(() => null).finally(() => {
            animateNavigate("/")
            setSpinner(false);
            curDispatch(updateTimestamp());
        });
    };

    // const performChangePassword = (e) => {
    //     setSpinner(true);
    //     animateBox(e, <ChangePassword onChange={() => setSpinner(false)} />)
    // };

    React.useEffect(() => {
        const handler = () => {
            if (props.visible) props.setVisible(false);
        };

        document.body.addEventListener("click", handler);

        return () => {
            document.removeEventListener("click", handler);
        };
    }, [props.visible]);

    return <div className={`component__userHeader__userModal ${props.visible ? "component__userHeader__userModal--active" : ""}`} onClick={e => {
        e.stopPropagation();
    }}>
        <div className={`component__userHeader__userModal__spinner ${spinner ? "component__userHeader__userModal__spinner--active" : ""}`}>
            <Spinner style={{ width: "64px", height: "64px" }} color="#6C5DD3" />
        </div>

        <div className="component__userHeader__userModal__first">
            <img src="/images/userIcon.png" />
            <p>{userDataSelector?.UserInfo?.Username}</p>
        </div>
        <div className="component__userHeader__userModal__first component__userHeader__userModal__first-manager">
            
            <div className="component__userHeader__right__manager">
                <p>{userDataSelector?.UserInfo?.ManagerInfo?.Name}{userDataSelector?.UserInfo?.ManagerInfo?.LastName}</p>
                <div>
                <small>Your manager</small>
                    <p> {userDataSelector?.UserInfo?.ManagerInfo?.Name} {userDataSelector?.UserInfo?.ManagerInfo?.LastName}</p>
                    <small>{userDataSelector?.UserInfo?.ManagerInfo?.Email}</small>
                </div>

            </div>
        </div>

        <div className="component__userHeader__userModal__button">
            <ModalMyProfile_img />
            <p>My profile</p>
        </div>
        <div className="component__userHeader__userModal__button" onClick={e => animateBox(e, <ChangePasswordModal />)} >
            <ModalChangePassword_img />
            <p>Change password</p>
        </div>
        <div className="component__userHeader__userModal__button" onClick={performLogout}>
            <ModalLogout_img />
            <p>Logout</p>
        </div>
    </div>
}

const ChangePasswordModal = (props) => {
    const [infoP, setInfoP] = React.useState({ text: "", errors: [] });
    const [spinner, setSpinner] = React.useState(false);

    const oldPassRef = React.useRef();
    const newPassRef = React.useRef();
    const confirmPassRef = React.useRef();

    const changePassword = () => {
        if (spinner) return;

        setInfoP({ text: "", errors: [] });

        let data = {
            oldPassword: oldPassRef.current.value,
            newPassword: newPassRef.current.value,
            confirmPassword: confirmPassRef.current.value
        };
        if (!data.oldPassword) return setInfoP({ errors: ["old-password"], text: "Old password is required" });
        if (!data.newPassword) return setInfoP({ errors: ["new-password"], text: "New password is required" });
        if (!data.confirmPassword) return setInfoP({ errors: ["confirm-password"], text: "Confirm new password is required" });
        if (data.newPassword.length < 8 || data.newPassword.length > 64) return setInfoP({ errors: ["new-password"], text: "New password must be between 8 and 64 characters" });
        if (data.newPassword !== data.confirmPassword) return setInfoP({ errors: ["new-password", "confirm-password"], text: "New passwords don't match" });

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/changePassword`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                return props.onClose();
            } else {
                setInfoP({ errors: [], text: "There was an error while changing the password." });
            };
        }).catch(() => {
            setInfoP({ errors: [], text: "Server timed out!" });
        }).finally(() => {
            setSpinner(false);
        });
    };

    return <div className="component__userHeader__changePassword">
        <div className="component__userHeader__changePassword__wrap">
            <div className="component__userHeader__changePassword__wrap__head">
                <div className="component__userHeader__changePassword__wrap__head__left">Change password</div>
                <div className="component__userHeader__changePassword__wrap__head__right" style={{ backgroundImage: `url("/images/icon_close.svg")` }} onClick={props.onClose}></div>
            </div>

            <div className={`component__userHeader__changePassword__wrap__input ${infoP.errors.includes("old-password") ? "component__userHeader__changePassword__wrap__input--error" : ""}`}>
                <p>Old password</p>
                <input ref={oldPassRef} type="password" placeholder="Old password" />
            </div>
            <div className={`component__userHeader__changePassword__wrap__input ${infoP.errors.includes("new-password") ? "component__userHeader__changePassword__wrap__input--error" : ""}`}>
                <p>New password</p>
                <input ref={newPassRef} type="password" placeholder="New password" />
            </div>
            <div className={`component__userHeader__changePassword__wrap__input ${infoP.errors.includes("confirm-password") ? "component__userHeader__changePassword__wrap__input--error" : ""}`}>
                <p>Confirm new password</p>
                <input ref={confirmPassRef} type="password" placeholder="Confirm password" />
            </div>

            <div className="component__userHeader__changePassword__wrap__buttons">
                <button className="component__userHeader__changePassword__wrap__buttons__cancel" style={{
                    pointerEvents: spinner ? "none" : "all"
                }} onClick={() => !spinner && props.onClose()} >Cancel</button>

                <button className="component__userHeader__changePassword__wrap__buttons__change" style={{
                    pointerEvents: spinner ? "none" : "all"
                }} onClick={changePassword}>
                    {spinner ? <Spinner style={{ width: "21px", height: "21px" }} color="white" /> : "Change"}
                </button>
            </div>

            {infoP.text && <p className="component__userHeader__changePassword__wrap__infoP">{infoP.text}</p>}
        </div>
    </div>
};