import React from "react";
import "./index.scss";

import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment/moment";
import * as backendModule from "../../../modules/backendModule";
import Spinner from "../../../components/customComponents/Spinner";
import animateModule from "../../../modules/animateModule";
import { useNavigate } from "react-router-dom";

import { Pie, Line } from 'react-chartjs-2';
import { Chart, ArcElement } from 'chart.js'
import { CategoryScale } from 'chart.js'
import { registerables } from 'chart.js';

const PublisherDashboardGeneral = (props) => {
    const [data, setData] = React.useState();
    const [dataOld, setDataOld] = React.useState();
    const [dailyData, setDailyData] = React.useState();
    const [filters, setFilters] = React.useState(props.filters);
    const [filters2, setFilters2] = React.useState(props.filters2);
    const curUserSelector = useSelector(state => state?.userData?.userData?.UserInfo);

    const curNavigate = useNavigate();
    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".root__content"));





    const getData = () => {
        setData(null)
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/statistic/getPublisherStatistic`,
            data: {
                filters: filters,
            },
            ...backendModule.axiosConfig
        }).then(res => {
            console.log(res.data.data)
            let tmp = res.data.data.statisticBydate;
            let ordered = Object.keys(tmp).sort().reduce(
                (obj, key) => {
                    obj[key] = tmp[key];
                    return obj;
                },
                {}
            );
            setDailyData({ data: ordered });
            setData(res.data);
        }).catch(() => {
            setData(backendModule.axiosConfig);
        });
    };

    const getDataOld = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/statistic/getPublisherStatistic`,
            data: {
                filters: filters2,
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setDataOld(res.data);
        }).catch(() => {
            setData(backendModule.axiosConfig);
        });
    };

    React.useEffect(() => {
        if (filters.length === 0) return;
        getData()
    }, [filters]);
    React.useEffect(() => {
        if (filters2.length === 0) return;
        getDataOld()
    }, [filters2]);


    React.useEffect(() => {
        setFilters(props.filters)
    }, [props.filters]);

    React.useEffect(() => {
        setFilters2(props.filters2)
    }, [props.filters2]);


    Chart.register(ArcElement);
    Chart.register(CategoryScale);
    Chart.register(...registerables);

    const calculateValues = (curr, prev) => {
        if (!curr) {
            return <span className="component__dashboard__content__kpi__single__data2__red">&#129033; 0 (100%)</span>;
        }
        if (!prev) {
            return <span className="component__dashboard__content__kpi__single__data2__green">&#129035; 0 (-100%)</span>;
        }
        if (curr > prev) {
            return <span className="component__dashboard__content__kpi__single__data2__green">
                &#129033; {prev.toFixed(2)} ({(100 - prev / curr * 100).toFixed(2)}%)
            </span>;
        } else return <span className="component__dashboard__content__kpi__single__data2__red">
            &#129035; {prev.toFixed(2)} (-{(100 - curr / prev * 100).toFixed(2)}%)
        </span>;
    }



    return <div className="component__dashboard__content">
        {data ? <></> : <Spinner />}
        
        {data?.data?.statisticByType?.["RevShare"] ? <>
        <p className="component__dashboard__content__head">Rev Share</p>
            <div className="component__dashboard__content__kpi">
                <div className="component__dashboard__content__kpi__single">
                    <p className="component__dashboard__content__kpi__single__head">
                        Clicks
                    </p>
                    <p className="component__dashboard__content__kpi__single__data">
                        {data?.data?.statisticByType?.["RevShare"]?.leads?.View ? data?.data?.statisticByType?.["RevShare"]?.leads?.View : 0}
                    </p>
                    <p className="component__dashboard__content__kpi__single__data2">
                        {calculateValues(data?.data?.statisticByType?.["RevShare"]?.leads?.View, dataOld?.data?.statisticByType?.["RevShare"]?.leads?.View)}
                    </p>
                </div>
                <div className="component__dashboard__content__kpi__single">
                    <p className="component__dashboard__content__kpi__single__head">
                        Registrations
                    </p>
                    <p className="component__dashboard__content__kpi__single__data">
                        {data?.data?.statisticByType?.["RevShare"]?.leads?.Registration ? data?.data?.statisticByType?.["RevShare"]?.leads?.Registration : 0}
                    </p>
                    <p className="component__dashboard__content__kpi__single__data2">
                        {calculateValues(data?.data?.statisticByType?.["RevShare"]?.leads?.Registration, dataOld?.data?.statisticByType?.["RevShare"]?.leads?.Registration)}
                    </p>
                </div>
                <div className="component__dashboard__content__kpi__single">
                    <p className="component__dashboard__content__kpi__single__head">
                        CR (Registrations)
                    </p>
                    <p className="component__dashboard__content__kpi__single__data">
                        {data?.data?.statisticByType?.["RevShare"]?.leads?.Registration && data?.data?.statisticByType?.["RevShare"]?.leads?.View ? (data?.data?.statisticByType?.["RevShare"]?.leads?.Registration / data?.data?.statisticByType?.["RevShare"]?.leads?.View * 100).toFixed(2) : 0} %
                    </p>
                    <p className="component__dashboard__content__kpi__single__data2">
                        {calculateValues(data?.data?.statisticByType?.["RevShare"]?.leads?.Registration / data?.data?.statisticByType?.["RevShare"]?.leads?.View * 100, dataOld?.data?.statisticByType?.["RevShare"]?.leads?.Registration / dataOld?.data?.statisticByType?.["RevShare"]?.leads?.View * 100)}

                    </p>
                </div>
                <div className="component__dashboard__content__kpi__single">
                    <p className="component__dashboard__content__kpi__single__head">
                        DOI
                    </p>
                    <p className="component__dashboard__content__kpi__single__data">
                        {data?.data?.statisticByType?.["RevShare"]?.leads?.Verification ? data?.data?.statisticByType?.["RevShare"]?.leads?.Verification : 0}
                    </p>
                    <p className="component__dashboard__content__kpi__single__data2">
                        {calculateValues(data?.data?.statisticByType?.["RevShare"]?.leads?.Verification, dataOld?.data?.statisticByType?.["RevShare"]?.leads?.Verification)}

                    </p>
                </div>
                <div className="component__dashboard__content__kpi__single">
                    <p className="component__dashboard__content__kpi__single__head">
                        CR (DOI)
                    </p>
                    <p className="component__dashboard__content__kpi__single__data">
                        {data?.data?.statisticByType?.["RevShare"]?.leads?.Verification ? (data?.data?.statisticByType?.["RevShare"]?.leads?.Verification / data?.data?.statisticByType?.["RevShare"]?.leads?.Registration * 100).toFixed(2) : 0} %
                    </p>
                    <p className="component__dashboard__content__kpi__single__data2">
                        {calculateValues(data?.data?.statisticByType?.["RevShare"]?.leads?.Verification / data?.data?.statisticByType?.["RevShare"]?.leads?.Registration * 100, dataOld?.data?.statisticByType?.["RevShare"]?.leads?.Verification / dataOld?.data?.statisticByType?.["RevShare"]?.leads?.Registration * 100)}

                    </p>
                </div>

                <div className="component__dashboard__content__kpi__single">
                    <p className="component__dashboard__content__kpi__single__head">
                        Unique buyers
                    </p>
                    <p className="component__dashboard__content__kpi__single__data">
                        {data?.data?.statisticByType?.["RevShare"]?.users ? Object.keys(data?.data?.statisticByType?.["RevShare"]?.users)?.length : 0}
                    </p>
                    <p className="component__dashboard__content__kpi__single__data2">
                        {data?.data?.statisticByType?.["RevShare"]?.users && dataOld?.data?.statisticByType?.["RevShare"]?.users && calculateValues(Object.keys(data?.data?.statisticByType?.["RevShare"]?.users)?.length, Object.keys(dataOld?.data?.statisticByType?.["RevShare"]?.users)?.length)}
                    </p>
                </div>
                <div className="component__dashboard__content__kpi__single">
                    <p className="component__dashboard__content__kpi__single__head">
                        Unique buyers (CR)
                    </p>
                    <p className="component__dashboard__content__kpi__single__data">
                        {data?.data?.statisticByType?.["RevShare"]?.users && data?.data?.statisticByType?.["RevShare"]?.leads?.Registration ? (Number(Object.keys(data?.data?.statisticByType?.["RevShare"]?.users)?.length / data?.data?.statisticByType?.["RevShare"]?.leads?.Registration * 100)).toFixed(2) : 0} %
                    </p>
                    <p className="component__dashboard__content__kpi__single__data2">
                        {data?.data?.statisticByType?.["RevShare"]?.users && dataOld?.data?.statisticByType?.["RevShare"]?.users && calculateValues(Object.keys(data?.data?.statisticByType?.["RevShare"]?.users)?.length / data?.data?.statisticByType?.["RevShare"]?.leads?.Registration * 100, Object.keys(dataOld?.data?.statisticByType?.["RevShare"]?.users)?.length / dataOld?.data?.statisticByType?.["RevShare"]?.leads?.Registration * 100)}

                    </p>
                </div>
                <div className="component__dashboard__content__kpi__single">
                    <p className="component__dashboard__content__kpi__single__head">
                        Unique sales
                    </p>
                    <p className="component__dashboard__content__kpi__single__data">
                        {data.data?.users ? (() => {
                            let tmp = 0;
                            Object.keys(data.data?.users).map(user => {
                                tmp += data.data?.users[user][0]
                            })
                            return tmp.toFixed(2)
                        })() : 0} €
                    </p>
                    <p className="component__dashboard__content__kpi__single__data2">
                        {data?.data?.statisticByType?.["RevShare"]?.users && dataOld?.data?.statisticByType?.["RevShare"]?.users && (() => {
                            let salesOld = 0;
                            let sales = 0;
                            Object.keys(data.data?.users).map(user => {
                                sales += data.data?.users[user]?.[0]
                            })
                            Object.keys(dataOld.data?.users).map(user => {
                                salesOld += dataOld.data?.users[user]?.[0]
                            })
                            return calculateValues(sales, salesOld)
                        })()}
                    </p>
                </div>
                <div className="component__dashboard__content__kpi__single">
                    <p className="component__dashboard__content__kpi__single__head">
                        Tail sales
                    </p>
                    <p className="component__dashboard__content__kpi__single__data">
                        {data.data?.users ? (() => {
                            let tmp = 0;
                            Object.keys(data.data?.users).map(user => {
                                if (data.data?.users[user].length > 1) {
                                    data.data?.users[user].map((item, index) => {
                                        if (index > 0) {
                                            tmp += item;
                                        }
                                    })
                                }
                            })
                            return tmp.toFixed(2)
                        })() : 0} €
                    </p>
                    <p className="component__dashboard__content__kpi__single__data2">
                        {data?.data?.statisticByType?.["RevShare"]?.users && dataOld?.data?.statisticByType?.["RevShare"]?.users && (() => {
                            let salesOld = 0;
                            let sales = 0;
                            Object.keys(data.data?.users).map(user => {
                                if (data.data?.users[user].length > 1) {
                                    data.data?.users[user].map((item, index) => {
                                        if (index > 0) {
                                            sales += item;
                                        }
                                    })
                                }
                            })
                            Object.keys(dataOld.data?.users).map(user => {
                                if (dataOld.data?.users[user].length > 1) {
                                    dataOld.data?.users[user].map((item, index) => {
                                        if (index > 0) {
                                            salesOld += item;
                                        }
                                    })
                                }
                            })
                            return calculateValues(sales, salesOld)
                        })()}
                    </p>
                </div>
                <div className="component__dashboard__content__kpi__single">
                    <p className="component__dashboard__content__kpi__single__head">
                        Total sales
                    </p>
                    <p className="component__dashboard__content__kpi__single__data">
                        {data.data?.users ? (() => {
                            let tmp = 0;
                            Object.keys(data.data?.users).map(user => {
                                data.data?.users[user].map((item, index) => {
                                    tmp += item;
                                })
                            })
                            return tmp.toFixed(2)
                        })() : 0} €
                    </p>
                    <p className="component__dashboard__content__kpi__single__data2">
                        {data?.data?.statisticByType?.["RevShare"]?.users && dataOld?.data?.statisticByType?.["RevShare"]?.users && (() => {
                            let salesOld = 0;
                            let sales = 0;
                            Object.keys(data.data?.users).map(user => {
                                data.data?.users[user].map((item, index) => {
                                    sales += item;
                                })
                            })
                            Object.keys(dataOld.data?.users).map(user => {
                                dataOld.data?.users[user].map((item, index) => {
                                    salesOld += item;
                                })
                            })
                            return calculateValues(sales, salesOld)
                        })()}
                    </p>
                </div>
                <div className="component__dashboard__content__kpi__single">
                    <p className="component__dashboard__content__kpi__single__head">
                        Commisions
                    </p>
                    <p className="component__dashboard__content__kpi__single__data">
                        {data.data?.commisions ? (() => {
                            let tmp = 0;
                            Object.keys(data.data?.commisions).map(com => {
                                tmp += data.data?.commisions[com];
                            })
                            return tmp.toFixed(2)
                        })() : 0} €
                    </p>
                    <p className="component__dashboard__content__kpi__single__data2">
                        {data?.data?.statisticByType?.["RevShare"]?.commisions && dataOld?.data?.statisticByType?.["RevShare"]?.commisions && (() => {
                            let salesOld = 0;
                            let sales = 0;
                            Object.keys(data.data?.commisions).map(com => {
                                sales += data.data?.commisions[com];
                            })
                            Object.keys(dataOld.data?.commisions).map(com => {
                                salesOld += dataOld.data?.commisions[com];
                            })
                            return calculateValues(sales, salesOld)
                        })()}
                    </p>
                </div>
                <div className="component__dashboard__content__kpi__single">
                    <p className="component__dashboard__content__kpi__single__head">
                        Earnings per Click
                    </p>
                    <p className="component__dashboard__content__kpi__single__data">
                        {data.data?.commisions ? (() => {
                            let tmp = 0;
                            Object.keys(data.data?.commisions).map(com => {
                                tmp += data.data?.commisions[com];
                            })
                            return (tmp / data?.data?.statisticByType?.["RevShare"]?.leads?.View).toFixed(2)
                        })() : 0} €
                    </p>
                    <p className="component__dashboard__content__kpi__single__data2">
                        {data?.data?.statisticByType?.["RevShare"]?.commisions && dataOld?.data?.statisticByType?.["RevShare"]?.commisions && (() => {
                            let salesOld = 0;
                            let sales = 0;
                            Object.keys(data.data?.commisions).map(com => {
                                sales += data.data?.commisions[com];
                            })
                            Object.keys(dataOld.data?.commisions).map(com => {
                                salesOld += dataOld.data?.commisions[com];
                            })
                            return calculateValues(sales / data?.data?.statisticByType?.["RevShare"]?.leads?.View, salesOld / dataOld?.data?.statisticByType?.["RevShare"]?.leads?.View)
                        })()}
                    </p>
                </div>

            </div>

        </> : <></>}
        
        {data?.data?.statisticByType?.["CPA-SOI"] ? <>
        <p className="component__dashboard__content__head">CPA-SOI</p>
            <div className="component__dashboard__content__kpi">
                <div className="component__dashboard__content__kpi__single">
                    <p className="component__dashboard__content__kpi__single__head">
                        Clicks
                    </p>
                    <p className="component__dashboard__content__kpi__single__data">
                        {data?.data?.statisticByType?.["CPA-SOI"]?.leads?.View ? data?.data?.statisticByType?.["CPA-SOI"]?.leads?.View : 0}
                    </p>
                    <p className="component__dashboard__content__kpi__single__data2">
                        {calculateValues(data?.data?.statisticByType?.["CPA-SOI"]?.leads?.View, dataOld?.data?.statisticByType?.["CPA-SOI"]?.leads?.View)}
                    </p>
                </div>
                <div className="component__dashboard__content__kpi__single">
                    <p className="component__dashboard__content__kpi__single__head">
                        Registrations
                    </p>
                    <p className="component__dashboard__content__kpi__single__data">
                        {data?.data?.statisticByType?.["CPA-SOI"]?.leads?.Registration ? data?.data?.statisticByType?.["CPA-SOI"]?.leads?.Registration : 0}
                    </p>
                    <p className="component__dashboard__content__kpi__single__data2">
                        {calculateValues(data?.data?.statisticByType?.["CPA-SOI"]?.leads?.Registration, dataOld?.data?.statisticByType?.["CPA-SOI"]?.leads?.Registration)}
                    </p>
                </div>
                <div className="component__dashboard__content__kpi__single">
                    <p className="component__dashboard__content__kpi__single__head">
                        CR (Registrations)
                    </p>
                    <p className="component__dashboard__content__kpi__single__data">
                        {data?.data?.statisticByType?.["CPA-SOI"]?.leads?.Registration && data?.data?.statisticByType?.["CPA-SOI"]?.leads?.View ? (data?.data?.statisticByType?.["CPA-SOI"]?.leads?.Registration / data?.data?.statisticByType?.["CPA-SOI"]?.leads?.View * 100).toFixed(2) : 0} %
                    </p>
                    <p className="component__dashboard__content__kpi__single__data2">
                        {calculateValues(data?.data?.statisticByType?.["CPA-SOI"]?.leads?.Registration / data?.data?.statisticByType?.["CPA-SOI"]?.leads?.View * 100, dataOld?.data?.statisticByType?.["CPA-SOI"]?.leads?.Registration / dataOld?.data?.statisticByType?.["CPA-SOI"]?.leads?.View * 100)}

                    </p>
                </div>
                <div className="component__dashboard__content__kpi__single">
                    <p className="component__dashboard__content__kpi__single__head">
                        DOI
                    </p>
                    <p className="component__dashboard__content__kpi__single__data">
                        {data?.data?.statisticByType?.["CPA-SOI"]?.leads?.Verification ? data?.data?.statisticByType?.["CPA-SOI"]?.leads?.Verification : 0}
                    </p>
                    <p className="component__dashboard__content__kpi__single__data2">
                        {calculateValues(data?.data?.statisticByType?.["CPA-SOI"]?.leads?.Verification, dataOld?.data?.statisticByType?.["CPA-SOI"]?.leads?.Verification)}

                    </p>
                </div>

                <div className="component__dashboard__content__kpi__single">
                    <p className="component__dashboard__content__kpi__single__head">
                        Commisions
                    </p>
                    <p className="component__dashboard__content__kpi__single__data">
                        {data.data?.commisions ? (() => {
                            let tmp = 0;
                            Object.keys(data.data?.commisions).map(com => {
                                tmp += data.data?.commisions[com];
                            })
                            return tmp.toFixed(2)
                        })() : 0} €
                    </p>
                    <p className="component__dashboard__content__kpi__single__data2">
                        {data?.data?.statisticByType?.["CPA-SOI"]?.commisions && dataOld?.data?.statisticByType?.["CPA-SOI"]?.commisions && (() => {
                            let salesOld = 0;
                            let sales = 0;
                            Object.keys(data.data?.commisions).map(com => {
                                sales += data.data?.commisions[com];
                            })
                            Object.keys(dataOld.data?.commisions).map(com => {
                                salesOld += dataOld.data?.commisions[com];
                            })
                            return calculateValues(sales, salesOld)
                        })()}
                    </p>
                </div>
                <div className="component__dashboard__content__kpi__single">
                    <p className="component__dashboard__content__kpi__single__head">
                        Earnings per Click
                    </p>
                    <p className="component__dashboard__content__kpi__single__data">
                        {data.data?.commisions ? (() => {
                            let tmp = 0;
                            Object.keys(data.data?.commisions).map(com => {
                                tmp += data.data?.commisions[com];
                            })
                            return (tmp / data?.data?.statisticByType?.["CPA-SOI"]?.leads?.View).toFixed(2)
                        })() : 0} €
                    </p>
                    <p className="component__dashboard__content__kpi__single__data2">
                        {data?.data?.statisticByType?.["CPA-SOI"]?.commisions && dataOld?.data?.statisticByType?.["CPA-SOI"]?.commisions && (() => {
                            let salesOld = 0;
                            let sales = 0;
                            Object.keys(data.data?.commisions).map(com => {
                                sales += data.data?.commisions[com];
                            })
                            Object.keys(dataOld.data?.commisions).map(com => {
                                salesOld += dataOld.data?.commisions[com];
                            })
                            return calculateValues(sales / data?.data?.statisticByType?.["CPA-SOI"]?.leads?.View, salesOld / dataOld?.data?.statisticByType?.["CPA-SOI"]?.leads?.View)
                        })()}
                    </p>
                </div>

            </div>

        </> : <></>}

        {dailyData?.data && <div className="component__dashboard__content__charts" style={{ gridTemplateColumns: "1fr 1fr" }}>
            <div style={{ width: "100%" }}>
                <p>Leads</p>
                {data?.data && <Line style={{ width: "100%", height: "auto" }} data={{
                    labels: Object.keys(dailyData?.data).map(item => {
                        return item;
                    }),
                    datasets: [{
                        label: "SOI",
                        data: Object.keys(dailyData?.data).map((item, index) => {
                            return dailyData?.data[item]?.leads?.Registration ? dailyData?.data[item]?.leads?.Registration : 0;
                        }),
                        borderColor: "#6c5dd3",
                        backgroundColor: "#6c5dd3",
                    }, {
                        label: "DOI",
                        data: Object.keys(dailyData?.data).map((item, index) => {
                            return dailyData?.data[item]?.leads?.Verification ? dailyData?.data[item]?.leads?.Verification : 0;
                        }),
                        borderColor: "#FFA459",
                        backgroundColor: "#FFA459",

                    }, {
                        label: "Purchase",
                        data: Object.keys(dailyData?.data).map((item, index) => {
                            return dailyData?.data[item]?.leads?.Purchase ? dailyData?.data[item]?.leads?.Purchase : 0;
                        }),
                        borderColor: "#59FF74",
                        backgroundColor: "#59FF74",

                    }]
                }} />}
            </div>
            <div style={{ width: "100%" }}>
                <p>Earnings</p>
                {data?.data && <Line style={{ width: "100%", height: "auto" }} data={{
                    labels: Object.keys(dailyData?.data).map(item => {
                        return item;
                    }),
                    datasets: [{
                        label: "SOI",
                        data: Object.keys(dailyData?.data).map((item, index) => {
                            return dailyData?.data[item]?.commisions["CPA-SOI"] ? dailyData?.data[item]?.commisions["CPA-SOI"] : 0;
                        }),
                        borderColor: "#6c5dd3",
                        backgroundColor: "#6c5dd3",
                    }, {
                        label: "DOI",
                        data: Object.keys(dailyData?.data).map((item, index) => {
                            return dailyData?.data[item]?.commisions["CPA-SOI"] ? dailyData?.data[item]?.commisions["CPA-SOI"] : 0;
                        }),
                        borderColor: "#FFA459",
                        backgroundColor: "#FFA459",

                    }, {
                        label: "REV-SHARE",
                        data: Object.keys(dailyData?.data).map((item, index) => {
                            return dailyData?.data[item]?.commisions["RevShare"] ? dailyData?.data[item]?.commisions["RevShare"] : 0;
                        }),
                        borderColor: "#59FF74",
                        backgroundColor: "#59FF74",

                    }]
                }} />}
            </div>

        </div>
        }
    </div>


};



export default PublisherDashboardGeneral;