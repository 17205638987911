import React from "react";
import "./index.scss";

import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment/moment";
import * as backendModule from "../../../modules/backendModule";
import Spinner from "../../../components/customComponents/Spinner";
import animateModule from "../../../modules/animateModule";
import { useNavigate } from "react-router-dom";

import { Pie, Line } from 'react-chartjs-2';
import { Chart, ArcElement } from 'chart.js'
import { CategoryScale } from 'chart.js'
import { registerables } from 'chart.js';

const PublisherDashboardDate = (props) => {
    const [data, setData] = React.useState();
    const [dataOrdered, setDataOrdered] = React.useState();
    const [filters, setFilters] = React.useState(props.filters);
    const curUserSelector = useSelector(state => state?.userData?.userData?.UserInfo);

    const curNavigate = useNavigate();
    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".root__content"));


    const getData = () => {
        setData(null);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/statistic/getPublisherStatisticByDate`,
            data: {
                filters: filters,
                limit: 10
            },
            ...backendModule.axiosConfig
        }).then(res => {
            let tmp = res.data;
            let ordered = Object.keys(tmp.data).sort().reduce(
                (obj, key) => {
                    obj[key] = tmp.data[key];
                    return obj;
                },
                {}
            );
            setData({ data: ordered });
        }).catch(() => {
            setData(backendModule.axiosConfig);
        });
    };

    React.useEffect(() => {
        getData()
    }, [filters]);


    React.useEffect(() => {
        setFilters(props.filters)
    }, [props.filters]);


    Chart.register(ArcElement);
    Chart.register(CategoryScale);
    Chart.register(...registerables);


    return <div className="component__dashboard__content">
        {data ? <>
            <div className="component__dashboard__content__row">
                <p>Date</p>
                <p>Clicks</p>
                <p>SOI</p>
                <p>CR (Reg)</p>
                <p>DOI</p>
                <p>CR (DOI)</p>
                <p>Unique sales</p>
                <p>Tail sales</p>
                <p>Total sales</p>
                <p>Commisions RevShare</p>
                <p>Commisions SOI</p>
                <p>Commisions DOI</p>
                <p>Commisions total</p>
                <p>Earnings per Click</p>

            </div>
            {Object.keys(data.data).map((item, index) => {
                return <div className={`component__dashboard__content__row ${index % 2 === 0 && "component__dashboard__content__row-2"} `}>
                    <p>{item}</p>
                    <p>{data.data[item]?.leads.View ? data.data[item]?.leads.View : 0}</p>
                    <p>{data.data[item]?.leads.Registration ? data.data[item]?.leads.Registration : 0}</p>
                    <p>{(data.data[item]?.leads.Registration && data.data[item]?.leads.View) ?
                        (Number(data.data[item]?.leads.Registration) / Number(data.data[item]?.leads.View) * 100).toFixed(2) : 0} %</p>
                    <p>{data.data[item]?.leads.Verification ? data.data[item]?.leads.Verification : 0}</p>
                    <p>{(data.data[item]?.leads.Verification && data.data[item]?.leads.Registration) ?
                        (Number(data.data[item]?.leads.Verification) / Number(data.data[item]?.leads.Registration) * 100).toFixed(2) : 0} %</p>
                    <p>{data.data[item]?.users ? (() => {
                        let tmp = 0;
                        Object.keys(data.data[item]?.users).map(user => {
                            tmp += data.data[item]?.users[user][0]
                        })
                        return tmp.toFixed(2);
                    })() : 0} € </p>
                    <p>{data.data[item]?.users ? (() => {
                        let tmp = 0;
                        Object.keys(data.data[item]?.users).map(user => {
                            if (data.data[item]?.users[user].length > 1) {
                                data.data[item]?.users[user].map((item, index) => {
                                    if (index > 0) {
                                        tmp += item;
                                    }
                                })
                            }
                        })
                        return tmp.toFixed(2);
                    })() : 0} €</p>
                    <p>{data.data[item]?.users ? (() => {
                        let tmp = 0;
                        Object.keys(data.data[item]?.users).map(user => {
                            data.data[item]?.users[user].map((item, index) => {
                                tmp += item;
                            })
                        })
                        return tmp.toFixed(2);
                    })() : 0} €</p>
                    <p>{data.data[item]?.commisions.RevShare ? data.data[item]?.commisions.RevShare.toFixed(2) : 0} €</p>
                    <p>{data.data[item]?.commisions["CPA-SOI"] ? data.data[item]?.commisions["CPA-SOI"].toFixed(2) : 0} €</p>
                    <p>{data.data[item]?.commisions["CPA-DOI"] ? data.data[item]?.commisions["CPA-DOI"].toFixed(2) : 0} €</p>
                    <p>{(() => {
                        let tmp = 0;
                        if (data.data[item]?.commisions.RevShare) tmp += data.data[item]?.commisions.RevShare;
                        if (data.data[item]?.commisions["CPA-SOI"]) tmp += data.data[item]?.commisions["CPA-SOI"];
                        if (data.data[item]?.commisions["CPA-DOI"]) tmp += data.data[item]?.commisions["CPA-DOI"];
                        return tmp.toFixed(2);
                    })()} €</p>
                    <p>{(() => {
                        let tmp = 0;
                        if (data.data[item]?.commisions.RevShare) tmp += data.data[item]?.commisions.RevShare;
                        if (data.data[item]?.commisions["CPA-SOI"]) tmp += data.data[item]?.commisions["CPA-SOI"];
                        if (data.data[item]?.commisions["CPA-DOI"]) tmp += data.data[item]?.commisions["CPA-DOI"];
                        return (tmp / data.data[item]?.leads.View).toFixed(2);
                    })()} €</p>

                </div>
            })}
            {(() => {
                let tmpViews = 0;
                let tmpSoi = 0;
                let tmpCRReg = 0;
                let tmpDoi = 0;
                let crDoi = 0;
                let tmpUS = 0;
                let tmpTS = 0;
                let tmpTotalS = 0
                let cRev = 0;
                let cSOI = 0;
                let cDOI = 0;
                let cTotal = 0;

                Object.keys(data?.data).forEach(item => {
                    tmpViews += data.data[item]?.leads?.View ? data.data[item]?.leads?.View : 0;
                    tmpSoi += data.data[item]?.leads?.Registration ? data.data[item]?.leads?.Registration : 0;
                    tmpDoi += data.data[item]?.leads?.Verification ? data.data[item]?.leads?.Verification : 0;
                    Object.keys(data.data[item]?.users).map(user => {
                        tmpUS += data.data[item]?.users[user][0] ? data.data[item]?.users[user][0] : 0
                    })
                    Object.keys(data.data[item]?.users).map(user => {
                        if (data.data[item]?.users[user].length > 1) {
                            data.data[item]?.users[user].map((item, index) => {
                                if (index > 0) {
                                    tmpTS += item ? item : 0;
                                }
                            })
                        }
                    })
                    Object.keys(data.data[item]?.users).map(user => {
                        data.data[item]?.users[user].map((item, index) => {
                            tmpTotalS += item ? item : 0;
                        })
                    })
                    cRev += data.data[item]?.commisions?.RevShare ? Number(data.data[item]?.commisions?.RevShare) : 0;
                    cSOI += data.data[item]?.commisions["CPA-SOI"] ? Number(data.data[item]?.commisions["CPA-SOI"]) : 0;
                    cDOI += data.data[item]?.commisions["CPA-DOI"] ? Number(data.data[item]?.commisions["CPA-DOI"]) : 0;
                })
                tmpCRReg = (tmpSoi / tmpViews * 100).toFixed(2);
                crDoi = (tmpDoi / tmpSoi * 100).toFixed(2);
                cTotal = cRev + cSOI + cDOI;



                return <div className={`component__dashboard__content__row ${Object.keys(data.data)?.length % 2 === 0 && "component__dashboard__content__row-2"} `}>
                    <p>Total</p>
                    <p>{tmpViews}</p>
                    <p>{tmpSoi}</p>
                    <p>{tmpCRReg} %</p>
                    <p>{tmpDoi}</p>
                    <p>{crDoi} %</p>
                    <p>{tmpUS.toFixed(2)} €</p>
                    <p>{tmpTS.toFixed(2)} €</p>
                    <p>{tmpTotalS.toFixed(2)} €</p>
                    <p>{cRev.toFixed(2)} €</p>
                    <p>{cSOI.toFixed(2)} €</p>
                    <p>{cDOI.toFixed(2)} €</p>
                    <p>{cTotal.toFixed(2)} €</p>
                    <p>{(cTotal / tmpViews).toFixed(2)} €</p>
                </div>
            })()}
        </> : <Spinner align={"center"} color={"#6c5dd3"} />}
        <div className="component__dashboard__content__charts" style={{ gridTemplateColumns: "1fr 1fr" }}>
            <div style={{ width: "100%" }}>
                <p>Leads</p>
                {data?.data && <Line style={{ width: "100%", height: "auto" }} data={{
                    labels: Object.keys(data.data).map(item => {
                        return item;
                    }),
                    datasets: [{
                        label: "SOI",
                        data: Object.keys(data.data).map((item, index) => {
                            return data.data[item]?.leads?.Registration ? data.data[item]?.leads?.Registration : 0;
                        }),
                        borderColor: "#6c5dd3",
                        backgroundColor: "#6c5dd3",
                    }, {
                        label: "DOI",
                        data: Object.keys(data.data).map((item, index) => {
                            return data.data[item]?.leads?.Verification ? data.data[item]?.leads?.Verification : 0;
                        }),
                        borderColor: "#FFA459",
                        backgroundColor: "#FFA459",

                    }, {
                        label: "Purchase",
                        data: Object.keys(data.data).map((item, index) => {
                            return data.data[item]?.leads?.Purchase ? data.data[item]?.leads?.Purchase : 0;
                        }),
                        borderColor: "#59FF74",
                        backgroundColor: "#59FF74",

                    }]
                }} />}
            </div>
            <div style={{ width: "100%" }}>
                <p>Earnings</p>
                {data?.data && <Line style={{ width: "100%", height: "auto" }} data={{
                    labels: Object.keys(data.data).map(item => {
                        return item;
                    }),
                    datasets: [{
                        label: "SOI",
                        data: Object.keys(data.data).map((item, index) => {
                            return data.data[item]?.commisions["CPA-SOI"] ? data.data[item]?.commisions["CPA-SOI"] : 0;
                        }),
                        borderColor: "#6c5dd3",
                        backgroundColor: "#6c5dd3",
                    }, {
                        label: "DOI",
                        data: Object.keys(data.data).map((item, index) => {
                            return data.data[item]?.commisions["CPA-DOI"] ? data.data[item]?.commisions["CPA-DOI"] : 0;
                        }),
                        borderColor: "#FFA459",
                        backgroundColor: "#FFA459",

                    }, {
                        label: "REV-SHARE",
                        data: Object.keys(data.data).map((item, index) => {
                            return data.data[item]?.commisions["RevShare"] ? data.data[item]?.commisions["RevShare"] : 0;
                        }),
                        borderColor: "#59FF74",
                        backgroundColor: "#59FF74",

                    }]
                }} />}
            </div>

        </div>

    </div>


};



export default PublisherDashboardDate;