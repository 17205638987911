import React from "react";
import "./index.scss";

import { useSelector } from "react-redux";
import axios from "axios";
import * as backendModule from "../../../modules/backendModule";
import { animateBox } from "../../../modules/componentAnimation";
import { countries } from "../../../modules/countryModule";

import useDefer from "../../../modules/hooks/useDefer";
import useOnScreen from "../../../modules/hooks/useOnScreen";

import Spinner from "../../../components/customComponents/Spinner";
import FilterPanel from "../../../components/customComponents/FilterPanel";
import Dropdown from "../../../components/customComponents/Dropdown";
import FilterBySearch from "../../../filters/FilterBySearch";
import RadioButton from "../../../components/customComponents/RadioButton";
import { FilteredCustomTable } from "../../../components/customComponents/Table";

const AdminWebsites = () => {
    const [showFlags, setShowFlags] = React.useState(false);
    const [data, setData] = React.useState();
    const [filters, setFilters] = React.useState([]);
    const [spinner, setSpinner] = React.useState(false)
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [search, setSearch] = React.useState("");
    const curDefer = useDefer();
    const curOnScreen = useOnScreen();

    const curUserSelector = useSelector(state => state?.userData?.userData?.UserInfo);

    const getData = () => {
        const filtersTmp = [...filters];

        if (search) {
            filtersTmp.push({
                or: [
                    { name: "Name", op: "like", value: search },
                    { name: "Type", op: "like", value: search },
                    { name: "Currency", op: "like", value: search },
                    { name: "ID", op: "like", value: search }
                ]
            })
        };
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/websites/getAllWebsites`,
            data: {
                filters: filtersTmp,
                limit: null
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data?.data?.DBData?.length === 20) {
                    setCanPaginate(true);
                } else {
                    setCanPaginate(false);
                };
            };

            setData(res.data);
        }).catch((e) => {
            setData(backendModule.axiosConfig);
        });
    };

    const continueData = () => {
        setCanPaginate(false);
        if (!data) return;
        if (data.status !== "ok") return;
        if (data?.DBData.length === 0) return;

        const filtersTmp = [...filters];

        if (search) {
            filtersTmp.push({
                or: [
                    { name: "Name", op: "like", value: search },
                    { name: "Type", op: "like", value: search },
                    { name: "Currency", op: "like", value: search },
                    { name: "ID", op: "like", value: search }
                ]
            })
        };

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/websites/getAllWebsites`,
            data: {
                limit: 20,
                offset: data?.DBData.length,
                filters: filtersTmp
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData(d => {
                    return {
                        ...d,
                        DBData: [
                            ...d.data,
                            ...res.data?.DBData
                        ]
                    };
                });
                if (res.data?.DBData.length === 20) {
                    setCanPaginate(true);
                } else {
                    setCanPaginate(false);
                };
            };
        }).catch(() => null).finally(() => {
            setSpinner(false);
        });
    };
    React.useEffect(() => {
        getData();
    }, [search, filters])

    React.useEffect(() => {
        if (curOnScreen.isIntersecting && canPaginate) {
            try {
                curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
            } catch { };

            curDefer(() => {
                continueData();
            }, 500);
        };
    }, [canPaginate, curOnScreen.isIntersecting]);

    return <div className="route__adminWebsites">
        <div className="route__adminWebsites__head" style={{
            gridTemplateColumns: curUserSelector?.Flags?.isAdmin ? null : "1fr 400px"
        }}>
            <FilterPanel
                accent="#dfdfdf"
                theme="light"
                filterCB={fi => setFilters(fi)}
                filters={[
                    { name: "ID", friendlyName: "ID", type: "string" },
                ]} />
            <FilterBySearch onChange={e => setSearch(e)} />
            {curUserSelector?.Flags?.isAdmin && <button onClick={() => { animateBox({ currentTarget: document.querySelector('.route__adminWebsites__content') }, <SelectWebsite item={null} offers={data.data.offers} update={getData} />) }}>Add website</button>}

        </div>

        <div className="route__adminWebsites__content" style={{ gridTemplateColumns: "1fr" }} >
            <div className="route__adminWebsites__content__left">
                <FilteredCustomTable
                    theme="light"
                    accent="#6C5DD3"
                    headers={["Offer", "Name", "Type", "URL", ""]}
                    customColumns={["1fr", "1fr", "1fr", "1fr", curUserSelector?.Flags?.isAdmin ? "400px" : "0px"]}
                    data={(() => {
                        let out = [];

                        if (!data) return [[{ keyID: "noData-spinner", type: "spinner", color: "white" }]];
                        if (data.status === "ok") {
                            for (let item of data?.data?.DBData) {
                                out.push({
                                    columns: [
                                        { keyID: item.ID, type: "text", text: item.OfferName },
                                        { keyID: item.ID, type: "text", text: item.WebsiteName },
                                        { keyID: item.ID, type: "text", text: item.WebsiteType },
                                        { keyID: item.ID, type: "text", text: item.URL },
                                        {
                                            keyID: item.ID, type: "custom", data: <div className="route__adminOffers__buttons">

                                                {curUserSelector?.Flags?.isAdmin && <p className="route__adminWebsites__delete" onClick={(e) => {
                                                    e.stopPropagation();
                                                    animateBox({ currentTarget: document.querySelector('.route__adminWebsites__content') }, <RemoveWebsite item={item} id={item.ID} update={getData} />)
                                                }}>Delete</p>}
                                            </div>
                                        },
                                    ]
                                })
                            };
                        } else {
                            out.push([{ keyID: "noData-error", type: "text", text: "Error while fetching orders!", color: "#f96666" }]);
                        };

                        if (spinner) out.push([{ keyID: "pagination-spinner", type: "spinner", color: "white" }]);
                        if (out.length === 0) out.push([{ keyID: "noData-noData", type: "text", text: "Nothing to show for now." }]);

                        return out;
                    })()}
                />
            </div>
            {canPaginate && <div ref={curOnScreen.measureRef} className="modals__ordersModal__wrap__content__left__paginate"></div>}

        </div>
    </div>
};

const SelectWebsite = (props) => {

    const [selectedOffer, setSelectedOffer] = React.useState();
    const [curFile, setCurFile] = React.useState();
    const [desktopPreview, setDesktopPreview] = React.useState();
    const [mobilePreview, setMobilePreview] = React.useState();
    const [selectedImages, setSelectedImages] = React.useState(props?.item?.Images ?? []);

    const [item, setItem] = React.useState(props.item);
    const [error, setError] = React.useState();
    const [spinner, setSpinner] = React.useState();

    const [selectedType, setSelectedType] = React.useState(props?.item?.Type);
    const nameRef = React.useRef();
    const entryFileRef = React.useRef();

    const onClose = async () => {
        props.onClose();
    }

    const saveData = () => {

        setError("");
        let WebsiteName = nameRef.current.value;
        let EntryFile = entryFileRef.current.value;
        let CurFile = curFile;
        let WebsiteType = selectedType;
        let OfferID = selectedOffer;

        if (!WebsiteName) return setError("Website name can't be empty");
        if (!EntryFile) return setError("Entry file can't be empty");
        if (!CurFile || !(CurFile instanceof File)) return setError("File not found or invalid");
        if (!WebsiteType) return setError("Website type can't be empty");
        if (!OfferID) return setError("Offer can't be empty");
        if (!selectedImages || selectedImages.length === 0) return setError("Prewiew image can't be empty")





        setSpinner(true);
        let fd = new FormData();
        fd.append("websiteFile", CurFile);
        fd.append("WebsiteName", WebsiteName);
        fd.append("OfferID", OfferID);
        fd.append("EntryFile", EntryFile);
        fd.append("WebsiteType", WebsiteType);
        fd.append("PreviewImages", selectedImages)
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/websites/uploadWebsite`,
            data: fd,
            ...backendModule.axiosConfig,
            headers: {
                ...backendModule.axiosConfig.headers,
                "Content-Type": "multipart/form-data",
            }
        }).then(res => {
            if (res.data.status === "ok") {
                props.update();
                props.onClose();
            } else {
                setError("Error while adding an website, check the info and try again.");
            };
        }).catch(() => {
            setError("Server timed out!");
        }).finally(() => {
            setSpinner(false);
        });
    };



    return <div className="modals__adminWebsitesModal">
        <div className="modals__adminWebsitesModal__container">
            <div className="modals__adminWebsitesModal__close">
                <img src="/images/closeIcon.png" onClick={() => { onClose() }} />
            </div>

            <p className="modals__adminWebsitesModal__head">{props.edit ? "Edit Website" : "Add new website"}</p>

            <p className="modals__adminWebsitesModal__label">Offer</p>
            {props.offers ? <Dropdown theme="light"
                accent="#6C5DD3" data={props.offers.map(c => {
                    return { name: `${c.Name}`, value: c.ID };
                })} onChange={val => {
                    setSelectedOffer(val.value)
                }}
                style={{ height: "45px", marginTop: "15px" }}

            /> : <Spinner color="white" style={{ color: "45px", height: "45px" }} />}

            <p className="modals__adminWebsitesModal__label">Website file</p>
            <input onChange={e => e.target?.files[0] && setCurFile(e.target.files[0])} type="file" accept=".zip" />

            <p className="modals__adminWebsitesModal__label">Entry file</p>
            <input defaultValue={item?.Name} ref={entryFileRef} type='text' placeholder="Entry file" />

            <p className="modals__adminWebsitesModal__label">Name</p>
            <input defaultValue={item?.Name} ref={nameRef} type='text' placeholder="Website name" />

            <p className="modals__adminWebsitesModal__label">Type</p>
            <Dropdown theme="light"
                accent="#6C5DD3" data={[
                    { name: "Landing", value: "Landing" },
                    { name: "Prelanding", value: "Prelanding" },
                    { name: "Lady landing", value: "LadyLanding" },
                    { name: "Home page", value: "HomePage" }
                ]} onChange={val => {
                    setSelectedType(val.value)
                }}
                style={{ height: "45px", marginTop: "15px" }}
            />




            <p className="modals__adminWebsitesModal__error">{error}</p>
            <div className="modals__adminWebsitesModal__buttons">
                <button onClick={e => animateBox(e, <ViewImages onChange={e => setSelectedImages(e)} Images={selectedImages} />)}>View images ({selectedImages.length})</button>

                {spinner ? <Spinner color={"##00A3FF"} /> : <button onClick={() => { saveData() }}>Save</button>}
            </div>
        </div>
    </div>
};






const RemoveWebsite = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [error, setError] = React.useState();

    const deleteData = () => {
        setSpinner(true)
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/websites/removeWebsite`,
            data: {
                ID: props.id
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.update();
                props.onClose();
            } else {
                setError('Please check all your info and try again');
            }
        }).catch(() => {
            setError('Something went wrong please try again later')
        }).finally(() => { setSpinner(false) })
    };

    return <div className="modals__removeWebsite">
        <div className="modals__removeWebsite__container">
            <p>You are about to delete <span style={{ color: "#6c5dd3" }}>{props.item?.Name}</span></p>
            <p>Website deletion is permanent.</p>
            <p>Are you sure you want to continue?</p>
            <p className="modals__removeWebsite__error">{error}</p>
            <div className="modals__removeWebsite__container__buttons">
                <button onClick={() => [deleteData()]}>Yes</button>
                <button onClick={() => { props.onClose() }}>No</button>
            </div>
        </div>
    </div>
};


const ViewImages = props => {
    const [images, setImages] = React.useState(props.Images ?? []);
    const [selectedFiles, setSelectedFiles] = React.useState([]);
    const [imagesToRemove, setImagesToRemove] = React.useState([]);

    const onClose = async (wasSuccess = false) => {
        props.onClose();
        let oldImages = props.Images ?? [];

        let diff = images.filter(i => !oldImages.includes(i));

        if (!wasSuccess) {
            for (let item of diff) {
                let finalName = item.split("/").pop();

                await axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/images/deleteImageByFilename`,
                    data: {
                        FileName: finalName
                    },
                    ...backendModule.axiosConfig
                }).then(() => null).catch(() => null);
            };
        };

        if (wasSuccess && !props.editMode) for (let item of imagesToRemove) {
            await axios({
                method: "POST",
                url: `${backendModule.backendURL}/images/deleteImageByFilename`,
                data: {
                    FileName: item
                },
                ...backendModule.axiosConfig
            }).then(() => null).catch(() => null);
        };
    };

    const removeImage = URL => {
        let finalName = URL.split("/").pop();
        setImagesToRemove(i => [...i, finalName]);
        return setImages(imgs => imgs.filter(imgFinal => imgFinal !== URL));
    };

    React.useEffect(() => {
        if (!Array.isArray(selectedFiles)) return;

        for (let item of [...selectedFiles]) {
            if (item.progress !== 0) continue;

            let ext = item.file.name.split(".").pop();

            let fd = new FormData();
            fd.append("ImageName", `Upload-offer-${Date.now()}-${Math.floor(Math.random() * 999999)}.${ext}`);
            fd.append("tag", "Offer");
            fd.append("image", item.file);

            axios({
                method: "POST",
                url: `${backendModule.backendURL}/images/uploadImage`,
                data: fd,
                onUploadProgress: function (progressEvent) {
                    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

                    setSelectedFiles(sf => sf.map(sfm => {
                        if (sfm.file !== item.file) return sfm;
                        sfm.progress = percentCompleted;
                        if (sfm.progress === 0) sfm.progress = 1;

                        return sfm;
                    }));
                },
                ...backendModule.axiosConfig,
                headers: {
                    ...backendModule.axiosConfig.headers,
                    "Content-Type": "multipart/form-data"
                }
            }).then(res => {
                if (res.data.status === "ok") {
                    setSelectedFiles(sf => sf.filter(ssf => ssf.file !== item.file));
                    setImages(img => [...img, res.data.data]);
                } else {
                    setSelectedFiles(sf => sf.map(sfm => {
                        if (sfm.file !== item.file) return sfm;
                        sfm.progress = -1;

                        return sfm;
                    }));
                };
            }).catch(() => {
                setSelectedFiles(sf => sf.map(sfm => {
                    if (sfm.file !== item.file) return sfm;
                    sfm.progress = -1;

                    return sfm;
                }));
            });
        };

        if (selectedFiles.find(f => f.progress === 0)) setSelectedFiles(sf => sf.map(ssf => {
            return {
                ...ssf,
                progress: 1
            };
        }));
    }, [selectedFiles]);

    const renderImage = (file, img) => {
        let reader = new FileReader();
        reader.onload = e => {
            img.src = e.target.result;
        };
        reader.readAsDataURL(file);
    };

    return <div className="modals__adminOffersModal__viewPayouts">
        <div className="modals__adminOffersModal__viewPayouts__wrap">
            <div className="modals__adminOffersModal__viewPayouts__wrap__head">
                <div className="modals__adminOffersModal__viewPayouts__wrap__head__left">View images</div>
                <div className="modals__adminOffersModal__viewPayouts__wrap__head__middle" onClick={() => {
                    props.onChange(images);
                    onClose(true);
                }}>Save</div>
                <div className="modals__adminOffersModal__viewPayouts__wrap__head__right" style={{ backgroundImage: `url("/images/icon_close.svg")` }} onClick={() => onClose()}></div>
            </div>

            <div className="modals__adminOffersModal__viewPayouts__wrap__content">
                <div className="modals__adminOffersModal__viewPayouts__wrap__content__inputs">
                    <button style={{ gridColumn: "1 / -1" }} onClick={e => e?.currentTarget.parentNode.querySelector("input").click()}>Upload</button>
                    <input type="file" accept="image/*" style={{ display: "none" }} multiple={true} onChange={e => {
                        if (e.target.files.length > 0) setSelectedFiles(sf => {
                            return [
                                ...(sf ?? []),
                                ...[...e.target.files].map(f => {
                                    return {
                                        file: f,
                                        progress: 0
                                    }
                                })
                            ]
                        });
                    }} />
                </div>

                <div className="modals__adminOffersModal__viewPayouts__wrap__content__uploads">
                    {selectedFiles.filter(i => i.file && i.progress < 100).map((img, imgIndex) => {
                        return <div key={`${img.file.name}-${imgIndex}`} className="modals__adminOffersModal__viewPayouts__wrap__content__uploads__upload" src="/images/image-missing.png" onError={e => {
                            renderImage(img.file, e.target);
                        }}>
                            <img src="modals__adminOffersModal__viewPayouts__wrap__content__uploads__upload__image" />
                            <p>{img.file.name} {img.progress === -1 && <button onClick={() => {
                                setSelectedFiles(sf => sf.filter(ssf => ssf.file !== img.file));
                            }}>Dismiss</button>}</p>
                            <div className="modals__adminOffersModal__viewPayouts__wrap__content__uploads__upload__progressbar" style={{
                                backgroundColor: img.progress === -1 ? "#9e4949" : null,
                                width: `${img.progress === -1 ? "100%" : img.progress + "%"}`
                            }}></div>
                        </div>
                    })}
                </div>

                <div className="modals__adminOffersModal__viewPayouts__wrap__content__images">
                    {images.map(img => <div className="modals__adminOffersModal__viewPayouts__wrap__content__images__image">
                        <img src={img} />
                        <button onClick={() => {
                            removeImage(img);
                        }}>Remove</button>
                    </div>)}

                </div>
            </div>
        </div>
    </div>
};



export default AdminWebsites;