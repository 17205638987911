import React from "react";
import "./index.scss";

import axios from "axios";

import * as backendModule from "../../../modules/backendModule";
import { UseSelector, useSelector } from "react-redux/es/hooks/useSelector";
import { animateBox } from "../../../modules/componentAnimation";
import { checkMail } from "../../../modules/miscModule";
import { countries } from "../../../modules/countryModule";

import Spinner from "../../../components/customComponents/Spinner";
import FilterPanel from "../../../components/customComponents/FilterPanel";
import { FilteredCustomTable } from "../../../components/customComponents/Table";
import Dropdown from "../../../components/customComponents/Dropdown";
import FilterBySearch from "../../../filters/FilterBySearch";
import RadioButton from "../../../components/customComponents/RadioButton";

import useOnScreen from "../../../modules/hooks/useOnScreen";
import useDefer from "../../../modules/hooks/useDefer";

const AdminAdvertisers = () => {
    const [data, setData] = React.useState();
    const [filters, setFilters] = React.useState([]);
    const [spinner, setSpinner] = React.useState(false)
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [search, setSearch] = React.useState("");
    const curDefer = useDefer();
    const curOnScreen = useOnScreen();

    const curUserSelector = useSelector(state => state?.userData?.userData?.UserInfo);

    const getData = () => {
        const filtersTmp = [...filters];

        if (search) {
            filtersTmp.push({
                or: [
                    { name: "Username", op: "like", value: search },
                    { name: "CompanyName", op: "like", value: search },
                    { name: "CompanyAddress", op: "like", value: search },
                    { name: "CompanyCity", op: "like", value: search },
                    { name: "TaxVatNumber", op: "like", value: search },
                    { name: "ID", op: "like", value: search },
                ]
            })
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/advertisers/getAllAdvertisers`,
            data: {
                filters: filtersTmp,
                limit: null
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) {
                    setCanPaginate(true);
                } else {
                    setCanPaginate(false);
                };
            };
            setData(res.data);
        }).catch(() => {
            setData(backendModule.axiosConfig);
        });
    };

    const continueData = () => {
        setCanPaginate(false);
        if (!data) return;
        if (data.status !== "ok") return;
        if (data.data.length === 0) return;

        const filtersTmp = [...filters];

        if (search) {
            filtersTmp.push({
                or: [
                    { name: "Username", op: "like", value: search },
                    { name: "CompanyName", op: "like", value: search },
                    { name: "CompanyAddress", op: "like", value: search },
                    { name: "CompanyCity", op: "like", value: search },
                    { name: "TaxVatNumber", op: "like", value: search },
                    { name: "ID", op: "like", value: search },
                ]
            })
        };

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/advertisers/getAllAdvertisers`,
            data: {
                limit: 20,
                offset: data.data.length,
                filters: filtersTmp
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
                if (res.data.data.length === 20) {
                    setCanPaginate(true);
                } else {
                    setCanPaginate(false);
                };
            };
        }).catch(() => null).finally(() => {
            setSpinner(false);
        });
    };
    React.useEffect(() => {
        getData();
    }, [search, filters])

    React.useEffect(() => {
        if (curOnScreen.isIntersecting && canPaginate) {
            try {
                curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
            } catch { };

            curDefer(() => {
                continueData();
            }, 500);
        };
    }, [canPaginate, curOnScreen.isIntersecting]);

    return <div className="route__adminAdvertisers">
        <div className="route__adminAdvertisers__head" style={{
            gridTemplateColumns: curUserSelector?.Flags?.isAdmin ? null : "1fr 400px"
        }}>
            <FilterPanel

                accent="#dfdfdf"
                theme="light"
                filterCB={fi => setFilters(fi)}
                filters={[
                    { name: "ID", friendlyName: "ID", type: "string" },
                    { name: "Username", friendlyName: "Username", type: "string" },
                    { name: "CompanyName", friendlyName: "Company name", type: "string" },
                    { name: "CompanyCity", friendlyName: "Company city", type: "string" },
                    { name: "CompanyZipCode", friendlyName: "Company ZIP code", type: "string" },
                    { name: "createdAt", friendlyName: "Date created", type: "date" },
                    { name: "updatedAt", friendlyName: "Date updated", type: "date" }
                ]} />
            <FilterBySearch onChange={e => setSearch(e)} />
            {curUserSelector?.Flags?.isAdmin && <button onClick={() => { animateBox({ currentTarget: document.querySelector('.route__adminAdvertisers__content') }, <SelectedAdvertiser item={null} update={getData} />) }}>Add Advertiser</button>}
        </div>
        <div className="route__adminAdvertisers__content" style={{ gridTemplateColumns: "1fr" }} >
            <div className="route__adminAdvertisers__content__left">
                <FilteredCustomTable
                    theme="light"
                    accent="#6C5DD3"
                    headers={["ID", "Username", "Company name", "Credit balance", ""]}
                    customColumns={["360px", "1fr", "1fr", (curUserSelector?.Flags?.isAdmin || curUserSelector?.Flags?.isManager) ? "300px" : "0px"]}
                    style={{ width: "100%" }}
                    data={(() => {
                        let out = [];

                        if (!data) return [[{ keyID: "noData-spinner", type: "spinner", color: "white" }]];
                        if (data.status === "ok") {
                            for (let item of data.data) {

                                out.push({
                                    columns: [
                                        { keyID: item.ID, type: "text", text: item.ID },
                                        { keyID: item.ID, type: "text", text: item.Username },
                                        { keyID: item.ID, type: "text", text: item.CompanyName },
                                        { keyID: item.ID, type: "text", text: `€ ${item.creditBalance}` },
                                        {
                                            keyID: item.ID, type: "custom", data: <div className="route__adminAdvertisers__buttons">

                                                {curUserSelector?.Flags?.isAdmin && <>
                                                    <p className="route__adminAdvertisers__edit" onClick={(e) => {
                                                        e.stopPropagation();
                                                        animateBox({ currentTarget: document.querySelector('.route__adminAdvertisers__content') }, <SelectedAdvertiser item={item} update={getData} edit={true} />)
                                                    }}>Edit</p>
                                                    <p className="route__adminAdvertisers__edit" onClick={(e) => {
                                                        e.stopPropagation();
                                                        animateBox({ currentTarget: document.querySelector('.route__adminAdvertisers__content') }, <AddBalance item={item} update={getData} edit={true} />)
                                                    }}>Add/Remove balance</p>
                                                    <p className="route__adminAdvertisers__delete" onClick={(e) => {
                                                        e.stopPropagation();
                                                        animateBox({ currentTarget: document.querySelector('.route__adminAdvertisers__content') }, <DeleteAdvertiser item={item} id={item.ID} update={getData} />)
                                                    }}>Delete</p>
                                                </>}
                                            </div>
                                        },
                                        {
                                            key: item.ID, type: "groupNewline", group: [
                                                {
                                                    keyID: item.ID, type: "custom", data: <div className="route__adminAdvertisers__content__left__pills">
                                                        {Object.keys(item.Flags).map(key => <p>{key}: <span>{item.Flags[key] ? "Yes" : "No"}</span></p>)}
                                                    </div>
                                                }
                                            ]
                                        }
                                    ]
                                })
                            };
                        } else {
                            out.push([{ keyID: "noData-error", type: "text", text: "Error while fetching orders!", color: "#f96666" }]);
                        };

                        if (spinner) out.push([{ keyID: "pagination-spinner", type: "spinner", color: "white" }]);
                        if (out.length === 0) out.push([{ keyID: "noData-noData", type: "text", text: "Nothing to show for now." }]);

                        return out;
                    })()}
                />
            </div>
            {canPaginate && <div ref={curOnScreen.measureRef} className="modals__ordersModal__wrap__content__left__paginate"></div>}

        </div>

    </div>
};

const SelectedAdvertiser = (props) => {
    const [item, setItem] = React.useState(props.item);
    const [spinner, setSpinner] = React.useState(false);
    const [flags, setFlags] = React.useState({});
    const [error, setError] = React.useState();
    const [focusedInput, setFocusedInput] = React.useState();

    const [users, setUsers] = React.useState();
    const [userID, setUserID] = React.useState();

    const companyNameRef = React.useRef();
    const companyAddressRef = React.useRef();
    const companyCityRef = React.useRef();
    const companyZipRef = React.useRef();
    const taxNumberRef = React.useRef();
    const vatNumberRef = React.useRef();




    React.useEffect(() => {
        if (!props.item?.ID) return;

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/advertisers/getAdvertiserByID`,
            data: {
                ID: props.item.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "error") return props.onClose();
            setUserID(res.data.data.ID)
            setItem(res.data.data);
            setFlags(res.data.data.Flags ?? {});
        }).catch().finally(() => {
            setSpinner(false);
        });
    }, [props.item])

    const getUsers = () => {


        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsers`,
            data: {
                limit: 1000
            },
            ...backendModule.axiosConfig
        }).then(res => {

            setUsers(res.data);
        }).catch(() => {
            setUsers(backendModule.axiosConfig);
        });
    };
    React.useEffect(() => {
        if (!props.edit) {
            getUsers();
        }

    }, [])

    const saveData = () => {
        setError();
        let data = {
            ID: userID,
            UserID: userID,
            CompanyName: companyNameRef.current.value,
            CompanyAddress: companyAddressRef.current.value,
            CompanyCity: companyCityRef.current.value,
            CompanyZipCode: companyZipRef.current.value,
            TaxNumber: taxNumberRef.current.value,
            VatNumber: vatNumberRef.current.value
        };


        if (!data.UserID) return setError("User is required");
        if (!data.CompanyName) return setError("Company name is required");
        if (!data.CompanyAddress) return setError("Company address is required");
        if (!data.CompanyZipCode) return setError("Company ZIP code is required");
        if (!data.TaxNumber) return setError("Tax number is required");
        if (!data.VatNumber) return setError("VAT number is required");

        let path = '/createNewAdvertiser';
        if (props.edit) path = '/editAdvertiser';
        setSpinner(true)
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/advertisers${path}`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.update();
                props.onClose();
            } else {
                setError('Please check all your info and try again');
            }
        }).catch(() => {
            setError('Something went wrong please try again later')
        }).finally(() => { setSpinner(false) })
    };

    return <div className="modals__advertiserModal">
        <div className="modals__advertiserModal__container">
            <div className="modals__advertiserModal__close">
                <img src="/images/closeIcon.png" onClick={() => { props.onClose() }} />
            </div>
            <p className="modals__advertiserModal__head">{props.edit ? "Edit Advertiser" : "Add new advertiser"}</p>
            {props.edit ? <>
                <p className="modals__advertiserModal__label">Selected user</p>
                <input type="text" defaultValue={item?.Username} />
            </> : <> <p className="modals__advertiserModal__label">Select user</p>
                <Dropdown theme="light"
                    accent="#6C5DD3" data={users?.status === 'ok' && users.data.map(item => {
                        return { value: item.ID, name: item.Username }
                    })} onChange={val => {
                        setUserID(val.value)
                    }} /></>}
            <p className="modals__advertiserModal__label">Company name</p>
            <input ref={companyNameRef} defaultValue={item?.CompanyName} type="text" placeholder="Company name" />
            <p className="modals__advertiserModal__label">Company address</p>
            <input ref={companyAddressRef} type="text" defaultValue={item?.CompanyAddress} placeholder="Company address" />
            <p className="modals__advertiserModal__label">Company city</p>
            <input ref={companyCityRef} defaultValue={item?.CompanyCity} type="text" placeholder="Company city" />
            <p className="modals__advertiserModal__label">Company ZIP code</p>
            <input ref={companyZipRef} defaultValue={item?.CompanyZipCode} type="text" placeholder="Company ZIP code" />
            <p className="modals__advertiserModal__label">Tax number</p>
            <input ref={taxNumberRef} defaultValue={item?.TaxVatNumber?.Tax} type="text" placeholder="Tax number" />
            <p className="modals__advertiserModal__label">VAT number</p>
            <input ref={vatNumberRef} defaultValue={item?.TaxVatNumber?.Vat} type="number" placeholder="VAT number" />



            <p className="modals__advertiserModal__error">{error}</p>
            <div className="modals__advertiserModal__buttons">
                {spinner ? <Spinner color={"#00A3FF"} /> : <button onClick={() => { saveData() }}>Save</button>}
            </div>
        </div>


    </div>
}

const DeleteAdvertiser = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [error, setError] = React.useState();
    const deleteData = () => {
        setSpinner(true)
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/advertisers/removeAdvertiser`,
            data: {
                ID: props.id
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.update();
                props.onClose();
            } else {
                setError('There was an error while removing the user');
            }
        }).catch(() => {
            setError('Something went wrong please try again later')
        }).finally(() => { setSpinner(false) })
    };
    return <div className="modals__deleteAdvertiser">
        <div className="modals__deleteAdvertiser__container">
            <p>You are about to delete <span style={{ color: "#6c5dd3" }}>{props.item?.Username}</span></p>
            <p>Advertiser deletion is permanent.</p>
            <p>Are you sure you want to continue?</p>
            <p className="modals__domainModal__error">{error}</p>
            <div className="modals__deleteAdvertiser__container__buttons">
                {spinner ? <Spinner color="white" style={{ width: "43px", height: "43px" }} /> : <>
                    <button onClick={() => [deleteData()]}>Yes</button>
                    <button onClick={() => { props.onClose() }}>No</button>
                </>}
            </div>
        </div>
    </div>
}

const VerifyAdvertiser = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [error, setError] = React.useState();
    const deleteData = () => {
        setSpinner(true)
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/advertisers/verifyAdvertiser`,
            data: {
                ID: props.id,
                verifyStatus: true
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.update();
                props.onClose();
            } else {
                setError('There was an error while verifying the user');
            }
        }).catch(() => {
            setError('Something went wrong please try again later')
        }).finally(() => { setSpinner(false) })
    };
    return <div className="modals__deleteAdvertiser">
        <div className="modals__deleteAdvertiser__container">
            <p>You are about to verify <span style={{ color: "#6c5dd3" }}>{props.item?.Username}</span></p>
            <p>By verifying the user you grant them rights to run offers and see the platform.</p>
            <p>Are you sure you want to continue?</p>
            <p className="modals__domainModal__error">{error}</p>
            <div className="modals__deleteAdvertiser__container__buttons">
                {spinner ? <Spinner color="white" style={{ width: "43px", height: "43px" }} /> : <>
                    <button onClick={() => [deleteData()]}>Yes</button>
                    <button onClick={() => { props.onClose() }}>No</button>
                </>}
            </div>
        </div>
    </div>
}

const AddBalance = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [error, setError] = React.useState();
    const minBalanceRef=React.useRef();
    const creditBalanceRef=React.useRef();
    const deleteData = () => {
        setSpinner(true)
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/advertisers/addCredits`,
            data: {
                ID: props.item.ID,
                minBalance: minBalanceRef.current.value ?? 0,
                creditBalance: Number(creditBalanceRef.current.value)
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.update();
                props.onClose();
            } else {
                setError('There was an error while adding balance');
            }
        }).catch((e) => {
            setError('Something went wrong please try again later')
        }).finally(() => { setSpinner(false) })
    };
    return <div className="modals__deleteAdvertiser">
        <div className="modals__deleteAdvertiser__container">
            <p>Add credits to advertiser <span style={{ color: "#6c5dd3" }}>{props.item?.Username}</span></p>
            <div className="modals__deleteAdvertiser__container__input">
                <label>Minimum allowed balance</label>
                <input type="number" ref={minBalanceRef} />
            </div>
            <div className="modals__deleteAdvertiser__container__input">
                <label>Credits to add/remove</label>
                <input type="number" ref={creditBalanceRef} />
            </div>
            <p className="modals__domainModal__error">{error}</p>
            <div className="modals__deleteAdvertiser__container__buttons">
                {spinner ? <Spinner color="white" style={{ width: "43px", height: "43px" }} /> : <>
                    <button onClick={() => [deleteData()]}>Yes</button>
                    <button onClick={() => { props.onClose() }}>No</button>
                </>}
            </div>
        </div>
    </div>
}

export default AdminAdvertisers;